import React from "react";
import Price from "../components/priceBackground";
import "../main.css";
import { Tooltip, Checkbox, Badge } from "antd";
import { CheckOutlined } from "@ant-design/icons";

export class RenderHelpers {
  static renderAccountSummaryValue = (text) => (
    <Price text={text} dp="2" bold={false}></Price>
  );

  static renderAccountSummaryValueWithSymbol = (
    symbol,
    value,
    showThousandsSeperator
  ) => (
    <Price
      currencySymbol={symbol}
      text={value}
      dp={2}
      showThousandsSeperator={showThousandsSeperator}
      bold={false}
    ></Price>
  );

  static renderAccountSummaryWithAlertLevel = (text, alertLevel) => {
    var color = "gray";
    if (alertLevel === 4)
      color = "#D32029";
    else if (alertLevel === 3)
      color = "#D84A1B";
    else if (alertLevel === 2)
      color = "#FFFF00";
    else if (alertLevel === 1)
      color = "#49AA19";
    return (
      <b>
        <font className="bold16" color={color}>
          {text}
        </font>
      </b>
    );
  };

  static renderCurrency = (text, render) => (
    <b>
      <font className="bold16" color="#A9C1D6">
        {text}
      </font>
    </b>
  );

  static renderType = (text, tooltip) => (
    
    <b>
      <font className="bold16" color="#A9C1D6">
      <Tooltip title={tooltip?tooltip: text}>
        {text}
        </Tooltip>
      </font>
    </b>
    
  );

  static renderPosition = (text, render) => (
    //49AA19
    <b>
      <font className="bold16" color="#5a9dd8">
        {text}
      </font>
    </b>
  );

  static renderTrade = (text, render) => (
    // D32029
    <b>
      <font className="bold16" color="#177DDC">
        {text}
      </font>
    </b>
  );

  static renderOrder = (text, render) => (
    // D32029
    <b>
      <font className="bold16" color="#177DDC">
        {text}
      </font>
    </b>
  );

  static renderDatatableCheckbox = (flag, render) => (
    // D32029
    <b>
      <Checkbox checked={flag}/>
    </b>
  );

  static renderAccountSummaryErrors = (summary) => {
    if (summary && summary.hasError) {
      var errorCount = 0;
      var errorString = '';
      if (summary.errorsAndWarnings) {
        summary.errorsAndWarnings.forEach(error => {
          if (error.level === 1){
            if (error.type === 1){
              var missingPriceError = 'No price for market quote id ' + error.marketQuoteId + ' (' + error.description + ')';
              errorString += (missingPriceError + ',');
            }
            else{
              errorString += error.description + ',';
            }
            errorCount += 1;
          }
        });

      }
      return (
        <div style={{ padding: "0 5px 0 5px" }}>
          <Tooltip title={errorString}>
            <Badge overflowCount={999} count={errorCount} showZero>
            </Badge>
          </Tooltip>
        </div>
      )

    }
    else {
      return (
        <div style={{ padding: "0 5px 0 5px" }}>
          <CheckOutlined style={{ color: "green", fontSize: '150%' }} />
        </div>
      )
    }
  };
}
