import App from "./App.js";
import {createRoot} from "react-dom/client";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import { SignInButton } from "./components/SignInButton.jsx";
import { Helpers } from "./helpers/helpers";

const container = document.getElementById("root");
const root = createRoot(container);

msalConfig.auth.clientId = Helpers.currentEnv().AUTH_CLIENT_ID;

console.log(msalConfig.auth.tenantId)
export const msalInstance = new PublicClientApplication(msalConfig);
const accounts = msalInstance.getAllAccounts();
if (accounts.length === 1) {
  const account = accounts[0];
  if (account?.tenantId === msalConfig.auth.tenantId) {
    msalInstance.setActiveAccount(account);
  }
} else if (accounts.length > 1) {
  accounts.forEach((account) => {
    if (account?.tenantId === msalConfig.auth.tenantId) {
      msalInstance.setActiveAccount(account);
    }
  });
}

root.render(
    
    <MsalProvider instance={msalInstance}>
        <AuthenticatedTemplate>
            <App pca={msalInstance} />
        </ AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <div style={{width: "100%", top: "30%", position: "absolute"}}>
            <div>
              <h1 style={{textAlign: "center"}}>Trading Platform Dashboard</h1>
            </div>
            <div style={{left: "50%", transform: "translate(-50%)", position: "absolute"}}>
              <SignInButton/>
            </div>
          </div>
        </UnauthenticatedTemplate>
    </MsalProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
