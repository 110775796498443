import {
  HubConnectionBuilder,
  LogLevel,
  HubConnectionState,
  HttpTransportType,
} from "@microsoft/signalr";
import { Helpers } from "../helpers/helpers";

class AccountSummaryDataAccessHub {
  constructor(onAccountSummaryChange, onReconnect) {
    this.ACCOUNT_SUMMARIES_REPOSITORY_URL = Helpers.currentEnv().DYNAMIC_DATA_REPOSITORY_API_URL.replace("/api","/api-ws")+ "proxy/accountSummaryDataAccess/";
    console.debug(
      "Account summary repository hub: ",
      Helpers.currentEnvName(),
      this.ACCOUNT_SUMMARIES_REPOSITORY_URL
    );
    this.onAccountSummaryChange = onAccountSummaryChange;
    this.onReconnect = onReconnect;
    this.hubConnection = new HubConnectionBuilder()
      .withUrl(this.ACCOUNT_SUMMARIES_REPOSITORY_URL + "summaries", {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
      })
      .configureLogging(LogLevel.Debug)
      .withAutomaticReconnect()
      .build();

    this.hubConnection.keepAliveIntervalInMilliseconds = 30000;
    this.hubConnection.serverTimeoutInMilliseconds = 60000;

    this.hubConnection.onreconnected((connectionId) => {
      console.debug(
        "AccountSummaryDataAccessHub Reconnected connectionId: ",
        connectionId
      );
      if (onReconnect) {
        onReconnect();
      }
    });

    this.hubConnection.onclose((connectionId) => {
      console.debug(
        "AccountSummaryDataAccessHub Closed connectionId: ",
        connectionId
      );
    });

    this.hubConnection.on("accountSummaryUpdate", (message) => {
      if (this.onAccountSummaryChange) this.onAccountSummaryChange(message[0]);
    });
  }

  connect() {
    this.connectPromise = this.hubConnection
      .start()
      .then(() => {
        console.debug("AccountSummaryDataAccessHub Connected");
      })
      .catch((error) => {
        console.assert(
          this.hubConnection.state === HubConnectionState.Disconnected
        );
        console.error(error);
        setTimeout(() => this.connect(), 2000);
      });

    return this.connectPromise;
  }

  async subscribe(clientIds, legacyAccountSummaries) {
    if (
      this.hubConnection &&
      this.hubConnection.state === HubConnectionState.Connected
    ) {
      await this.hubConnection
        .invoke("subscribe", {
          includeLegacy: legacyAccountSummaries,
          clientIds: clientIds,
        })
        .catch((err) => console.error(err));
    }
  }

  async unsubscribe(clientIds) {
    if (
      this.hubConnection &&
      this.hubConnection.state === HubConnectionState.Connected
    ) {
      await this.hubConnection
        .invoke("unsubscribe", clientIds)
        .catch((err) => console.error(err));
    }
  }

  stop() {
    if (this.hubConnection !== HubConnectionState.Disconnected) {
      return this.connectPromise.then(() =>
        this.hubConnection.stop().catch((err) => console.error(err))
      );
    }
    return Promise.resolve(true);
  }
}

export default AccountSummaryDataAccessHub;
