import { Helpers } from "../helpers/helpers";
import axios from "axios";
import axiosRetry from "axios-retry";

class TelemetryProxy {
  constructor(
  ) {
    this.TELEMETRY_PERSISTER_API_URL = Helpers.currentEnv().DYNAMIC_DATA_REPOSITORY_API_URL+ "proxy/telemetry/";
    console.debug(
      "Telemetry Persister: ",
      Helpers.currentEnvName(),
      this.TELEMETRY_PERSISTER_API_URL
    );

    axiosRetry(axios, {
      retries: 3,
      shouldResetTimeout: true,
      retryCondition: (error) => {
        console.error(error);
        return true;
      },
      retryDelay: (retryCount) => retryCount * 1000,
    });
  }

  async GetDistributionUserWebsocketHeartbeats(clientId) {
    var response;
    try {
      response = await axios.get(
        this.TELEMETRY_PERSISTER_API_URL +
        `distributionUserWebsocketHeartbeats/clientId/${clientId}`,
        { headers: { application: this.APPLICATION } }
      );
    } catch (error) {
      console.error(error);
    }
    var results =
      response && response.status === 200 ? response?.data.results : [];
    console.debug("Telemetry Persister Events:", results);
    return results;
  }
}

export default TelemetryProxy;
