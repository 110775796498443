import React from "react";
import { Table, Button } from "antd";
import { AreaChartOutlined } from "@ant-design/icons";
import TradeSummary from "./tradeSummary";
import OpenOrderSummary from "./openOrderSummary";
import Errors from "./errors.js";
import { RenderHelpers } from "./renderHelpers";

class PositionSummary extends React.Component {
  constructor(props) {
    super();
    this.props = props;
    this.state = { record: props.record };
  }
  async componentDidMount() {
    var columns = [
      {
        title: "Market Name",
        dataIndex: "marketName",
        width: "10%",
        render: (text) => RenderHelpers.renderPosition(text),
      },
      {
        title: "Market Quote",
        dataIndex: "marketQuoteId",
        width: "10%",
      },
      {
        title: "Market",
        dataIndex: "marketId",
        width: "10%",
      },
      {
        title: "Buy/Sell",
        dataIndex: "tradeModeType",
        width: "10%",
      },
      {
        title: "PnL",
        width: "10%",
        render: (record) =>
          RenderHelpers.renderAccountSummaryValueWithSymbol(
            record.currencyCodeSymbol,
            record.openPnL,
            true
          ),
      },
      {
        title: "Margin",
        width: "10%",
        render: (record) =>
          RenderHelpers.renderAccountSummaryValueWithSymbol(
            record.currencyCodeSymbol,
            record.margin,
            true
          ),
      },
      {
        title: "Stake",
        width: "10%",
        render: (record) =>
          RenderHelpers.renderAccountSummaryValueWithSymbol(
            record.currencyCodeSymbol,
            record.stakeBalance,
            true
          ),
      },
      {
        title: "Avg Open Price",
        width: "10%",
        render: (record) =>
          RenderHelpers.renderAccountSummaryValueWithSymbol(
            record.currencyCodeSymbol,
            record.averageOpenPrice,
            true
          ),
      },
      {
        title: "Current Price",
        width: "10%",
        render: (record) =>
          record.tradeModeType === "Sell"
            ? record.currentPrice && record.currentPrice.ask
              ? RenderHelpers.renderAccountSummaryValueWithSymbol(
                  record.currencyCodeSymbol,
                  record.currentPrice.ask,
                  true
                )
              : "-"
            : record.currentPrice && record.currentPrice.bid
            ? RenderHelpers.renderAccountSummaryValueWithSymbol(
                record.currencyCodeSymbol,
                record.currentPrice.bid,
                true
              )
            : "-",
      },
      {
        width: "10%",
        render: (text, record) => (
          <a href={"#/marketMonitor/" + record.marketId}>
            <Button
              type="primary"
              shape="circle"
              icon={<AreaChartOutlined />}
            />
          </a>
        ),
      },
    ];
    this.setState({ columns: columns });
  }
  render() {
    return (
      <>
        <Errors record={this.props.record} />
        <Table
          dataSource={this.props.record.positions.filter(
            (x) => x.totalTrades > 0 || x.openingOrders.length > 0
          )}
          pagination={false}
          rowExpandable={(record) =>
            (record.trades && record.trades.length > 0) ||
            record.openingOrders.length > 0
          }
          expandedRowRender={(record, i) => (
            <>
              <TradeSummary record={record} />
              <OpenOrderSummary record={record} />
            </>
          )}
          columns={this.state.columns}
          rowClassName={(record) => "positionBlue"}
        />
      </>
    );
  }
}

export default PositionSummary;
