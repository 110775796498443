import React from "react";
import { Table, Tooltip } from "antd";
import { RenderHelpers } from "../../helpers/renderHelpers";
import ConnectorsCoordinatorProxy from "../../proxies/connectorsCoordinatorProxy";

import moment from "moment";
import * as _ from "underscore";

import {
  ArrowRightOutlined
} from "@ant-design/icons";

import "antd/dist/antd.dark.css";
import "../../main.css";

const fullDateFormat = "yyyy-MM-DD HH:mm:ss";
const dateFormat = "ddd HH:mm:ss";

class PrimaryAndSecondaryFeed extends React.Component {
  constructor(props) {
    super();
    this.throttledSetState = _.throttle(this.setConstituentPrices, 50, {
      leading: false,
    });

    this.pricingHubProxy = new ConnectorsCoordinatorProxy();

    this.props = props;
    this.state = {
      columns: [],
      constituentPrices: props.record.constituentPrices,
    };
  }
  async componentDidMount() {
    this._isMounted = true;
    const columns = [
      {
        title: "",
        dataIndex: "isActive",
        width: 10,
        render: (isActive, record) =>
        {
          return isActive === true ? (
            <Tooltip title="Currently pricing from this provider instrument">
              <ArrowRightOutlined
                className="size20"
                style={{
                  color: "green",
                }}
              />
            </Tooltip>
          ) : (
            <></>
          )},
      },
      {
        title: "Provider",
        dataIndex: "feed",
        width: 100,
      },
      {
        title: "Feed",
        dataIndex: "name",
        width: 200,
      },
      {
        title: "Bid",
        dataIndex: "bid",
        width: 100,
        render: (text) => RenderHelpers.renderBidAsk(text, this.props.record),
      },
      {
        title: "Ask",
        dataIndex: "ask",
        width: 100,
        render: (text) => RenderHelpers.renderBidAsk(text, this.props.record),
      },
      {
        title: "Last Seen (UTC)",
        dataIndex: "timestamp",
        width: 100,
        render: (text) =>
          text ? (
            <Tooltip title={moment(text).format(fullDateFormat).toString()}>
              {moment(text).format(dateFormat).toString()}
            </Tooltip>
          ) : (
            ""
          ),
      },
      {}
    ];
    this.setState({ columns: columns });
  }

  setConstituentPrices = () => {
    if (this._isMounted)
      this.setState({
        constituentPrices: [...this.props.record.constituentPrices],
      });
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  async componentDidUpdate(prevProps) {
    if (this._isMounted) this.throttledSetState();
  }

  render() {
    return (
      <Table
        size="small"
        rowKey={(x) => x.feed + x.name}
        columns={this.state.columns}
        dataSource={this.state.constituentPrices}
        pagination={false}
      />
    );
  }
}

export default PrimaryAndSecondaryFeed;
