import React from "react";
import AccountSummaryDataAccess from "../proxies/accountSummaryDataAccess.js";
import {
  InputNumber,
  Layout,
  Input,
  Typography,
  Space,
  Switch,
  Select,
  Row,
  Col,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { Helpers } from "../helpers/helpers";
import { AppContext } from "../dataContext";
import ErrorsTable from "./errorsTable.js";
const { Content } = Layout;
const { Title } = Typography;
const { Option } = Select;

class ErrorsPage extends React.Component {
  static contextType = AppContext;
  constructor(props) {
    super();
    this.props = props;
    this.accountSummaryProxy = new AccountSummaryDataAccess();
    this.state = {
      search: "",
      errorTypes: [],
      errorLevels: [],
      selectedClientTypes: [],
      whiteLabels: [],
      clientTypes: [],
      nameFilter: null,
      clientIdFilter: null,
      errorTypesFilter: [],
      errorLevelsFilter: [],
      whiteLabelsFilter: [],
      clientTypesFilter: [],
      defaultClientTypesFilter: [],
      autoRefreshPageIntervalSecs: 30,
      errorCount: 0,
    };
  }

  async componentDidMount() {
    this.context.updateMenuSelection("accountErrors");

    document.title = "Account Errors";
    await this.getOptions();
    this.loadUrlParameters();
  }

  async componentDidUpdate(prevProps, prevState) {
    var currentQueryString = new URLSearchParams(this.props.location.search)
      .toString()
      .toLowerCase();
    var previousQueryString = new URLSearchParams(prevProps.location.search)
      .toString()
      .toLowerCase();
    if (currentQueryString !== previousQueryString) {
      this.loadUrlParameters();
    }
  }

  async getOptions() {
    try {
      var response = await this.accountSummaryProxy.getOptions();
      this.setState({
        allowLegacy: response.allowLegacy,
        errorTypes: response.errorTypes,
        errorLevels: response.errorLevels,
        defaultClientTypesFilter: response.defaultClientTypes.map(
          (x) => x.index
        ),
        alertLevels: response.alertLevels,
        clientTypesFilter: response.defaultClientTypes.map((x) => x.index),
        whiteLabels: response.whiteLabels,
        clientTypes: response.clientTypes,
      });
    } catch (error) {
      console.error(error);
      this.setState({
        allowLegacy: false,
        errorLevels: [{ key: "Error", description: "Errors" }],
        errorTypes: [],
        defaultClientTypesFilter: [],
        clientTypesFilter: [],
        whiteLabels: [],
        clientTypes: [],
        alertLevels: [],
      });
    }
  }

  updateContent = async (event, nameFromContext) => {
    var name = event ? event.target.value : nameFromContext;
    this.setState({ search: name });
    this.context.state.search = name;
  };


  refreshPageCheckedChanged = (checked) => {
    if (checked) {
      this.setState({ autoRefreshPage: true });
    } else {
      this.setState({ autoRefreshPage: false });
    }
  };

  autoRefreshPageIntervalSecsChanged = (value) => {
    this.setState({ autoRefreshPageIntervalSecs: value });
  };


  onClientTypesChanged = (e, control) => {
    if (e) {
      this.setState({ clientTypesFilter: e });
    } else {
      this.setState({ clientTypesFilter: [] });
    }

    if (control) {
      control.blur();
    }
  };

  onWhiteLabelsChanged = (e, control) => {
    if (e) {
      this.setState({ whiteLabelsFilter: e });
    } else {
      this.setState({ whiteLabelsFilter: [] });
    }

    if (control) {
      control.blur();
    }
  };

  onErrorLevelsChanged = (e, control) => {
    if (e) {
      this.setState({ errorLevelsFilter: e });
    } else {
      this.setState({ errorLevelsFilter: [] });
    }

    if (control) {
      control.blur();
    }
  };

  onErrorTypesChanged = (e, control) => {
    if (e) {
      this.setState({ errorTypesFilter: e });
    } else {
      this.setState({ errorTypesFilter: [] });
    }

    if (control) {
      control.blur();
    }
  };

  onFilterApplied = (filter) => {
    if (filter) {
      this.updateUrl(filter);
    }
  };

  onDataRetrieved = (data) => {
    this.setState({ errorCount: data.errorCount });
  };

  loadUrlParameters = () => {
    const query = new URLSearchParams(this.props.location.search);
    const searchVal = query.get("search");
    if (searchVal) {
      this.setState({ search: searchVal });
    }
    const nameVal = query.get("name");
    if (nameVal) {
      this.setState({ nameFilter: nameVal });
    }

    const clientIdVal = query.get("clientId");
    if (clientIdVal) {
      this.setState({ clientIdFilter: clientIdVal });
    }
    this.setState({
      whiteLabelsFilter: this.getUrlPropFromQueryIntList(
        query,
        "whiteLabels",
        this.state.whiteLabels,
        (x) => x.id,
        []
      ),
    });
    this.setState({
      errorTypeFilter: this.getUrlPropFromQueryIntList(
        query,
        "errorTypes",
        this.state.errorTypes,
        (x) => x.index,
        []
      ),
    });
    this.setState({
      errorLevelFilter: this.getUrlPropFromQueryIntList(
        query,
        "errorLevels",
        this.state.errorLevels,
        (x) => x.index,
        []
      ),
    });
    this.setState({
      alertLevelsFilter: this.getUrlPropFromQueryIntList(
        query,
        "alertLevels",
        this.state.alertLevels,
        (x) => x.id,
        []
      ),
    });
    this.setState({
      clientTypesFilter: this.getUrlPropFromQueryIntList(
        query,
        "clientTypes",
        this.state.clientTypes,
        (x) => x.index,
        this.state.defaultClientTypesFilter
      ),
    });
  };

  getUrlPropFromQueryIntList(
    query,
    queryParam,
    allOptions,
    optionsValueFunction,
    defaultValues
  ) {
    const queryValue = query.get(queryParam);
    if (queryValue) {
      if (queryValue === "All") {
        return allOptions.map((x) => optionsValueFunction(x));
      } else {
        var split = queryValue.split(",");
        if (split.length === 0) {
          if (defaultValues) {
            return defaultValues;
          } else {
            return [];
          }
        } else {
          for (var a in split) {
            split[a] = parseInt(split[a], 10);
          }
        }
        return split;
      }
    } else {
      if (defaultValues) {
        return defaultValues;
      } else {
        return [];
      }
    }
  }

  updateUrl = (filter) => {
    const query = new URLSearchParams(this.props.location.search);
    var originalString = query.toString().toLowerCase();

    var title = "Account Errors";
    if (filter.searchString) {
      query.set("search", filter.searchString);
      title = title + ", Search " + filter.searchString;
    } else {
      query.delete("search");
    }


    title += this.updateUrlFromFilter(query, filter.nameFilter, "name", "Name");

    title += this.updateUrlFromFilter(
      query,
      filter.clientIdFilter,
      "clientId",
      "Client"
    );

    title += this.updateUrlFromFilterList(
      query,
      filter.whiteLabelsFilter,
      "whiteLabels",
      "White Labels",
      this.state.whiteLabels,
      (f, option) => f === option.id,
      (option) => option.name
    );
    title += this.updateUrlFromFilterList(
      query,
      filter.errorTypeFilter,
      "errorTypes",
      "Error Types",
      this.state.errorTypes,
      (f, option) => f === option.index,
      (option) => option.description
    );
    title += this.updateUrlFromFilterList(
      query,
      filter.errorLevelFilter,
      "errorLevels",
      "Error Levels",
      this.state.errorLevels,
      (f, option) => f === option.index,
      (option) => option.description
    );
    title += this.updateUrlFromFilterList(
      query,
      filter.clientTypesFilter,
      "clientTypes",
      "Client Types",
      this.state.clientTypes,
      (f, option) => f === option.index,
      (option) => option.description
    );

    if (query.toString().toLowerCase() !== originalString) {
      if (!this.props.params.history)
        this.props.params.history = [];
      this.props.params.history.push({
        search: "?" + query.toString(),
      });
    }

    document.title = title;
    this.props.navigate(this.props.location.pathname + "?" + query.toString());
  };

  updateUrlFromFilter(query, filter, queryProperty, title) {
    if (filter) {
      query.set(queryProperty, filter);
      return ", " + title + ":" + filter;
    } else {
      query.delete(queryProperty);
      return "";
    }
  }
  updateUrlFromFilterList = (
    query,
    filter,
    queryProperty,
    title,
    options,
    optionFilterFunction,
    optionDescriptionFunction
  ) => {
    var returnTitle = "";
    if (filter && filter.length > 0) {
      if (filter.length === options.length) {
        query.set(queryProperty, "All");
      } else {
        query.set(queryProperty, filter.join());
        var names = filter.map((x) => {
          var filteredOptions = options.filter((option) =>
            optionFilterFunction(x, option)
          );
          if (filteredOptions.length > 0) {
            return optionDescriptionFunction(filteredOptions[0]);
          }
          return x;
        });
        returnTitle = ", " + title + ":" + names.join();
      }
    } else {
      query.delete(queryProperty);
    }
    return returnTitle;
  };

  filterDropDown = (input, option) => {
    return (
      option.props &&
      ((option.props.value &&
        (option.props.value === input ||
          (Object.prototype.toString.call(option.props.value) ===
            "[object String]" &&
            option.props.value.toLowerCase().indexOf(input.toLowerCase()) >=
            0))) ||
        (option.props.key &&
          option.props.key.toLowerCase().indexOf(input.toLowerCase()) >= 0) ||
        (option.props.children &&
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
          0))
    );
  };

  clearAllFilters = () => {
    this.setState({
      search: null,
      errorLevelFilter: [],
      errorTypeFilter: [],
      whiteLabelsFilter: [],
      clientTypesFilter: this.state.clientTypes.map((x) => x.index),
      nameFilter: null,
      clientIdFilter: null,
    });
  };


  render() {
    const { search } = this.state;
    var that = this;

    const errorLevelsMenu = (
      <Select
        mode="multiple"
        allowClear
        style={{ width: "200px" }}
        placeholder="Please select"
        value={that.state.errorValuesFilter}
        onChange={(e) => that.onErrorLevelsChanged(e, that.errorLevelsMenu)}
        showArrow={true}
        ref={(select) => (that.errorLevelsMenu = select)}
        filterOption={(input, option) => that.filterDropDown(input, option)}
      >
        {this.state.errorLevels.map((element) => (
          <Option value={element.index} key={element.index}>
            {element.description}
          </Option>
        ))}
      </Select>
    );

    const errorTypesMenu = (
      <Select
        mode="multiple"
        allowClear
        style={{ width: "200px" }}
        placeholder="Please select"
        value={that.state.errorTypesFilter}
        onChange={(e) => that.onErrorTypesChanged(e, that.errorTypesMenu)}
        showArrow={true}
        ref={(select) => (that.errorTypesMenu = select)}
        filterOption={(input, option) => that.filterDropDown(input, option)}
      >
        {this.state.errorTypes.map((element) => (
          <Option value={element.index} key={element.index}>
            {element.description}
          </Option>
        ))}
      </Select>
    );

    const whiteLabelsMenu = (
      <Select
        mode="multiple"
        allowClear
        style={{ width: "400px" }}
        placeholder="Please select"
        defaultValue={[]}
        value={that.state.whiteLabelsFilter}
        onChange={(e) => that.onWhiteLabelsChanged(e, that.whiteLabelsMenu)}
        showArrow={true}
        ref={(select) => (that.whiteLabelsMenu = select)}
        filterOption={(input, option) => that.filterDropDown(input, option)}
      >
        {this.state.whiteLabels.map((element) => (
          <Option value={element.id} key={element.id}>
            {element.name}
          </Option>
        ))}
      </Select>
    );

    const clientTypesMenu = (
      <Select
        mode="multiple"
        allowClear
        style={{ width: "350px" }}
        placeholder="Please select"
        value={this.state.clientTypesFilter}
        onChange={(e) => that.onClientTypesChanged(e, that.clientTypesMenu)}
        showArrow={true}
        ref={(select) => (that.clientTypesMenu = select)}
        filterOption={(input, option) => that.filterDropDown(input, option)}
      >
        {this.state.clientTypes.map((element) => (
          <Option value={element.index} key={element.index}>
            {element.description}
          </Option>
        ))}
      </Select>
    );

    return (
      <Layout className="layout">
        <Content style={{ padding: "0 5px " }}>
          <Space style={{ padding: "0px 0px 35px 0px" }} wrap>
            <Space style={{ margin: "0 15px 0 0" }}>
              <Title style={{ margin: "0px 0px 0px 0px" }} level={5}>
                Search
              </Title>
              <Input
                allowClear
                prefix={<SearchOutlined />}
                placeholder="Client ID, Account ID or Name"
                style={{ width: 400 }}
                onChange={this.updateContent}
                value={search}
              />
            </Space>
            <Space style={{ margin: "0 15px 0 0" }}>
              <Title style={{ margin: "0px 0px 0px 0px" }} level={5}>
                Error Levels:
              </Title>
              {errorLevelsMenu}
            </Space>
            <Space style={{ margin: "0 15px 0 0" }}>
              <Title style={{ margin: "0px 0px 0px 0px" }} level={5}>
                Error Types:
              </Title>
              {errorTypesMenu}
            </Space>
            <Space style={{ margin: "0 15px 0 0" }}>
              <Title style={{ margin: "0px 0px 0px 0px" }} level={5}>
                White Labels:
              </Title>
              {whiteLabelsMenu}
            </Space>
            <Space style={{ margin: "0 15px 0 0" }}>
              <Title style={{ margin: "0px 0px 0px 0px" }} level={5}>
                Client Types:
              </Title>
              {clientTypesMenu}
            </Space>
          </Space>
          <Row justify="space-between">
            <Col span={16}>
              <Space style={{ padding: "0px 0px 35px 0px" }} wrap>
                <Space style={{ margin: "0 15px 0 0" }}>
                  Auto refresh page:
                  <Switch
                    checked={this.state.autoRefreshPage}
                    onClick={this.refreshPageCheckedChanged}
                  />
                </Space>
                <Space style={{ margin: "0 15px 0 0" }}>
                  Interval secs:
                  <InputNumber
                    disabled={!this.state.autoRefreshPage}
                    min={30}
                    max={240}
                    value={this.state.autoRefreshPageIntervalSecs}
                    onChange={this.autoRefreshPageIntervalSecsChanged}
                  />
                </Space>
              </Space>
            </Col>
          </Row>
          <div className="accountSummariesTables">
            <ErrorsTable
              loadAllWhenNoSearchString={true}
              searchString={this.state.search}
              errorLevelFilter={this.state.errorLevelsFilter}
              errorTypeFilter={this.state.errorTypesFilter}
              whiteLabelsFilter={this.state.whiteLabelsFilter}
              clientTypesFilter={this.state.clientTypesFilter}
              nameFilter={this.state.nameFilter}
              clientIdFilter={this.state.clientIdFilter}
              refreshInterval={
                this.state.autoRefreshPage
                  ? this.state.autoRefreshPageIntervalSecs
                  : 0
              }
              onFilterApplied={(data) => this.onFilterApplied(data)}
              onDataRetrieved={(data) => this.onDataRetrieved(data)}
              errorTypes={this.state.errorTypes}
              whiteLabels={this.state.whiteLabels}
            />
          </div>
        </Content>
      </Layout>
    );
  }
}

export default Helpers.withParams(ErrorsPage);
