export class InstrumentAddOrEditHelper {
  static convertToImportDto(instrument) {

    var converted = {
      name: instrument.publishedName,
      description: instrument.description,

      primaryProvider: instrument.primaryFeed,
      primaryProviderInstrument: instrument.primaryName,
      primaryFairValue : instrument.primaryFairValue,

      secondaryProvider: instrument.secondaryFeed,
      secondaryProviderInstrument: instrument.secondaryName,
      secondaryFairValue : instrument.secondaryFairValue,

      backwardationFix: instrument.backwardationFix,
      zeroPriceFilter: instrument.zeroPriceFilter,

      maxChangeDiscard: instrument.maxChangeDiscard,
      maxChangeMovementValue: instrument.maxChangeMovementValue,
      maxChangeNumberOfPrices: instrument.maxChangeNumberOfPrices,

      timeoutFailover: instrument.timeoutFailover,
      timeoutInSeconds: instrument.timeoutInSec,
      timeoutInSecondsNight: instrument.timeoutInSecAtNight,

      publishedSource: instrument.publishedSource,
      mt4Name: instrument.mt4Name,
      enabled: instrument.enabled,
    };
    console.log("Converting form to import DTO:", instrument, converted);
    return converted;
  }

  static createNewInstrument(publishedSource, existingInstrument) {
    return {
      enabled: true,
      publishedName: existingInstrument?.publishedName ?? "",
      publishedSource: existingInstrument?.publishedSource ?? publishedSource,
      description: existingInstrument?.description ?? "",
      mt4Name: existingInstrument?.mt4Name ? `${existingInstrument.mt4Name}_CLONE` : "",

      timeoutFailover: existingInstrument?.timeoutFailover ?? false,
      timeoutInSec: existingInstrument?.timeoutInSec ?? 0,
      timeoutInSecAtNight: existingInstrument?.timeoutInSecAtNight ?? 0,
      backwardationFix: existingInstrument?.backwardationFix ?? false,
      zeroPriceFilter: existingInstrument?.zeroPriceFilter ?? true,
      maxChangeMovementValue: existingInstrument?.maxChangeMovementValue ?? 1,
      maxChangeNumberOfPrices: existingInstrument?.maxChangeNumberOfPrices ?? 3,
      maxChangeDiscard: existingInstrument?.maxChangeDiscard ?? true,

      primaryFeed: existingInstrument
        ? existingInstrument.primaryFeed
        : publishedSource
        ? publishedSource
        : "",
      primaryId: existingInstrument?.primaryId ? existingInstrument.primaryId : "",
      primaryName: "",
      secondaryFeed: existingInstrument?.secondaryFeed ?? "",
      secondaryName: "",
      tags: [],
      dp: 6
    };
  }

  static swapPrimaryAndSecondary(instrument) {
    const {
      primaryConnectorMapId,
      primaryId,
      primaryName,
      primaryFeed,
      primaryEnabled,
      primaryIsTest,
      primaryStatus,
      primaryActivityStatus,
      primaryFairValue,
      primaryFeedConnectorInstance,
      secondaryConnectorMapId,
      secondaryId,
      secondaryName,
      secondaryFeed,
      secondaryEnabled,
      secondaryIsTest,
      secondaryStatus,
      secondaryActivityStatus,
      secondaryFairValue,
      secondaryFeedConnectorInstance,
      isPrimaryActive,
    } = instrument;

    instrument.primaryConnectorMapId = secondaryConnectorMapId;
    instrument.primaryId = secondaryId;
    instrument.primaryName = secondaryName;
    instrument.primaryFeed = secondaryFeed;
    instrument.primaryEnabled = secondaryEnabled;
    instrument.primaryIsTest = secondaryIsTest;
    instrument.primaryStatus = secondaryStatus;
    instrument.primaryActivityStatus = secondaryActivityStatus;
    instrument.primaryFairValue = secondaryFairValue;
    instrument.primaryFeedConnectorInstance = secondaryFeedConnectorInstance;

    instrument.secondaryConnectorMapId = primaryConnectorMapId;
    instrument.secondaryId = primaryId;
    instrument.secondaryName = primaryName;
    instrument.secondaryFeed = primaryFeed;
    instrument.secondaryEnabled = primaryEnabled;
    instrument.secondaryIsTest = primaryIsTest;
    instrument.secondaryStatus = primaryStatus;
    instrument.secondaryActivityStatus = primaryActivityStatus;
    instrument.secondaryFairValue = primaryFairValue;
    instrument.secondaryFeedConnectorInstance = primaryFeedConnectorInstance;

    instrument.isPrimaryActive = !isPrimaryActive;

    return instrument;
  }
}
