import axios from "axios";
import axiosRetry from "axios-retry";
import { Helpers } from "../helpers/helpers";
import { MarketHelpers } from "../helpers/marketHelpers";


class DataRepositoryProxy {
  constructor() {
    this.DATA_REPOSITORY_API_URL =
      Helpers.currentEnv().DYNAMIC_DATA_REPOSITORY_API_URL +
      "proxy/DataRepository/";
    this.APPLICATION = "DASHBOARD";
    console.debug(
      "Data repository: ",
      Helpers.currentEnvName(),
      this.DATA_REPOSITORY_API_URL
    );

    axiosRetry(axios, {
      retries: 3,
      shouldResetTimeout: true,
      retryCondition: () => true,
      retryDelay: (retryCount) => retryCount * 1000,
    });
  }

  async markets() {
    var response;
    try {
      response = await axios.get(
        this.DATA_REPOSITORY_API_URL + "markets?expired=false",
        { headers: { application: this.APPLICATION } }
      );
    } catch (error) {
      console.error(error);
    }
    var results =
      response && response.status === 200 ? response?.data.results : [];

    var diffs = results.filter((x) => x.prcGenRuleName === "DIFFERENTIAL");
    diffs.forEach((x) => {
      var parts = MarketHelpers.associatedConstituentParts(x);
      var marketIds = parts.filter((y) => y.isMarket).map((y) => y.name);
      var found = results.filter((y) => marketIds.includes(y.id.toString()));
      if (found && found[0] && x.prcGenRawSourceSymbol.includes(found[0]?.id)) {
        x.prcGenRawSourceSymbolDecoded = found[0]?.prcGenRawSourceSymbol;
        x.prcGenRawSourceSymbolDecoded2 = found[1]?.prcGenRawSourceSymbol;
      } else {
        x.prcGenRawSourceSymbolDecoded2 = found[0]?.prcGenRawSourceSymbol;
        x.prcGenRawSourceSymbolDecoded = found[1]?.prcGenRawSourceSymbol;
      }
      x.parts = parts;
      console.debug("MarketIds && Markets", marketIds, found, parts, x);
    });
    console.debug("Data repository Markets:", results);
    return results;
  }

  async marketTradeSettings(marketIds) {
    var response;
    try {
      response = await axios.post(
        this.DATA_REPOSITORY_API_URL + "marketTradeSettings",
        marketIds,
        {
          headers: {
            application: this.APPLICATION,
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
      console.error(error);
    }
    var results =
      response && response.status === 200 ? response?.data.results : [];
    console.debug("Data repository MarketTradeSettings:", results);
    return results;
  }

  async marketGroups() {
    var response;
    try {
      response = await axios.get(
        this.DATA_REPOSITORY_API_URL + "marketGroups",
        { headers: { application: this.APPLICATION } }
      );
    } catch (error) {
      console.error(error);
    }
    var results =
      response && response.status === 200 ? response?.data.results : [];
    console.debug("Data repository Market Groups:", results);
    return results;
  }

  async marketSuperGroups() {
    var response;
    try {
      response = await axios.get(
        this.DATA_REPOSITORY_API_URL + "marketSuperGroups",
        { headers: { application: this.APPLICATION } }
      );
    } catch (error) {
      console.error(error);
    }
    var results =
      response && response.status === 200 ? response?.data.results : [];
    console.debug("Data repository Market Super Groups:", results);
    return results;
  }

  async marketQuotes(marketId) {
    var response;
    try {
      response = await axios.get(
        this.DATA_REPOSITORY_API_URL +
          "marketQuotes/market/" +
          marketId +
          "?activeOnly=true",
        { headers: { application: this.APPLICATION } }
      );
    } catch (error) {
      console.error(error);
    }
    var results =
      response && response.status === 200 ? response?.data.results : [];
    console.debug("Data repository MarketQuotes:", marketId, results);
    return results;
  }

  async marketQuote(marketQuoteId) {
    var response;
    try {
      response = await axios.get(
        this.DATA_REPOSITORY_API_URL + "marketQuotes/" + marketQuoteId,
        { headers: { application: this.APPLICATION } }
      );
    } catch (error) {
      console.error(error);
    }
    var results =
      response && response.status === 200 ? response?.data.results : [];
    console.debug("Data repository MarketQuote:", marketQuoteId, results);
    return results;
  }

  async instrumentRelatedMarkets(instruments) {
    var response;
    try {
      if (instruments.length > 0)
        response = await axios.post(
          this.DATA_REPOSITORY_API_URL + "markets?isActive=true&expired=false",
          instruments,
          {
            headers: {
              application: this.APPLICATION,
              "Content-Type": "application/json",
            },
          }
        );
    } catch (error) {
      console.error(error);
    }
    var results =
      response && response.status === 200 ? response?.data.results : [];
    console.debug("Data repository Markets:", results);
    return results;
  }

  async instrumentRelatedMarketsWithConnectorMaps(instruments, connectorMaps) {
    var response;
    try {
      if (instruments.length > 0)
        response = await axios.post(
          this.DATA_REPOSITORY_API_URL + "markets?isActive=true&expired=false",
          instruments,
          {
            headers: {
              application: this.APPLICATION,
              "Content-Type": "application/json",
            },
          }
        );
    } catch (error) {
      console.error(error);
    }
    var results =
      response && response.status === 200 ? response?.data.results : [];
    if (connectorMaps)
      results.forEach((market) => {
        var found = connectorMaps.filter(
          (x) => x.publishedName === market.instrument
        );
        found.forEach((connectorMap) => (connectorMap.marketIsPresent = true));
      });
    console.debug("Data repository Markets:", results);
    return results;
  }

  async updateMarketTradeableParameters(
    id,
    tradeOnWeb,
    callOnly,
    appearOnWeb,
    noLongPosition,
    noShortPosition
  ) {
    var response;
    try {
      response = await axios({
        method: "put",
        url: this.DATA_REPOSITORY_API_URL + "markets/" + id + "/tradeable",
        headers: {
          application: this.APPLICATION,
          tradeOnWeb:
            tradeOnWeb !== null && tradeOnWeb !== undefined
              ? JSON.stringify(tradeOnWeb)
              : "",
          callOnly:
            callOnly !== null && callOnly !== undefined
              ? JSON.stringify(callOnly)
              : "",
          appearOnWeb:
            appearOnWeb !== null && appearOnWeb !== undefined
              ? JSON.stringify(appearOnWeb)
              : "",
          noLongPosition:
            noLongPosition !== null && noLongPosition !== undefined
              ? JSON.stringify(noLongPosition)
              : "",
          noShortPosition:
            noShortPosition !== null && noShortPosition !== undefined
              ? JSON.stringify(noShortPosition)
              : "",
        },
      });
    } catch (error) {
      console.error(error);
    }
    console.debug("Data repository Update Market Tradeables:", response);
    return response && response.status === 200;
  }

}

export default DataRepositoryProxy;
