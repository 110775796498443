import {
  HubConnectionBuilder,
  LogLevel,
  HubConnectionState,
  HttpTransportType,
} from "@microsoft/signalr";
import { Helpers } from "../helpers/helpers";
import * as _ from "underscore";

class DynamicDataRepositoryMarketWatcherProxy {
  constructor(onData, onReconnect) {
    this.onData = onData;
    this.onReconnect = onReconnect;
    this.DYNAMIC_DATA_REPOSITORY_API_URL =
      Helpers.currentEnv().DYNAMIC_DATA_REPOSITORY_API_URL;
    console.debug(
      "Dynamic data repository: ",
      Helpers.currentEnvName(),
      this.DYNAMIC_DATA_REPOSITORY_API_URL
    );

    this.hubConnection = new HubConnectionBuilder()
      .withUrl(this.DYNAMIC_DATA_REPOSITORY_API_URL + "wsMarketWatcher", {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
      })
      .configureLogging(LogLevel.Debug)
      .withAutomaticReconnect()
      .build();

    this.hubConnection.keepAliveIntervalInMilliseconds = 60000;
    this.hubConnection.serverTimeoutInMilliseconds = 90000;

    this.hubConnection.onreconnected((connectionId) => {
      var marketSuperGroupIds = [];
      if (this.onReconnect) marketSuperGroupIds = this.onReconnect();
      if (marketSuperGroupIds && marketSuperGroupIds.length > 0) {
        console.debug("MarketWatcher Reconnected...", marketSuperGroupIds);
        this.hubConnection
          .invoke("refresh", marketSuperGroupIds)
          .catch((err) => console.error(err));
      }
    });

    this.hubConnection.onclose((connectionId) => {
      console.debug("Closed connectionId: ", connectionId);
    });

    this.hubConnection.on("marketQuotes", (message) => {
      if (this.onData) this.onData(message);
    });
  }

  async connect() {
    if (this.hubConnection.state === HubConnectionState.Disconnected) {
      await this.hubConnection
        .start()
        .then(() => {
        })
        .catch((error) => {
          console.assert(
            this.hubConnection.state === HubConnectionState.Disconnected
          );
          console.debug(error);
          setTimeout(() => this.connect(), 2000);
        });
    }
  }

  async subscribeAndOverride(marketSuperGroupIds) {
    marketSuperGroupIds = _.uniq(marketSuperGroupIds, (x) => x);
    await this.hubConnection
      .invoke("subscribeAndOverride", marketSuperGroupIds)
      .catch((err) => console.error(err));
    console.debug("MarketWatcher SubscribeAndOverride:", marketSuperGroupIds);
  }

  async unsubscribe() {
    await this.hubConnection
      .invoke("unsubscribe")
      .catch((err) => console.error(err));
    console.debug("MarketWatcher SubscribeAndOverride:", []);
    console.debug("MarketWatcher Unsubscribe");
  }

  async stop() {
    await this.hubConnection.stop().catch((err) => console.error(err));
  }
}

export default DynamicDataRepositoryMarketWatcherProxy;
