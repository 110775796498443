export class ProviderInstrumentHelper {

  static emptyTestProviderInstrument() {
    return {
      enabled: true,
      config_type : "Manual",
      config_interval : 500,
      config_range_min : 1.5,
      config_range_max : 2.5,
      config_step : 0.0001,
      config_spread : 0.0005,
      isTest : true
    };
  }

  static extractProviderInstrument(providerInstrument, isTest) {
    if (isTest) {
      return {
        id: providerInstrument.id,
        name: providerInstrument.name,
        providerName : providerInstrument.providerName,
        description: providerInstrument.description,
        bloombergId: providerInstrument.bloombergId,
        cmcId: providerInstrument.cmcId,
        isin: providerInstrument.isin,
        enabled: providerInstrument.enabled,
        autoGenerateConfig: `{"AutoGenerateType": "${providerInstrument.config_type}", "IntervalMs": ${providerInstrument.config_interval}, "RangeMin": ${providerInstrument.config_range_min}, "RangeMax": ${providerInstrument.config_range_max}, "Step": ${providerInstrument.config_step}, "Spread": ${providerInstrument.config_spread}}`,
      };
    }
    return {
      id: providerInstrument.id,
      name: providerInstrument.name,
      providerName : providerInstrument.providerName,
      description: providerInstrument.description,
      bloombergId: providerInstrument.bloombergId,
      cmcId: providerInstrument.cmcId,
      isin: providerInstrument.isin,
      enabled: providerInstrument.enabled,
      autoGenerateConfig: null,
    };
  }

  // used by provider instrument
  static mergeProviderInstrument(
    providerInstrument,
    modifiedProviderInstrument
  ) {
    providerInstrument.name = modifiedProviderInstrument.name;
    providerInstrument.enabled = modifiedProviderInstrument.enabled;
    providerInstrument.description = modifiedProviderInstrument.description;
    providerInstrument.bloombergId = modifiedProviderInstrument.bloombergId;
    providerInstrument.cmcId = modifiedProviderInstrument.cmcId;
    providerInstrument.isin = modifiedProviderInstrument.isin;
    providerInstrument.maturity = modifiedProviderInstrument.maturity;
  }
}
