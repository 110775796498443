import React from "react";
import { Table } from "antd";
import { RenderHelpers } from "../account-summary/renderHelpers";
import AccountDetailsDropdown from "./errorsAccountDropdown.js";

class SubtypeDetailsDropdown extends React.Component {
  constructor(props) {
    super();
    this.props = props;
    this.state = { record: props.record, errorTypes: props.errorTypes };
  }
  async componentDidMount() {
    var columns = [
      {
        dataIndex: "errorSubtype",
        align: "left",
        key: "subtype",
      },
      {
        dataIndex: "errorSubtypeCount",
        width: "5%",
        key: "subtypecount",
        align: "right",
        render: (text) => RenderHelpers.renderOrder(text),
      },
    ];
    this.setState({ columns: columns });
  }

  renderWhiteLabels(text, record) {
    for(let whiteLabel of this.props.whiteLabels)
    {
      if(text === whiteLabel.id)
      {
        return whiteLabel.name
      }
    }
  }

  renderTotalErrors(text, record) {
    let color= "white";
    if (record.errorsOfType < text) {
      color="orange"
    }
    return (
        <font color={color}>
          {text}
        </font>
    );
  }

  render() {
    return (
      <div>
        <div className="accountSummaryTable">
          <Table
            dataSource={this.props.record}
            pagination={{
              hideOnSinglePage: true,
              size: 30,
              total: this.state.filteredErrorsCount,
              showSizeChanger: true,
              showTotal: total => "" + total + " Errors",
            }}
            columns={this.state.columns}
            rowClassName={(record) => "currencyBlue"}
            expandable={{
                expandedRowRender: (record) => (
                  <AccountDetailsDropdown
                    record={record.errorAndAccountDetailsDtos}
                    errorTypes={this.props.errorTypes}
                    whiteLabels={this.props.whiteLabels}
                  />
                ),
                columnWidth: 3,
              }}
            rowKey={(record) => record.errorSubtype}
          />
        </div>
      </div>
    );
  }
}

export default SubtypeDetailsDropdown;