export class DataHelpers {
  // used by dash proxy
  static extractInstrumentNamesFromConnectorMaps(connectorMaps) {
    return connectorMaps.map((x) => x.publishedName);
  }

  // used by market monitor
  static extractTime(dateTime, isNow = false) {
    if (isNow || dateTime) {
      var time = dateTime ? new Date(dateTime) : new Date();
      var h = dateTime ? time.getHours() : time.getUTCHours();
      var m = dateTime ? time.getMinutes() : time.getUTCMinutes();
      var s = dateTime ? time.getSeconds() : time.getUTCSeconds();
      return { h, m, s };
    }
    return undefined;
  }
}
