import React, { Component } from "react";
import "../main.css";

class Price extends Component {
  constructor(props) {
    super();
    this.myRef = React.createRef();

    
    this.state = {
      prevNumber: null,
      color: "transparent",
      text: "-",
      bold: true
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.currentNumber = this.props.text;
    this.prevNumber = this.currentNumber;
    this.setState({
      prevNumber: isNaN(this.prevNumber) ? "-" : this.prevNumber,
      color: "transparent",
      bold: this.props.bold === null || this.props.bold === undefined? true : this.props.bold
    });
    this.updateText();
  }

  updateText(){
    var text = this.prevNumber;
    if (this.prevNumber !== null && this.prevNumber !== undefined && typeof this.props.text === 'number' && isNaN(this.prevNumber) === false){
        var dp = this.props.dp >= 0 ? this.props.dp : 6;
        if (this.props.currency){
          text = new Intl.NumberFormat(this.currency, {style: 'currency'}).format(this.prevNumber);
        }
        else if (this.props.showThousandsSeperator){
          text = new Intl.NumberFormat('en-GB', {minimumFractionDigits:dp, maximumFractionDigits:dp }).format(this.prevNumber);
        }
        else {
          text = this.prevNumber.toFixed(dp);
        }
        if (this.props.currencySymbol){
          text = this.props.currencySymbol+text;
        }
      
    }
    if (this.prevNumber === null || this.prevNumber === undefined){
      text = '-';
    }
    this.setState({
      text: text
    });
  }

  numberWithCommas(x) {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }

  componentDidUpdate(prevProps) {
    if (this._isMounted && this.props.text !== prevProps.text) {
      this.currentNumber = this.props.text;
      var arrow = 
        this.currentNumber === this.prevNumber
          ? "e"
          : this.currentNumber > this.prevNumber
          ? "u"
          : "d";
      this.color =
        arrow === "u"
          ? "#49AA19EE"
          : arrow === "d"
          ? "#D84A1BEE"
          : "transparent";
      //}
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(
        () =>
          this.setState({ color: "transparent" }),
        1000
      );
      this.prevNumber = this.currentNumber;
      this.setState({
        prevNumber: isNaN(this.prevNumber) ? "-" : this.prevNumber,
        color: isNaN(this.prevNumber) ? "-" : this.color,
      });
      this.updateText();
    }
  }

  componentWillUnmount = () => {
    if (this.timeout) clearTimeout(this.timeout);
    this._isMounted = false;
  };

  render() {
    if (this.state.bold){
    return (
      <div style={{ backgroundColor: this.state.color, padding: "0 5px 0 5px" }}>
        <font className="bold16">{this.state.currency}{this.state.text}</font>
      </div>
    );
    }
    else{
      return (
        <div style={{ backgroundColor: this.state.color, padding: "0 5px 0 5px" }}>
          {this.state.currency}{this.state.text}
        </div>
      );
    }
  }
}

export default Price;
