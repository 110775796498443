import React from "react";
import { useMsal } from "@azure/msal-react";
import { Button } from "antd";
import { appRoles } from "../authConfig";

/**
 * Renders a sign-out button
 */
export const SignOutButton = () => {
    const { instance, accounts } = useMsal();
    const handleLogout = () => {
        instance.logoutRedirect({
            postLogoutRedirectUri: "/",
        });
    }
    var role = 'Read';
    
    if (accounts && accounts[0].idTokenClaims['roles']) {
        if (accounts[0].idTokenClaims['roles'].includes(appRoles.Admin)){
            role = 'Admin'
        }
        else if (accounts[0].idTokenClaims['roles'].includes(appRoles.Write)){
            role = 'Write'
        }
    }
    return (
        
        <div>
            Signed in as {accounts[0].name} ({role})
            <Button variant="secondary" style={{marginLeft: "10px"}} className="ml-auto" drop="left" title="Sign Out" onClick={() => handleLogout()}>Sign Out</Button>
        </div>
        
    )
}