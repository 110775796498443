import axios from "axios";
import axiosRetry from "axios-retry";
import { Helpers } from "../helpers/helpers";

class DbListenerProxy {
  constructor(props) {
    this.DB_LISTENER_API_URL = Helpers.currentEnv().DYNAMIC_DATA_REPOSITORY_API_URL+ "proxy/dbListener/";
    console.debug(
      "Data repository: ",
      Helpers.currentEnvName(),
      this.DB_LISTENER_API_URL
    );

    axiosRetry(axios, {
      retries: 3,
      shouldResetTimeout: true,
      retryCondition: (error) => true,
      retryDelay: (retryCount) => retryCount * 1000,
    });
  }

  async dbs() {
    var response;
    try {
      response = await axios.get(
        this.DB_LISTENER_API_URL + "db"
      );
    } catch (error) {
      console.error(error);
    }
    var results =
      response && response.status === 200 ? response?.data.results : [];
    console.debug("DbListener DBs:", results);
    return results;
  }

  async db(table) {
    var response;
    try {
      response = await axios.get(
        this.DB_LISTENER_API_URL + "db/" + table
      );
    } catch (error) {
      console.error(error);
    }
    var results =
      response && response.status === 200 ? response?.data.results[0] : undefined;
    console.debug("DbListener DB:", results);
    return results;
  }

  async start(db,table) {
    var response;
    try {
      response = await axios.post(
        this.DB_LISTENER_API_URL + "db/" + db + "/table/" + table + "/start"
      );
    } catch (error) {
      console.error(error);
    }
    console.debug("DbListener Start:", response);
  }

  async stop(db,table) {
    var response;
    try {
      response = await axios.post(
        this.DB_LISTENER_API_URL + "db/" + db + "/table/" + table + "/stop"
      );
    } catch (error) {
      console.error(error);
    }
    console.debug("DbListener Stop:", response);
  }

  async reset(db,table) {
    var response;
    try {
      response = await axios.post(
        this.DB_LISTENER_API_URL + "db/" + db + "/table/" + table + "/reset"
      );
    } catch (error) {
      console.error(error);
    }
    console.debug("DbListener Reset:", response);
  }
}

export default DbListenerProxy;
