import React from "react";
import { Table } from "antd";
import { RenderHelpers } from "./renderHelpers";
import Errors from "./errors.js";
import OrderSummary from "./closingOrderSummary";

class TradeSummary extends React.Component {
  constructor(props) {
    super();
    this.props = props;
    this.state = { record: props.record };
  }
  async componentDidMount() {
    var columns = [
      {
        title: "Trade ID",
        dataIndex: "tradeId",
        width: "30%",
        render: (text) => RenderHelpers.renderTrade(text),
      },
      {
        title: "Buy/Sell",
        width: "10%",
        dataIndex: "tradeModeType",
      },
      {
        title: "PnL",
        width: "10%",
        render: (record) =>
          RenderHelpers.renderAccountSummaryValueWithSymbol(
            record.currencyCodeSymbol,
            record.openPnL,
            true
          ),
      },
      {
        title: "Margin",
        width: "10%",
        render: (record) =>
          RenderHelpers.renderAccountSummaryValueWithSymbol(
            record.currencyCodeSymbol,
            record.margin,
            true
          ),
      },
      {
        title: "Stake",
        width: "10%",
        render: (record) =>
          RenderHelpers.renderAccountSummaryValueWithSymbol(
            record.currencyCodeSymbol,
            record.stakeBalance,
            true
          ),
      },
      {
        title: "Open Price",
        width: "10%",
        render: (record) =>
          RenderHelpers.renderAccountSummaryValueWithSymbol(
            record.currencyCodeSymbol,
            record.openPrice,
            true
          ),
      },
      {
        title: "Current Price",
        width: "10%",
        render: (record) =>
          RenderHelpers.renderAccountSummaryValueWithSymbol(
            record.currencyCodeSymbol,
            record.currentPrice,
            true
          ),
      },
      {
        title: "Stop Order",
        width: "10%",
        render: (record) => {
          var text = "";
          let color="#49AA19";
          if (record.hasOrders) {
            text = "S";
            if (record.hasGuaranteedStopOrder) {
              text = "GS";
            }
          }
          else {
            text = "-";
            color = "#FFFFFF"
          }
          return (
            <b>
              <font className="bold16" color={color}>
                {text}
              </font>
            </b>
          );
        },
      },
    ];
    this.setState({ columns: columns });
  }
  render() {
    return (
      <>
        <Errors record={this.props.record} />
        <div className="tradeSummaryTable">
          {this.state.record.trades && this.state.record.trades.length > 0 && (
            <Table
              dataSource={this.props.record.trades}
              pagination={false}
              columns={this.state.columns}
              rowClassName={"tradeBlue"}
              rowExpandable={(record) =>
                (record.linkedOrders && record.linkedOrders.length > 0) ||
                record.hasError
              }
              defaultExpandAllRows={true}
              expandedRowRender={(record, i) => (
                <OrderSummary record={record} />
              )}
            />
          )}
        </div>
      </>
    );
  }
}

export default TradeSummary;
