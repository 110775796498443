import axios from "axios";
import axiosRetry from "axios-retry";
import { Helpers } from "../helpers/helpers";

class AccountSummaryDataAccess {
  constructor(props) {
    this.ACCOUNT_SUMMARIES_REPOSITORY_URL = Helpers.currentEnv().DYNAMIC_DATA_REPOSITORY_API_URL+ "proxy/accountSummaryDataAccess/";
    console.debug(
      "Account summary repository: ",
      Helpers.currentEnvName(),
      this.ACCOUNT_SUMMARIES_REPOSITORY_URL
    );

    axiosRetry(axios, {
      retries: 3,
      shouldResetTimeout: true,
      retryCondition: (error) => true,
      retryDelay: (retryCount) => retryCount * 1000,
    });
  }

  async clientSummaries(
    searchFilter,
    page,
    pageLength,
    sortCol,
    sortOrder,
    includeLegacy,
    params = {}
  ) {
    var response;
    try {
      var searchFilterString = searchFilter
        ? "searchFilter=" + searchFilter + "&"
        : "";
      var sortString = sortCol
        ? "sort=" + (sortCol + (sortOrder ? " " + sortOrder : "")) + "&"
        : "";
      var fieldFilters = "";
      if (Object.keys(params).length > 0) {
        for (var element in params) {
          fieldFilters = fieldFilters + "&" + element + "=" + params[element];
        }
      }
      var legacySummariesString = includeLegacy
        ? "&includeLegacyAccountSummary=" + includeLegacy
        : "";
      response = await axios.get(
        this.ACCOUNT_SUMMARIES_REPOSITORY_URL +
          "summaries/?" +
          searchFilterString +
          sortString +
          "page=" +
          page +
          legacySummariesString +
          "&pageLength=" +
          pageLength +
          fieldFilters
      );
    } catch (error) {
      console.error(error);
    }
    var results = response && response.status === 200 ? response?.data : [];
    console.debug(
      "Client Summaries:",
      results.details,
      " total: ",
      results.totalSummaries,
      " errorCount: ",
      results.totalErrorCount,
      " results: ",
      results
    );
    return results;
  }

  async errorSummaries(
    searchFilter,
    params = {}
  ) {
    var response;
    try {
      var searchFilterString = searchFilter
        ? "searchFilter=" + searchFilter
        : "";
      var fieldFilters = "";
      if (Object.keys(params).length > 0) {
        for (var element in params) {
          fieldFilters = fieldFilters + "&" + element + "=" + params[element];
        }
      }

      response = await axios.get(
          this.ACCOUNT_SUMMARIES_REPOSITORY_URL +
          "accountErrors?" +
          searchFilterString + 
          fieldFilters
      );
    } catch (error) {
      console.error(error);
    }
    var results = response && response.status === 200 ? response?.data : [];
    console.debug(
      "Error Summaries:",
      results.details,
      " total: ",
      results.accountsWithErrors,
      " errorCount: ",
      results.filteredErrorCount,
      " results: ",
      results
    );
    return results;
  }

  async getOptions() {
    var response;
    try {
      response = await axios.get(
        this.ACCOUNT_SUMMARIES_REPOSITORY_URL + "summaries/options"
      );
    } catch (error) {
      console.error(error);
    }
    var results = response && response.status === 200 ? response?.data : [];
    console.debug("Client Summary Options:", results);
    return results;
  }
}

export default AccountSummaryDataAccess;
