import React from 'react';
import AccountSummaryDataAccess from '../proxies/accountSummaryDataAccess.js';
import ClientSummaries from './clientSummaries.js';
import { InputNumber, Layout, Input, Typography, Space, Switch, Select, Badge, Row, Col } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { Helpers } from "../helpers/helpers";
import { AppContext } from '../dataContext';
const { Content } = Layout;
const { Title } = Typography;
const { Option } = Select;

class ClientAccountSummaries extends React.Component {
  static contextType = AppContext;
  constructor(props) {
    super();
    this.props = props;
    this.accountSummaryProxy = new AccountSummaryDataAccess();
    this.state = {
      search: '',
      errorTypes: [],
      errorLevels: [],
      alertLevels: [],
      selectedClientTypes: [],
      whiteLabels: [],
      clientTypes: [],
      legacyEnabled: false,
      allowLegacy: false,
      lastLoggedInAgoMonthsFilter: null,
      hasPositionsFilter: true,
      hasErrorsFilter: null,
      accountTypeFilter: null,
      userNameFilter: null,
      nameFilter: null,
      clientIdFilter: null,
      errorTypesFilter: [],
      errorLevelsFilter: [],
      whiteLabelsFilter: [],
      clientTypesFilter: [],
      alertLevelsFilter: [],
      defaultClientTypesFilter: [],
      autoRefreshPageIntervalSecs: 30,
      hasErrorsOptions: [
        { key: 'All', text: 'All', value: null },
        { key: 'Errors', text: 'Errors', value: true },
        { key: 'No Errors', text: 'No Errors', value: false },
      ],
      hasPositionsOptions: [
        { key: 'All', text: 'All', value: null },
        { key: 'Yes', text: 'Yes', value: true },
        { key: 'No', text: 'No', value: false },
      ],
      lastLoggedInOptions: [
        { key: 'All', text: 'All', value: null },
        { key: '1', text: 'Within last month', value: 1 },
        { key: '6', text: 'Within last 6 months', value: 6 },
        { key: '12', text: 'Within last year', value: 12 },
      ],
      errorCount: 0,
    };
  }

  async componentDidMount() {
    this.context.updateMenuSelection('accountSummaries');

    document.title = 'Account Summaries';
    await this.getOptions();
    this.loadUrlParameters();
  }

  async componentDidUpdate(prevProps, prevState) {
    var currentQueryString = new URLSearchParams(this.props.location.search).toString().toLowerCase();
    var previousQueryString = new URLSearchParams(prevProps.location.search).toString().toLowerCase();
    if (currentQueryString !== previousQueryString) {
      this.loadUrlParameters();
    }
  }

  async getOptions() {
    try {
      var response = await this.accountSummaryProxy.getOptions();
      this.setState({
        allowLegacy: response.allowLegacy,
        errorTypes: response.errorTypes,
        errorLevels: response.errorLevels,
        defaultClientTypesFilter: response.defaultClientTypes.map(x => x.index),
        alertLevels: response.alertLevels,
        clientTypesFilter: response.defaultClientTypes.map(x => x.index),
        whiteLabels: response.whiteLabels,
        clientTypes: response.clientTypes,
      });
    } catch (error) {
      console.error(error);
      this.setState({
        allowLegacy: false,
        errorLevels: [{ key: 'Error', description: 'Errors' }],
        errorTypes: [],
        defaultClientTypesFilter: [],
        clientTypesFilter: [],
        whiteLabels: [],
        clientTypes: [],
        alertLevels: [],
      });
    }
  }

  updateContent = async (event, nameFromContext) => {
    var name = event ? event.target.value : nameFromContext;
    this.setState({ search: name });
    this.context.state.search = name;
  };

  legacyCheckedChanged = checked => {
    if (checked) {
      this.setState({ legacyEnabled: true });
    } else {
      this.setState({ legacyEnabled: false });
    }
  };

  refreshPageCheckedChanged = checked => {
    if (checked) {
      this.setState({ autoRefreshPage: true });
    } else {
      this.setState({ autoRefreshPage: false });
    }
  };

  autoRefreshPageIntervalSecsChanged = value => {
    this.setState({ autoRefreshPageIntervalSecs: value });
  };

  handleHasPositionsChanged = val => {
    if (val) {
      this.setState({ hasPositionsFilter: val.value });
    }
  };

  handleHasErrorsChanged = val => {
    if (val) {
      this.setState({ hasErrorsFilter: val.value });
    }
  };

  handleLastLoggedInChanged = val => {
    if (val) {
      this.setState({ lastLoggedInAgoMonthsFilter: val.value });
    }
  };

  onAlertLevelsChanged = (e, control) => {
    if (e) {
      this.setState({ alertLevelsFilter: e });
    } else {
      this.setState({ alertLevelsFilter: [] });
    }

    if (control) {
      control.blur();
    }
  };

  onClientTypesChanged = (e, control) => {
    if (e) {
      this.setState({ clientTypesFilter: e });
    } else {
      this.setState({ clientTypesFilter: [] });
    }

    if (control) {
      control.blur();
    }
  };

  onWhiteLabelsChanged = (e, control) => {
    if (e) {
      this.setState({ whiteLabelsFilter: e });
    } else {
      this.setState({ whiteLabelsFilter: [] });
    }

    if (control) {
      control.blur();
    }
  };

  onFilterApplied = filter => {
    if (filter) {
      this.updateUrl(filter);
    }
  };

  onDataRetrieved = data => {
    this.setState({ errorCount: data.errorCount });
  };

  loadUrlParameters = () => {
    const query = new URLSearchParams(this.props.location.search);
    const searchVal = query.get('search');
    if (searchVal) {
      this.setState({ search: searchVal });
    }

    const hasPositionsVal = query.get('hasPositions');
    if (hasPositionsVal) {
      var filteredHasPositionsOptions = this.state.hasPositionsOptions.filter(x => x.value === hasPositionsVal || x.key.toLowerCase() === hasPositionsVal.toLowerCase());
      if (filteredHasPositionsOptions.length > 0) {
        this.handleHasPositionsChanged(filteredHasPositionsOptions[0]);
      }
    }

    var hasErrorsVal = query.get('hasErrors');
    if (hasErrorsVal) {
      var filteredhasErrorsOptions = this.state.hasErrorsOptions.filter(x => x.value === hasErrorsVal || x.key.toLowerCase() === hasErrorsVal.toLowerCase() || (hasErrorsVal === 'true' && x.value === true));
      if (filteredhasErrorsOptions.length > 0) {
        this.handleHasErrorsChanged(filteredhasErrorsOptions[0]);
      }
    }

    const lastLoggedInVal = query.get('LastLoggedIn');
    if (lastLoggedInVal) {
      var filteredLastLoggedInOptions = this.state.lastLoggedInOptions.filter(x => x.value === lastLoggedInVal || x.key.toLowerCase() === lastLoggedInVal.toLowerCase());
      if (filteredLastLoggedInOptions.length > 0) {
        this.handleLastLoggedInChanged(filteredLastLoggedInOptions[0]);
      }
    }

    const accountTypeVal = query.get('accountType');
    if (accountTypeVal) {
      this.setState({ accountTypeFilter: accountTypeVal });
    }

    const nameVal = query.get('name');
    if (nameVal) {
      this.setState({ nameFilter: nameVal });
    }

    const userNameVal = query.get('userName');
    if (userNameVal) {
      this.setState({ userNameFilter: userNameVal });
    }
    const clientIdVal = query.get('clientId');
    if (clientIdVal) {
      this.setState({ clientIdFilter: clientIdVal });
    }
    this.setState({
      whiteLabelsFilter: this.getUrlPropFromQueryIntList(query, 'whiteLabels', this.state.whiteLabels, x => x.id, []),
    });
    this.setState({
      errorTypeFilter: this.getUrlPropFromQueryIntList(query, 'errorTypes', this.state.errorTypes, x => x.index, []),
    });
    this.setState({
      errorLevelFilter: this.getUrlPropFromQueryIntList(query, 'errorLevels', this.state.errorLevels, x => x.index, []),
    });
    this.setState({
      alertLevelsFilter: this.getUrlPropFromQueryIntList(query, 'alertLevels', this.state.alertLevels, x => x.id, []),
    });
    this.setState({
      clientTypesFilter: this.getUrlPropFromQueryIntList(
        query,
        'clientTypes',
        this.state.clientTypes,
        x => x.index,
        this.state.defaultClientTypesFilter
      ),
    });
  };


  getUrlPropFromQueryIntList(query, queryParam, allOptions, optionsValueFunction, defaultValues) {
    const whiteLabelsVal = query.get(queryParam);
    if (whiteLabelsVal) {
      if (whiteLabelsVal === 'All') {
        return allOptions.map(x => optionsValueFunction(x));
      } else {
        var split = whiteLabelsVal.split(',');
        if (split.length === 0) {
          if (defaultValues) {
            return defaultValues;
          } else {
            return [];
          }
        } else {
          for (var a in split) {
            split[a] = parseInt(split[a], 10);
          }
        }
        return split;
      }
    } else {
      if (defaultValues) {
        return defaultValues;
      } else {
        return [];
      }
    }
  }

  updateUrl = filter => {
    const query = new URLSearchParams(this.props.location.search);
    var originalString = query.toString().toLowerCase();

    var title = 'Account Summaries';
    if (filter.searchString) {
      query.set('search', filter.searchString);
      title = title + ', Search ' + filter.searchString;
    } else {
      query.delete('search');
    }

    if (filter.hasPositionsFilter !== undefined) {
      var filteredHasPositionsOptions = this.state.hasPositionsOptions.filter(x => x.value === filter.hasPositionsFilter);
      if (filteredHasPositionsOptions.length > 0) {
        if (filteredHasPositionsOptions[0].value === null) {
          query.set('hasPositions', 'All');
        }
        if (filteredHasPositionsOptions[0].value) {
          query.set('hasPositions', true);
          title = title + ', Has Positions';
        } else {
          query.set('hasPositions', false);
          title = title + ', Has No Positions';
        }
      }
    } else {
      query.set('hasPositions', 'All');
    }

    if (filter.hasErrorsFilter !== undefined) {
      var filteredhasErrorsOptions = this.state.hasErrorsOptions.filter(x => x.value === filter.hasErrorsFilter);
      if (filteredhasErrorsOptions.length > 0) {
        if (filteredhasErrorsOptions[0].value === null) {
          query.set('hasErrors', 'All');
        }
        if (filteredhasErrorsOptions[0].value) {
          query.set('hasErrors', true);
          title = title + ', Has Errors';
        } else {
          query.set('hasErrors', false);
          title = title + ', Has No Errors';
        }
      }
    } else {
      query.set('hasErrors', 'All');
    }

    if (filter.lastLoggedInAgoMonthsFilter !== undefined) {
      var filteredLastLoggedInOptions = this.state.lastLoggedInOptions.filter(x => x.value === filter.lastLoggedInAgoMonthsFilter);
      if (filteredLastLoggedInOptions.length > 0) {
        if (filteredLastLoggedInOptions[0].value === null) {
          query.delete('LastLoggedIn');
        } else {
          query.set('LastLoggedIn', filteredLastLoggedInOptions[0].value);
          title = title + ', Logged in ' + filteredLastLoggedInOptions[0].text + ' months';
        }
      }
    } else {
      query.delete('LastLoggedIn');
    }

    title += this.updateUrlFromFilter(query, filter.accountTypeFilter, 'accountType', "Account Type");
    title += this.updateUrlFromFilter(query, filter.nameFilter, 'name', "Name");
    title += this.updateUrlFromFilter(query, filter.userNameFilter, 'userName', "UserName");
    title += this.updateUrlFromFilter(query, filter.clientIdFilter, 'clientId', "Client")

    title +=
      this.updateUrlFromFilterList(
        query,
        filter.whiteLabelsFilter,
        'whiteLabels',
        'White Labels',
        this.state.whiteLabels,
        (f, option) => f === option.id,
        option => option.name
      );
    title +=
      this.updateUrlFromFilterList(
        query,
        filter.errorTypeFilter,
        'errorTypes',
        'Error Types',
        this.state.errorTypes,
        (f, option) => f === option.index,
        option => option.description
      );
    title +=
      this.updateUrlFromFilterList(
        query,
        filter.errorLevelFilter,
        'errorLevels',
        'Error Levels',
        this.state.errorLevels,
        (f, option) => f === option.index,
        option => option.description
      );
    title +=
      this.updateUrlFromFilterList(
        query,
        filter.alertLevelsFilter,
        'alertLevels',
        'Alert Levels',
        this.state.alertLevels,
        (f, option) => f === option.id,
        option => option.description
      );
    title +=
      this.updateUrlFromFilterList(
        query,
        filter.clientTypesFilter,
        'clientTypes',
        'Client Types',
        this.state.clientTypes,
        (f, option) => f === option.index,
        option => option.description
      );

    if (query.toString().toLowerCase() !== originalString) {
      if (!this.props.params.history)
        this.props.params.history = [];
      this.props.params.history.push({
        search: '?' + query.toString(),
      });
    }

    document.title = title;
    this.props.navigate(this.props.location.pathname + "?" + query.toString());
  };

  updateUrlFromFilter(query, filter, queryProperty, title) {
    if (filter) {
      query.set(queryProperty, filter);
      return ', ' + title + ":" + filter;
    } else {
      query.delete(queryProperty);
      return '';
    }
  }
  updateUrlFromFilterList = (query, filter, queryProperty, title, options, optionFilterFunction, optionDescriptionFunction) => {
    var returnTitle = '';
    if (filter && filter.length > 0) {
      if (filter.length === options.length) {
        query.set(queryProperty, 'All');
      } else {
        query.set(queryProperty, filter.join());
        var names = filter.map(x => {
          var filteredOptions = options.filter(option => optionFilterFunction(x, option));
          if (filteredOptions.length > 0) {
            return optionDescriptionFunction(filteredOptions[0]);
          }
          return x;
        });
        returnTitle = ', ' + title + ':' + names.join();
      }
    } else {
      query.delete(queryProperty);
    }
    return returnTitle;
  };

  filterDropDown = (input, option) => {
    return (
      option.props &&
      ((option.props.value &&
        (option.props.value === input ||
          (Object.prototype.toString.call(option.props.value) === '[object String]' &&
            option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0))) ||
        (option.props.key && option.props.key.toLowerCase().indexOf(input.toLowerCase()) >= 0) ||
        (option.props.children && option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0))
    );
  };

  clearAllFilters = () => {
    this.setState({
      search: null,
      hasPositionsFilter: null,
      hasErrorsFilter: null,
      lastLoggedInAgoMonthsFilter: null,
      errorLevelFilter: [],
      errorTypeFilter: [],
      whiteLabelsFilter: [],
      clientTypesFilter: this.state.clientTypes.map(x => x.index),
      accountTypeFilter: null,
      nameFilter: null,
      userNameFilter: null,
      clientIdFilter: null,
      alertLevelsFilter: []
    });
  }
  onShowErrorsChange = (checked, e) => {
    if (checked) {
      this.clearAllFilters();
      this.setState({
        hasErrorsFilter: true,
      });
    } else {
      this.setState({
        hasErrorsFilter: null,
      });
    }
  };

  render() {
    const { search } = this.state;
    var that = this;

    const alertLevelsMenu = (
      <Select
        mode='multiple'
        allowClear
        style={{ width: '270px' }}
        placeholder='Please select'
        value={that.state.alertLevelsFilter}
        onChange={e => that.onAlertLevelsChanged(e, that.alertLevelsMenu)}
        showArrow={true}
        ref={select => (that.alertLevelsMenu = select)}
        filterOption={(input, option) => that.filterDropDown(input, option)}>
        {this.state.alertLevels.map(element => (
          <Option value={element.id} key={element.key}>
            {element.description}
          </Option>
        ))}
      </Select>
    );

    const whiteLabelsMenu = (
      <Select
        mode='multiple'
        allowClear
        style={{ width: '400px' }}
        placeholder='Please select'
        defaultValue={[]}
        value={that.state.whiteLabelsFilter}
        onChange={e => that.onWhiteLabelsChanged(e, that.whiteLabelsMenu)}
        showArrow={true}
        ref={select => (that.whiteLabelsMenu = select)}
        filterOption={(input, option) => that.filterDropDown(input, option)}>
        {this.state.whiteLabels.map(element => (
          <Option value={element.id} key={element.id}>
            {element.name}
          </Option>
        ))}
      </Select>
    );

    const clientTypesMenu = (
      <Select
        mode='multiple'
        allowClear
        style={{ width: '350px' }}
        placeholder='Please select'
        value={this.state.clientTypesFilter}
        onChange={e => that.onClientTypesChanged(e, that.clientTypesMenu)}
        showArrow={true}
        ref={select => (that.clientTypesMenu = select)}
        filterOption={(input, option) => that.filterDropDown(input, option)}>
        {this.state.clientTypes.map(element => (
          <Option value={element.index} key={element.index}>
            {element.description}
          </Option>
        ))}
      </Select>
    );

    return (
      <Layout className='layout'>
        <Content style={{ padding: '0 5px ' }}>
          <Space style={{ padding: '0px 0px 35px 0px' }} wrap>
            <Space style={{ margin: '0 15px 0 0' }}>
              <Title style={{ margin: '0px 0px 0px 0px' }} level={5}>
                Search
              </Title>
              <Input
                allowClear
                prefix={<SearchOutlined />}
                placeholder='clientId, Name or UserName'
                style={{ width: 400 }}
                onChange={this.updateContent}
                value={search}
              />
            </Space>
            <Space style={{ margin: '0 15px 0 0' }}>
              <Title style={{ margin: '0px 0px 0px 0px' }} level={5}>
                Has Positions:
              </Title>
              <Select value={this.state.hasPositionsFilter} onChange={(val, option) => this.handleHasPositionsChanged(option)}>
                {this.state.hasPositionsOptions.map(element => (
                  <Option value={element.value} key={element.key}>
                    {element.text}
                  </Option>
                ))}
              </Select>
            </Space>
            <Space style={{ margin: '0 15px 0 0' }}>
              <Title style={{ margin: '0px 0px 0px 0px' }} level={5}>
                Last Logged In:
              </Title>
              <Select
                value={this.state.lastLoggedInAgoMonthsFilter}
                onChange={(val, option) => this.handleLastLoggedInChanged(option)}
                style={{ width: 200 }}>
                {this.state.lastLoggedInOptions.map(element => (
                  <Option value={element.value} key={element.key}>
                    {element.text}
                  </Option>
                ))}
              </Select>
            </Space>
            {
              <Space style={{ margin: '0 15px 0 0' }}>
                <Title style={{ margin: '0px 0px 0px 0px' }} level={5}>
                  Alert Levels:
                </Title>
                {alertLevelsMenu}
              </Space>
            }
            <Space style={{ margin: '0 15px 0 0' }}>
              <Title style={{ margin: '0px 0px 0px 0px' }} level={5}>
                White Labels:
              </Title>
              {whiteLabelsMenu}
            </Space>
            <Space style={{ margin: '0 15px 0 0' }}>
              <Title style={{ margin: '0px 0px 0px 0px' }} level={5}>
                Client Types:
              </Title>
              {clientTypesMenu}
            </Space>
          </Space>
          <Row justify='space-between'>
            <Col span={16}>
              <Space style={{ padding: '0px 0px 35px 0px' }} wrap>
                <Space style={{ margin: '0 15px 0 0' }}>
                  Auto refresh page:
                  <Switch checked={this.state.autoRefreshPage} onClick={this.refreshPageCheckedChanged} />
                </Space>
                <Space style={{ margin: '0 15px 0 0' }}>
                  Interval secs:
                  <InputNumber
                    disabled={!this.state.autoRefreshPage}
                    min={30}
                    max={240}
                    value={this.state.autoRefreshPageIntervalSecs}
                    onChange={this.autoRefreshPageIntervalSecsChanged}
                  />
                </Space>
              </Space>
            </Col>
            <Col span={1}>
              <Badge overflowCount={999} count={this.state.errorCount} showZero>
                <Switch
                  checked={this.state.hasErrorsFilter}
                  checkedChildren='Errors'
                  unCheckedChildren='Errors'
                  onChange={this.onShowErrorsChange}
                />
              </Badge>
            </Col>
          </Row>
          <div className='accountSummariesTables'>
            <ClientSummaries
              loadAllWhenNoSearchString={true}
              searchString={this.state.search}
              hasPositionsFilter={this.state.hasPositionsFilter}
              hasErrorsFilter={this.state.hasErrorsFilter}
              lastLoggedInAgoMonthsFilter={this.state.lastLoggedInAgoMonthsFilter}
              errorLevelFilter={this.state.errorLevelsFilter}
              errorTypeFilter={this.state.errorTypesFilter}
              legacyAccountSummaries={this.state.legacyEnabled}
              whiteLabelsFilter={this.state.whiteLabelsFilter}
              clientTypesFilter={this.state.clientTypesFilter}
              accountTypeFilter={this.state.accountTypeFilter}
              nameFilter={this.state.nameFilter}
              userNameFilter={this.state.userNameFilter}
              clientIdFilter={this.state.clientIdFilter}
              alertLevelsFilter={this.state.alertLevelsFilter}
              refreshInterval={this.state.autoRefreshPage ? this.state.autoRefreshPageIntervalSecs : 0}
              onFilterApplied={data => this.onFilterApplied(data)}
              onDataRetrieved={data => this.onDataRetrieved(data)}
            /></div>
          {this.state.allowLegacy ? (
            <div>
              Enable Legacy Account Summaries:
              <Switch checked={this.state.legacyEnabled} onClick={this.legacyCheckedChanged} />
            </div>
          ) : (
            ''
          )}
        </Content>
      </Layout>
    );
  }
}

export default Helpers.withParams(ClientAccountSummaries);
