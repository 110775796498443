import React from "react";
import { Table } from "antd";
import { RenderHelpers } from "../helpers/renderHelpers";

class MarketDetails extends React.Component {
  constructor(props) {
    super();
    this.props = props;
    this.state = { record: props.record };
  }

  async componentDidMount() {
    const columns = [
      {
        title: "Quote Id",
        dataIndex: "id",
        key: "id",
        width: 50,
        sorter: {
          compare: (a, b) => (a.id < b.id ? -1 : 1),
        },
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "White Label",
        dataIndex: "whiteLabelName",
        sorter: {
          compare: (a, b) => (a.name < b.name ? -1 : 1),
        },
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Bid",
        dataIndex: "bid",
        width: 100,
        render: RenderHelpers.renderBidAsk,
      },
      {
        title: "Ask",
        dataIndex: "ask",
        width: 100,
        render: RenderHelpers.renderBidAsk,
      },
      {
        title: "Spread",
        dataIndex: "spread",
        width: 50,
      },
      {
        title: "Low",
        dataIndex: "low",
      },
      {
        title: "High",
        dataIndex: "high",
      },
      {
        title: "Open",
        dataIndex: "open",
      },
      {
        title: "Close",
        dataIndex: "close",
      },
    ];

    const marketDetailsColumns = [
      {
        title: "Id",
        dataIndex: "id",
        key: "id",
      },
      {
        title: "Decimal places",
        dataIndex: "dp",
      },
      {
        title: "BetPer",
        dataIndex: "prcGenBetPer",
      },
      {
        title: "FairValue",
        dataIndex: "prcGenFairValue",
      },
    ];

    this.setState({ columns: columns });
    this.setState({ marketDetailsColumns: marketDetailsColumns });
    this.setLocalState();
  }

  setLocalState = () => {
    this.setState({ record: this.props.record });
  };

  async componentDidUpdate(prevProps) {
    if (prevProps.record !== this.props.record)
      this.setLocalState();
  }

  render() {
    return (
      <div>
        <Table
          size="small"
          rowKey="id"
          columns={this.state.marketDetailsColumns}
          dataSource={[this.state.record]}
          pagination={false}
        />
        <Table
          size="small"
          rowKey="id"
          columns={this.state.columns}
          dataSource={this.state.record.marketQuotes}
          pagination={false}
        />
      </div>
    );
  }
}

export default MarketDetails;
