import axios from "axios";
import axiosRetry from "axios-retry";
import { Helpers } from "../../helpers/helpers";

class OrderQueuesDataAccess {
  constructor(props) {
    this.ORDER_QUEUES_REPOSITORY_URL = Helpers.currentEnv().DYNAMIC_DATA_REPOSITORY_API_URL+ "proxy/orderDataAccess/";
    console.debug(
      "Order queues repository: ",
      Helpers.currentEnvName(),
      this.ORDER_QUEUES_REPOSITORY_URL
    );

    axiosRetry(axios, {
      retries: 3,
      shouldResetTimeout: true,
      retryCondition: (error) => true,
      retryDelay: (retryCount) => retryCount * 1000,
    });
  }

  async orders(
    marketQuoteId,
    marketId,
    tradeMode,
    triggerType,
    search,
    page,
    pageLength
  ) {
    var response;
    try {
      response = await axios.get(
        this.ORDER_QUEUES_REPOSITORY_URL +
          "orders/?marketQuoteId=" +
          marketQuoteId +
          "&marketId=" +
          marketId +
          "&tradeMode=" +
          tradeMode +
          "&triggerType=" +
          triggerType +
          "&search=" +
          search +
          "&page=" +
          page +
          "&pageLength=" +
          pageLength
      );
    } catch (error) {
      console.error(error);
    }
    var results = response && response.status === 200 ? response?.data : [];
    console.debug("Orders:", results.details, " total: ", results.totalOrders);
    return results;
  }

  async getOptions() {
    var response;
    try {
      response = await axios.get(
        this.ORDER_QUEUES_REPOSITORY_URL + "orders/options"
      );
    } catch (error) {
      console.error(error);
    }
    var results = response && response.status === 200 ? response?.data : [];
    console.debug("Orders Options:", results);
    return results;
  }
}

export default OrderQueuesDataAccess;
