import axios from "axios";
import axiosRetry from "axios-retry";
import { Helpers } from "../helpers/helpers";
import { DataHelpers } from "../helpers/dataHelpers";
import * as _ from "underscore";

class DashboardDataAccessProxy {
  constructor() {
    this.DATA_ACCESS_API_URL = //"http://localhost:5030/api/"
       Helpers.currentEnv().DYNAMIC_DATA_REPOSITORY_API_URL + "dataAccess/";
    this.APPLICATION = "DASHBOARD";
    console.debug(
      "Dashboard DataAccess API: ",
      Helpers.currentEnvName(),
      this.DATA_ACCESS_API_URL
    );

    axiosRetry(axios, {
      retries: 3,
      shouldResetTimeout: true,
      retryCondition: (error) => {
        console.debug(error);
        return true;
      },
      retryDelay: (retryCount) => retryCount * 1000,
    });
  }

  async whiteLabels() {
    var response;
    try {
      response = await axios.get(this.DATA_ACCESS_API_URL + "whiteLabels", {
        headers: { application: this.APPLICATION },
      });
    } catch (error) {
      console.error(error);
    }
    var results =
      response && response.status === 200 ? response?.data.results : [];
    console.debug("Data repository WhiteLabels:", results);
    return results;
  }


}

export default DashboardDataAccessProxy;
