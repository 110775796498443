export class MarketHelpers {

  static doesInstrumentNameEqual(prcGenRawSourceSymbol, instrumentName) {
    var segements = prcGenRawSourceSymbol?.split(".");
    segements?.shift();
    return segements?.join(".").toLowerCase() === instrumentName.toLowerCase();
  }

  static extractInstrumentNameFromPrcGenRawSourceSymbol(prcGenRawSourceSymbol) {
    var segements = prcGenRawSourceSymbol?.split(".");
    segements?.shift();
    return segements?.join(".");
  }

  static extractInstrumentNameFromMarket(market) {
    var segements = market.prcGenRawSourceSymbol.split(".");
    segements.shift();
    return segements.join(".");
  }

  static associatedConstituentParts(market) {
    var parts = [];
    var segements = market.prcGenRawSourceSymbol.split(".");
    var isMarket = segements[0] === "MARKET";
    segements.shift();
    parts.push({ isMarket: isMarket, name: segements.join(".") });
    segements = market.prcGenRawSourceSymbol2.split(".");
    isMarket = segements[0] === "MARKET";
    segements.shift();
    parts.push({ isMarket: isMarket, name: segements.join(".") });
    return parts;
  }

  static associatedInstruments(
    symbol,
    connector,
    prefix,
    connectorAndInstrument = true
  ) {
    var instruments = [];
    var segements = symbol.split(".");
    segements.shift();
    if (connectorAndInstrument)
      instruments.push(
        prefix + connector + "." + encodeURIComponent(segements.join("."))
      );
    else instruments.push(prefix + segements.join("."));

    return instruments;
  }

  static associatedMarkets(market) {
    var markets = [];
    var segements = market.prcGenRawSourceSymbol.split(".");
    segements.shift();
    markets.push(segements.join("."));
    segements = market.prcGenRawSourceSymbol2.split(".");
    segements.shift();
    markets.push(segements.join("."));
    return markets;
  }

  static extractInstrumentNamesFromMarkets(markets) {
    return markets.map((market) => {
      var segements = market.prcGenRawSourceSymbol.split(".");
      segements.shift();
      return segements.join(".");
    });
  }
}
