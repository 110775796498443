import React from "react";
import { Space, Tooltip, Button } from "antd";
import {
  CopyOutlined,
  EditOutlined,
  AreaChartOutlined,
} from "@ant-design/icons";
import { AppContext } from "../../dataContext";

class InstrumentTableButtons extends React.Component {
  static contextType = AppContext;
  constructor(props) {
    super();
    this.props = props;
    this._isMounted = false;
  }

  render() {
    return this.props.record.marketIsPresent ? (
      <Space>
        <Tooltip title="Edit">
          <Button
            type="primary"
            shape="circle"
            disabled={
              this.props.record.editingDisabled || !this.context.hasWriteRole
            }
            onClick={() => this.context.showInstrumentEdit(this.props.record)}
            icon={<EditOutlined />}
          />
        </Tooltip>
        <Tooltip title="Clone">
          <Button
            type="primary"
            shape="circle"
            disabled={
              this.props.record.editingDisabled || !this.context.hasWriteRole
            }
            onClick={() =>
              this.context.showInstrumentAdd(null, this.props.record)
            }
            icon={<CopyOutlined />}
          />
        </Tooltip>
        <Tooltip title="Market Monitor">
          <a
            href={
              "#/marketMonitor/" +
              encodeURIComponent(this.props.record.publishedName)
            }
          >
            <Button
              type="primary"
              shape="circle"
              icon={<AreaChartOutlined />}
            />
          </a>
        </Tooltip>
      </Space>
    ) : (
      <Space>
        <Tooltip title="Edit">
          <Button
            type="primary"
            shape="circle"
            disabled={
              this.props.record.editingDisabled || !this.context.hasWriteRole
            }
            onClick={() => this.context.showInstrumentEdit(this.props.record)}
            icon={<EditOutlined />}
          />
        </Tooltip>
        <Tooltip title="Clone">
          <Button
            type="primary"
            shape="circle"
            disabled={
              this.props.record.editingDisabled || !this.context.hasWriteRole
            }
            onClick={() =>
              this.context.showInstrumentAdd(null, this.props.record)
            }
            icon={<CopyOutlined />}
          />
        </Tooltip>
        <a
          href={
            "#/marketMonitor/" +
            encodeURIComponent(this.props.record.publishedName)
          }
        >
          <Button
            disabled
            type="primary"
            shape="circle"
            icon={<AreaChartOutlined />}
          />
        </a>
      </Space>
    );
  }
}

InstrumentTableButtons.contextType = AppContext;
export default InstrumentTableButtons;
