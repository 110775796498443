import React from "react";
import * as lodash from "lodash";
import { Layout, Row } from "antd";
import * as _ from "underscore";

import "antd/dist/antd.dark.css";
import "../main.css";
import DynamicDataRepositoryRedisDbProxy from "../proxies/redisDbProxy";
import DynamicDataRepositoryRedisProxy from "../proxies/redisProxy";
import DynamicDataRepositoryKafkaProxy from "../proxies/kafkaProxy";
import DataRepositoryProxy from "../proxies/dataRepositoryProxy";
import DynamicDataRepositoryDashProxy from "../proxies/dashProxy";
import { Helpers } from "../helpers/helpers";
import { AppContext } from "../dataContext";
import { KafkaComponent } from "../components/kafkaComponent";
import InstrumentTable from "./instrument-table/instrumentTable";
import InstrumentSearchAndFilter from "./instrumentSearchAndFilter";
import InstrumentPageButtons from "./instrumentPageButtons";
import { InstrumentPageUrlHelper } from "./helpers/instrumentPageUrlHelper";
import { InstrumentPageDataHelper } from "./helpers/instrumentPageDataHelper";
import PricingHubProxy from "../proxies/pricinghubProxy";

const { Content } = Layout;

class InstrumentPage extends KafkaComponent {
  static contextType = AppContext;

  constructor(props) {
    super();
    this.urlSearch = new URLSearchParams(props.location.search);

    this.throttledSearch = _.throttle(this.search, 500, {
      leading: false,
    });

    this.pricingHubProxy = new PricingHubProxy();
    this.dataRepositoryProxy = new DataRepositoryProxy();
    this.dynamicDataRepositoryDashProxy = new DynamicDataRepositoryDashProxy(
      {}
    );
    this.urlHelper = new InstrumentPageUrlHelper();
    this.redisProxy = new DynamicDataRepositoryRedisProxy();
    this.kafkaProxy = new DynamicDataRepositoryKafkaProxy(
      this.onKafkaPrice,
      this.onKafkaInactivePrice,
      this.onKafkaPriceStatus,
      this.onKafkaFeedStatus,
      this.onKafkaReconnect
    );
    this.redisDbProxy = new DynamicDataRepositoryRedisDbProxy({
      onInstrumentWithFeeds: this.onInstrumentWithFeeds
    });
    this.props = props;

    this.state = {
      loading: true,
      id: this.props.params.id,
      instruments: [],
      csvDisabled: true,
      providers: [],
      currentSearchAndFilter: {
        search: undefined,
        operationValue: "contains",
        currentPage: 1,
        providerValue: undefined,
        priceStatusValues: [],
        tagValue: [],
        namesFromSearch: [],
        dateFilter: { date: "", isBefore: true },
      },
    };
  }

  onInstrumentWithFeeds = (payload) => {
    let instrumentWithFeeds = payload.data;
    if (payload.action === 3) this._onDelete(instrumentWithFeeds);
    else {
      let foundInstruments = this.instruments?.filter(
        (y) => y.id === instrumentWithFeeds.id
      );
      if (foundInstruments?.length > 0)
        foundInstruments.forEach((foundInstrument) => {
          foundInstrument.editingDisabled = false;
          var converted = InstrumentPageDataHelper.convertResponseToInstrumentPageObject(instrumentWithFeeds);
          foundInstrument.constituentPrices?.sort((a, b) => { // so that primary constituent price shows at top
            if (a.name === converted.primaryName) return -1; // `a` comes before `b`
            if (b.name === converted.primaryName) return 1;  // `b` comes before `a`
            return 0; // If neither is the primary feed, keep the original order
        });
          Object.assign(foundInstrument, converted);
        });
    }
    this.instrumentsSetState();
  };

  _onDelete = (instrumentWithFeeds) => {
    let foundInstruments = this.instruments.filter((y) => y.Id === instrumentWithFeeds.id);
    foundInstruments.forEach((x) => {
      if (this.state.onDelete) this.state.onDelete(x);
      if (this.instruments.indexOf(x) > -1) {
        // trying to get rid of instrument
          let temp = this.instruments;
          temp.splice(temp.indexOf(x), 1);
          this.instruments = [...temp];
          this.setState({ instruments: this.instruments });
        }
    });
  };

  instrumentsSetState = () => {
    if (this._isMounted)
      this.setState(() => ({
        instruments: lodash.cloneDeep(this.instruments),
      }));
  };

  onDelete = (x, deletedConnectorMapId) => {
    if (this.instruments.indexOf(x) > -1) {
      if (x.primaryConnectorMapId && x.secondaryConnectorMapId) {
        InstrumentPageDataHelper.removePrimaryOrSecondary(x, deletedConnectorMapId);
      } else {
        let temp = this.instruments;
        temp.splice(temp.indexOf(x), 1);
        this.instruments = [...temp];
        this.setState({ instruments: this.instruments });
      }
    }
  };

  onAdded = (connectorMap) => {
    let found = this.instruments.filter((x) => x.id === connectorMap.id)[0];
    if (found) {
      let temp = this.instruments;
      temp.splice(temp.indexOf(found), 1, connectorMap);
      this.instruments = [...temp];
      this.setState({ instruments: this.instruments });
    }
  };

  onKafkaPrice = (price) => {
    this._onPrice(price, this.instrumentsSetState);
  };

  onKafkaInactivePrice = (price) => {
    this._onInactivePrice(price, this.instrumentsSetState);
  };

  onKafkaPriceStatus = (price) => {
    this._onPriceStatus(price, this.instrumentsSetState);
  };

  onKafkaFeedStatus = () => {};

  onKafkaReconnect = () => {
    if (this._isMounted) return this._onReconnect();
    return [];
  };

  componentDidMount = async () => {
    this._isMounted = true;
    await this.redisDbProxy.connect();
    await this.redisDbProxy.subscribeAndOverride();

    this.context.updateMenuSelection("allInstruments");
    document.title = "Instruments";
  };

  componentWillUnmount = async () => {
    this._isMounted = false;
    await this.kafkaProxy.stop();
    await this.redisProxy.stop();
    await this.redisDbProxy.stop();
  };

  addNew = () => {
    this.context.showInstrumentAdd();
  };

  instrumentExport = async () => {
    this.context.showInstrumentExport();
  };

  search = async () => {
    console.debug("Searching:", this.state.currentSearchAndFilter);

    if (!this._isMounted) {
      return;
    }

    const {
      priceStatusValues,
      namesFromSearch: names,
      providerValue,
      tagValue: tags,
      operationValue: operation,
      dateFilter,
    } = this.state.currentSearchAndFilter;

    this.updateContext(
      names,
      providerValue,
      tags,
      priceStatusValues,
      operation
    );

    const convertedNames = names?.map((x) =>
      x.provider ? `${x.provider}.${x.name}` : x.name
    );
    const tagsCondition =
      Array.isArray(tags) && tags.length > 0 ? `#${tags[0]}` : undefined;
    const newPath = this.urlHelper.updateUrl(
      convertedNames,
      operation,
      tagsCondition,
      providerValue,
      this.props.location
    );
    if (newPath) {
      this.props.navigate(newPath);
    }

    let payload = await this.pricingHubProxy.instrumentsSearch(
      {
        providerName: providerValue,
        names: names,
        tags: tags && tags.length > 0 ? tags : [],
        priceStatus: priceStatusValues,
        strict: operation === "equals",
        dateFilter : dateFilter.date? dateFilter : null
      },
      this.state.currentSearchAndFilter.currentPage
    );
    console.log("Connector maps are", payload);
    await this.dataRepositoryProxy.instrumentRelatedMarketsWithConnectorMaps(
      payload.names,
      payload.connectorMaps
    );
    if (payload.connectorMaps.length > 0) {
      this.handleSearchSuccess(payload);
    } else {
      this.handleSearchFailure();
    }
  };

  handleSearchSuccess = async (payload) => {
    await this.kafkaProxy.connect();
    this.instruments = payload.connectorMaps;
    Helpers.sort(this.instruments, (x) => x.publishedName);
    const priceStatuses = await this.pricingHubProxy.getPriceStatusesForInstruments(this.instruments.map(x => x.id));
    InstrumentPageDataHelper.applyLatestPriceStatusToInstruments(
      priceStatuses,
      this.instruments
    );
    const redisPrices = await this.redisProxy.connectorPrices(payload.names);
    InstrumentPageDataHelper.applyLatestPriceToInstruments(
      redisPrices,
      this.instruments
    );
    const newState = {
      instruments: [...this.instruments],
      csvDisabled: false,
      loading: false,
      total: payload.counter,
    };
    await this.kafkaProxy.subscribeAndOverride(payload.names);
    this.setState(newState);
  };

  handleSearchFailure = async () => {
    this.instruments = [];
    const newState = {
      total: 0,
      instruments: this.instruments,
      csvDisabled: true,
      loading: false,
    };

    await this.kafkaProxy.unsubscribe();
    this.setState(newState);
  };

  updateContext = (
    names,
    providerValue,
    tags,
    priceStatusValues,
    operation
  ) => {
    const contextUpdates = {
      instrumentSearchName: names,
      instrumentSearchProviderName: providerValue,
      instrumentSearchTags: tags,
      instrumentSearchPriceStatusValues: priceStatusValues,
      instrumentSearchOperation: operation,
    };

    const contextKeys = Object.keys(contextUpdates);
    contextKeys.forEach((key) =>
      this.context.updateContext(key, contextUpdates[key])
    );
  };

  getAllInstrumentNames = async () => {
    const {
      priceStatusValues,
      operationValue,
      namesFromSearch,
      providerValue,
      tagValue,
      dateFilter
    } = this.state.currentSearchAndFilter;

    let payload = await this.pricingHubProxy.instrumentSearch({
      providerName: providerValue,
      names: namesFromSearch,
      tags: tagValue && tagValue.length > 0 ? tagValue : [],
      priceStatusValues: priceStatusValues,
      strict: operationValue === "equals",
      dateFilter : dateFilter.date? dateFilter : null
    });

    return payload.counter > 0 ? payload.instrumentNames : [];
  };

  onNewChange = async (currentSearchAndFilter) => {
    this.setState({ loading: true });
    this.setState(
      { currentSearchAndFilter: currentSearchAndFilter },
      this.throttledSearch
    );
  };

  pageChanged = async (pageData) => {
    let page = 1;
    if (pageData.current) {
      page = pageData.current;
    }
    let currentSearchAndFilter = {
      ...this.state.currentSearchAndFilter,
      currentPage: page,
    };
    this.onNewChange(currentSearchAndFilter);
  };

  render() {
    return (
      <Layout className="layout">
        <Content style={{ padding: "0 5px" }}>
          <Row gutter={[16, 16]}>
            <InstrumentSearchAndFilter onNewChange={this.onNewChange} />
            <InstrumentPageButtons
              csvDisabled={this.state.csvDisabled}
              getAllInstrumentNames={this.getAllInstrumentNames}
              currentSearchAndFilter={this.state.currentSearchAndFilter}
            ></InstrumentPageButtons>
          </Row>
          <InstrumentTable
            ref={this.table}
            onChange={this.pageChanged}
            loading={this.state.loading}
            currentPage={this.state.currentSearchAndFilter.currentPage}
            total={this.state.total}
            instruments={this.state.instruments}
          />
        </Content>
      </Layout>
    );
  }
}

InstrumentPage.contextType = AppContext;
export default Helpers.withParams(InstrumentPage);
