import {
  HubConnectionBuilder,
  LogLevel,
  HubConnectionState,
  HttpTransportType,
} from "@microsoft/signalr";
import { Helpers } from "../helpers/helpers";
import * as _ from "underscore";
import axios from "axios";
import axiosRetry from "axios-retry";

class DynamicDataRepositoryDashProxy {
  constructor({
    dashSummary = null,
    dashUserWebsocketLatencies = null,
    onReconnect = null,
  }) {
    this.dashSummary = dashSummary;
    this.dashUserWebsocketLatencies = dashUserWebsocketLatencies;
    this.onReconnect = onReconnect;
    this.DYNAMIC_DATA_REPOSITORY_API_URL = //"http://localhost:7022/api/"
      Helpers.currentEnv().DYNAMIC_DATA_REPOSITORY_API_URL;
    console.debug(
      "Dynamic data repository: ",
      Helpers.currentEnvName(),
      this.DYNAMIC_DATA_REPOSITORY_API_URL
    );

    this.hubConnection = new HubConnectionBuilder()
      .withUrl(this.DYNAMIC_DATA_REPOSITORY_API_URL + "wsdash", {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
      })
      .configureLogging(LogLevel.Debug)
      .withAutomaticReconnect()
      .build();

    this.hubConnection.keepAliveIntervalInMilliseconds = 60000;
    this.hubConnection.serverTimeoutInMilliseconds = 90000;

    this.hubConnection.onreconnected(() => {
      var payload = { clients: [], whiteLabel: undefined };
      if (this.onReconnect) payload = this.onReconnect();
      console.debug("Dash Reconnected...", payload);
      this.hubConnection
        .invoke("refresh", payload.clients, payload.whiteLabel)
        .catch((err) => console.error(err));
    });

    this.hubConnection.onclose((connectionId) => {
      console.debug("Closed connectionId: ", connectionId);
    });

    this.hubConnection.on("summary", (message) => {
      if (this.dashSummary) this.dashSummary(message);
    });

    this.hubConnection.on("userWebsocketLatencies", (message) => {
      if (this.dashUserWebsocketLatencies)
        this.dashUserWebsocketLatencies(message);
    });

    axiosRetry(axios, {
      retries: 3,
      shouldResetTimeout: true,
      retryCondition: (error) => {
        console.debug(error);
        return true;
      },
      retryDelay: (retryCount) => retryCount * 1000,
    });
  }

  async connect() {
    if (this.hubConnection.state === HubConnectionState.Disconnected) {
      await this.hubConnection
        .start()
        .then(() => {
        })
        .catch((error) => {
          console.assert(
            this.hubConnection.state === HubConnectionState.Disconnected
          );
          console.debug(error);
          setTimeout(() => this.connect(), 2000);
        });
    }
  }

  async subscribeAndOverride({ clients = null, whiteLabel = null }) {
    if (this.hubConnection.state === HubConnectionState.Disconnected)
      await this.hubConnection.start().then(async () => {
        await this.hubConnection
          .invoke("subscribeAndOverride", clients, whiteLabel)
          .catch((err) => console.error(err));
        console.debug("Dash SubscribeAndOverride:");
      });
    else {
      await this.hubConnection
        .invoke("subscribeAndOverride", clients, whiteLabel)
        .catch((err) => console.error(err));
      console.debug("Dash SubscribeAndOverride:");
    }
  }

  async unsubscribe() {
    await this.hubConnection
      .invoke("unsubscribe")
      .catch((err) => console.error(err));
    console.debug("Dash SubscribeAndOverride:", []);
    console.debug("Dash Unsubscribe");
  }

  async stop() {
    await this.hubConnection.stop().catch((err) => console.error(err));
  }

  

  async whiteLabels(activeOnly = true) {
    var response;
    try {
      response = await axios.get(
        this.DYNAMIC_DATA_REPOSITORY_API_URL + `pricinghubproxy/whiteLabels`,
        { headers: { application: this.APPLICATION } }
      );
    } catch (error) {
      console.debug(error);
    }
    var results =
      response && response.status === 200 ? response?.data.results : [];
    console.debug("Dynamic data repository WhiteLabels:", results);
    return results.filter((x) => (activeOnly ? !x.isPassive : true));
  }
}

export default DynamicDataRepositoryDashProxy;
