import { Component } from "react";
import { notification } from "antd";
import { useParams, useLocation, useNavigate } from "react-router-dom";

export class Helpers extends Component {
  static showError = (message) => {
    notification.error({
      message: `Error`,
      description: `${message}`,
      placement: "bottomRight",
    });
  };

  static showInfo = (message) => {
    notification.info({
      message: `Information`,
      description: `${message}`,
      placement: "bottomRight",
    });
  };

  static generateGuid = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  };

  static setCookie = (cname, cvalue, exdays) => {
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var expires = "expires=" + d.toGMTString();
    document.cookie =
      cname + "=" + cvalue + ";" + expires + ";path=/;SameSite=Lax";
  };

  static getCookie = (cname) => {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  };

  static currentEnvName = () => window._env_.ENV.toUpperCase();

  static currentVer = () => window._env_.VER;

  static currentEnv = () => window._env_[window._env_.ENV.toUpperCase()];

  static isUnderConstruction = () =>
    window._env_.ENV.toUpperCase() === "PRODUCTION" ||
    window._env_.ENV.toUpperCase() === "DEMO";

  static sort = (list, property, asc = true) => {
    list.sort(function (a, b) {
      if (property(a) < property(b)) {
        return asc ? -1 : 1;
      }
      if (property(a) > property(b)) {
        return asc ? 1 : -1;
      }
      return 0;
    });
  };

  static sorter = (a, b, property) => {
    if (!property(a) || !property(b) || property(a) < property(b)) {
      return -1;
    }
    if (property(a) > property(b)) {
      return 1;
    }
    return 0;
  };

  static withParams = (Component) => {
    return props => <Component {...props} params={useParams()} location={useLocation()} navigate={useNavigate()}/>; 
  }
}
