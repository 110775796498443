import React from "react";
import { Table, Empty } from "antd";
import { RenderHelpers } from "./renderHelpers";
import Errors from "./errors.js";

class OrderSummary extends React.Component {
  constructor(props) {
    super();
    this.props = props;
    this.state = { record: props.record };
  }
  async componentDidMount() {
    var columns = [
      {
        title: "Closing Order ID",
        dataIndex: "id",
        width: "30%",
      },
      {
        title: "Is Triggered",
        width: "10%",
        dataIndex: "isTriggered",
        render: (flag) => RenderHelpers.renderDatatableCheckbox(flag),
      },
      {
        title: "Is Guaranteed",
        width: "10%",
        dataIndex: "isGuaranteed",
        render: (flag) => RenderHelpers.renderDatatableCheckbox(flag),
      },
      {
        title: "Limit Price",
        width: "10%",
        dataIndex: "limitOrderPrice",
      },
      {
        title: "Stop Price",
        width: "10%",
        dataIndex: "stopOrderPrice",
      },
      {
        title: "Is Trailing Stop",
        width: "10%",
        dataIndex: "trailingPoint",
        render: (flag) => RenderHelpers.renderDatatableCheckbox(flag),
      },
      {
        title: "Order Type",
        width: "10%",
        dataIndex: "orderTypeIdString",
      },
    ];
    this.setState({ columns: columns });
  }
  render() {
    return (
      <div>
        <Errors record={this.props.record} />
        <div className="orderSummaryTable">
          <Table
            dataSource={this.state.record.linkedOrderDtos}
            indentSize={1}
            pagination={false}
            columns={this.state.columns}
            rowClassName={(record) => "orderBlue"}
            locale={{
              emptyText: <Empty image={null} description="No Closing Orders" />,
            }}
          />
        </div>
      </div>
    );
  }
}

export default OrderSummary;
