import React from "react";
import { Button, Tooltip } from "antd";
import { AppContext } from "../dataContext";

class Paging extends React.Component {
  static contextType = AppContext;

  constructor(props) {
    super();
    this.defaultMaxPagesEitherSize = 4;

    this.props = props;

    this.state = {
      currentPage: this.props.currentPage
    };
  }

  async componentDidUpdate(prevProps) {
    if(this.props.currentPage !== this.state.currentPage){
      this.state.currentPage = this.props.currentPage;
    }
  }

  setPage(page) {
    this.setState({currentPage: page});
    if (this.props.onPageSelected){
      this.props.onPageSelected(page);
    }
  }


  render() {
    var _this = this;

    var maxPagesEitherSize = _this.props.maxPagesEitherSize;
    if (maxPagesEitherSize === undefined || maxPagesEitherSize === null){
      maxPagesEitherSize = _this.defaultMaxPagesEitherSize;
    }

    var minimumPage = _this.props.minimumPage;
    var maximumPage = _this.props.maximumPage;
    var currentPage = _this.state.currentPage;

    var pagesToLeft = maxPagesEitherSize;
    var pagesToRight = maxPagesEitherSize;

    if (currentPage - pagesToLeft < minimumPage){
      pagesToLeft = currentPage - minimumPage;
      pagesToRight += maxPagesEitherSize - pagesToLeft;
    }

    if (currentPage + pagesToRight > maximumPage){
      pagesToRight = maximumPage - currentPage;
      pagesToLeft += maxPagesEitherSize - pagesToRight;
    }

    var minPage = currentPage - pagesToLeft;
    var maxPage = currentPage + pagesToRight;
    
    if (minPage < minimumPage){
      minPage = minimumPage;
    }

    if (maxPage > maximumPage){
      maxPage = maximumPage;
    }

    var charWidth = (Math.max(maximumPage, -minimumPage)).toString().length;
    var buttonWidth = ''+(20+(charWidth*10))+'px';

    var pageSelector = [];
    pageSelector.push(
      <Button
        key={"pagebutton-mid"}
        onClick={() => _this.setPage(0)}
      >
        {"Center"}
      </Button>
    );

    pageSelector.push(
      <Button
        key={"pagebutton-endleft"}
        onClick={() => _this.setPage(minimumPage)}
        disabled={currentPage <= minimumPage}
      >
        <Tooltip title={minimumPage}>{"<<"}</Tooltip>
        
      </Button>
    );

    pageSelector.push(
      <Button
        key={"pagebutton-left"}
        onClick={() => _this.setPage(currentPage-1)}
        disabled={currentPage <= minimumPage}
      >
        <Tooltip title={currentPage-1}>{"<"}</Tooltip>
      </Button>
    );


    for (
      let index = minPage;
      index <= maxPage;
      index++
    ) {
      var className = "page";
      if (index === currentPage) {
        className += " active";
      }
      pageSelector.push(
        <Button
          className={className}
          style={{width:buttonWidth, textAlignLast: 'center'}}
          key={"pagebutton" + index}
          value={index}
          onClick={() => _this.setPage(index)}
        >
          {index}
        </Button>
      );
    }
    pageSelector.push(
      <Button
        key={"pagebutton-right"}
        onClick={() => _this.setPage(currentPage+1)}
        disabled={currentPage >= maximumPage}
      >
        <Tooltip title={currentPage+1}>{">"}</Tooltip>
      </Button>
    );

    pageSelector.push(
      <Button
        key={"pagebutton-endright"}
        onClick={() => _this.setPage(maximumPage)}
        disabled={currentPage >= maximumPage}
      >
        <Tooltip title={maximumPage}>{">>"}</Tooltip>
      </Button>
    );

    return (
      <div className="pageSelector">
          {_this.props.totalOrders} Active orders
          {pageSelector.map((item) => item)}
      </div> 
    );
  }
}

export default Paging;
