export class InstrumentPageUrlHelper {
  updateUrl(names, operation, tag, provider, location){
    const query = new URLSearchParams();
    var originalString = location.search;
    InstrumentPageUrlHelper.updateUrlFromFilterList(query, names, "name");
    InstrumentPageUrlHelper.updateUrlFromFilter(query, operation, "operation");
    InstrumentPageUrlHelper.updateUrlFromFilter(query, provider, "provider");
    InstrumentPageUrlHelper.updateUrlFromFilter(query, tag, "tag");

    const newQueryString = query.toString();
    if (newQueryString.toLowerCase() !== originalString.toLowerCase()) {
      return location.pathname + "?" + newQueryString;
    }
  };

  static updateUrlFromFilterList = (query, filter, queryProperty) => {
    if (filter && filter.length > 0) {
        filter.map((x) => query.append(queryProperty, x));
    } else {
        query.delete(queryProperty);
    }
  };

  static updateUrlFromFilter = (query, filter, queryProperty) => {
    if (filter) {
        query.set(queryProperty, filter);
    } else {
        query.delete(queryProperty);
    }
  };
}
