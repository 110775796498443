import React from "react";
import * as _ from "underscore";
import { Table } from "antd";
import OrderDataAccessHub from "./proxies/orderDataAccessHub.js";
import { Tooltip } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { AppContext } from "../dataContext";
import Price from "../components/priceBackground";
import Paging from "./paging.js";

class OrderQueue extends React.Component {
  static contextType = AppContext;

  constructor(props) {
    super();
    this.refreshTimer = null;

    this.maxPagesEitherSize = 4;

    this.props = props;

    this.throttledUpdate = _.throttle(this.update, 500, {
      leading: false,
    });

    this.dataRepositoryProxyHub = new OrderDataAccessHub(
      (levels) => this.onOrdersChanged(levels),
      null,
      null,
      (message) => this.onError(message),
      () => this.onReconnect()
    );
    this._isMounted = false;
    var columns = [
      {
        title: "Order Id",
        dataIndex: "id",
        columnKey: "id",
        width: "8%",
        render: (text, record) => (
          <span>
            {record.id}{" "}
            {record.shouldHaveFired ? (
              <Tooltip title="Order not fired">
                <ExclamationCircleOutlined />
              </Tooltip>
            ) : (
              ""
            )}
          </span>
        ),
      },
      {
        title: "Client Id",
        dataIndex: "id",
        columnKey: "id",
        width: "20%",
        render: (text, record) => (
          <a
            href={
              "/#/accountSummaries?search=" +
              record.clientId +
              "&hasPositions=all"
            }
          >
            {!record.alphaCode
              ? "-"
              : record.alphaCode + " (" + record.clientId + ")"}
          </a>
        ),
      },
      {
        title: "Market",
        dataIndex: "marketId",
        columnKey: "marketId",
        width: "29%",
        render: (text, record) =>
          !record.marketId
            ? "-"
            : record.marketId + " (" + record.marketName + ")",
      },
      {
        title: "Level",
        dataIndex: "level",
        columnKey: "level",
        width: "10%",
        render: (text, record) => (
          <Price text={text} dp={record.decimalPlaces} bold={false}></Price>
        ),
      },
      {
        title: "Current",
        dataIndex: "currentPrice",
        columnKey: "currentPrice",
        width: "10%",
        render: (text, record) => (
          <Price text={text} dp={record.decimalPlaces} bold={false}></Price>
        ),
      },
      {
        title: "Distance",
        dataIndex: "distancePercentage",
        columnKey: "distancePercentage",
        width: "7%",
        render: (text) => <Price text={text} dp="5" bold={false}></Price>,
      },
      {
        title: "Quantity",
        dataIndex: "quantity",
        columnKey: "quantity",
        width: "6%",
      },
      {
        title: "B/S",
        dataIndex: "tradeModeString",
        columnKey: "tradeModeString",
        width: "3%",
      },
      {
        title: "O/C",
        dataIndex: "isClosePosition",
        columnKey: "isClosePosition",
        width: "3%",
        render: (_, record) =>
          record.isClosePosition
            ? "C"
            : "O",
      },
      {
        title: "Trigger",
        dataIndex: "triggerType",
        columnKey: "triggerType",
        width: "10%",
        render: (text) => (text === 0 ? "Above" : "Below"),
      },
    ];

    this.state = {
      orders: null,
      totalOrders: 0,
      columns: columns,
      loading: false,
      page: 0,
      pageLength: 14,
      pageMin: 0,
      pageMax: 0,
      pageSelector: [],
    };
  }

  async onReconnect() {
    if (this._isMounted) {
      this.dataRepositoryProxyHub.subscribe(
        this.props.marketQuoteId,
        this.props.marketId,
        this.props.tradeMode,
        this.props.searchString,
        this.state.page,
        this.state.pageLength
      );
    }
  }

  onOrdersChanged(data) {
    if (this._isMounted) {
      this.total = data.totalOrders;
      this.records = data.details;
      this.minPage = data.minPage;
      this.maxPage = data.maxPage;
      this.throttledUpdate();
    }
  }

  update = () => {
    if (this._isMounted) {
      var total = this.total;
      var records = this.records;
      var minPage = this.minPage;
      var maxPage = this.maxPage;
      this.setState({
        orders: records,
        totalOrders: total,
        pageMin: minPage,
        pageMax: maxPage,
      });
    }
  };

  onError(message) {
    console.error(message);
    this.setState({ orders: [] });
  }

  async componentWillUnmount() {
    this._isMounted = false;
    this.dataRepositoryProxyHub.unsubscribe(
      this.props.marketQuoteId,
      this.props.marketId,
      this.props.tradeMode,
      this.props.searchString,
      this.state.page,
      this.state.pageLength
    );
  }

  async componentDidMount() {
    this._isMounted = true;
    this.setState({ loading: false });
    await this.dataRepositoryProxyHub.connect();
    this.dataRepositoryProxyHub.subscribe(
      this.props.marketQuoteId,
      this.props.marketId,
      this.props.tradeMode,
      this.props.searchString,
      this.state.page,
      this.state.pageLength
    );
  }

  async componentDidUpdate(prevProps) {
    var updated = false;

    if (prevProps.marketQuoteId !== this.props.marketQuoteId) {
      updated = true;
    } else if (prevProps.marketId !== this.props.marketId) {
      updated = true;
    } else if (prevProps.tradeMode !== this.props.tradeMode) {
      updated = true;
    } else if (prevProps.searchString !== this.props.searchString) {
      updated = true;
    }
    if (updated) {
      this.dataRepositoryProxyHub.unsubscribe(
        prevProps.marketQuoteId,
        prevProps.marketId,
        prevProps.tradeMode,
        prevProps.searchString,
        this.state.page,
        this.state.pageLength
      );
      this.dataRepositoryProxyHub.subscribe(
        this.props.marketQuoteId,
        this.props.marketId,
        this.props.tradeMode,
        this.props.searchString,
        this.state.page,
        this.state.pageLength
      );
    }
  }

  async setPage(page) {
    this.dataRepositoryProxyHub.unsubscribe(
      this.props.marketQuoteId,
        this.props.marketId,
        this.props.tradeMode,
        this.props.searchString,
      this.state.page,
      this.state.pageLength
    );
    this.setState({ page: page });
    this.dataRepositoryProxyHub.subscribe(
      this.props.marketQuoteId,
        this.props.marketId,
        this.props.tradeMode,
        this.props.searchString,
      page,
      this.state.pageLength
    );
  }
  async handleTableChange(pagination) {
    var previousPage = this.state.page;
    var previousPageLength = this.state.pageLength;
    var newPage = pagination.current - 1;
    var newPageLength = pagination.pageSize;
    if (newPage !== previousPage || newPageLength !== previousPageLength) {
      this.setState({
        page: pagination.current - 1,
        pageSize: pagination.pageSize,
      });
      this.dataRepositoryProxyHub.unsubscribe(
        this.props.marketQuoteId,
        this.props.marketId,
        this.props.tradeMode,
        this.props.searchString,
        previousPage,
        previousPageLength
      );
      this.dataRepositoryProxyHub.subscribe(
        this.props.marketQuoteId,
        this.props.marketId,
        this.props.tradeMode,
        this.props.searchString,
        newPage,
        newPageLength
      );
    }
  }
  render() {
    var _this = this;

    return (
      <div className="orderQueuesTables">
        <Paging 
          minimumPage = {_this.state.pageMin} 
          maximumPage = {_this.state.pageMax} 
          maxPagesEitherSize = {_this.maxPagesEitherSize} 
          currentPage = {_this.state.page}
          totalOrders = {_this.state.totalOrders}
          onPageSelected = {(p)=>_this.setPage(p)}/>
        <Table
          rowClassName={(record) =>
            record.isEndSection
              ? "end-section"
              : record.isStartSection
              ? "start-section"
              : ""
          }
          loading={_this.state.loading}
          dataSource={_this.state.orders}
          columns={_this.state.columns}
          onChange={(pagination) => _this.handleTableChange(pagination)}
          pagination={false}
        />
      </div>
    );
  }
}

export default OrderQueue;
