import React from "react";
import { Table, Tooltip } from "antd";
import { AppContext } from "../../dataContext.js";
import PrimaryAndSecondaryFeed from "./primaryAndSecondaryFeed.js";
import { RenderHelpers } from "../../helpers/renderHelpers";
import moment from "moment";
import InstrumentTableButtons from "./instrumentTableButtons.js";

const fullDateFormat = "yyyy-MM-DD HH:mm:ss";
const dateFormat = "ddd HH:mm:ss";

class InstrumentTable extends React.Component {
  static contextType = AppContext;
  constructor(props) {
    super();
    this.props = props;
    this._isMounted = false;

    this.state = {
      total: 3,
      currentPage: 1,
    };
  }

  getInstrumentColumns = (hasWriteRole) => {
    return [
      {
        title: "",
        dataIndex: "isTest",
        width: 30,
        render: (text, record) =>
          RenderHelpers.remderAutoGenerateSymbol(text, "Auto-generated"),
      },
      {
        title: "Internal Name",
        dataIndex: "publishedName",
        key: "publishedName",
        width: 100,
        render: (text, record) =>
          record.enabled ? (
            <Tooltip title={record.id}>
              <b>{text}</b>
            </Tooltip>
          ) : (
            <Tooltip title={record.id}>
              <font color="gray">{text}</font>
            </Tooltip>
          ),
      },
      {
        title: "Primary",
        dataIndex: "primaryStatus",
        width: 100,
        render: (text, record) => this.renderPriceStatus(text,record),
      },
      {
        title: "Secondary",
        dataIndex: "secondaryStatus",
        width: 100,
        render: (text, record) => this.renderPriceStatus(text,record),
      },
      {
        title: "Description",
        dataIndex: "description",
      },
      {
        title: "Bid",
        dataIndex: "bid",
        width: 100,
        render: RenderHelpers.renderBidAsk,
      },
      {
        title: "Ask",
        dataIndex: "ask",
        width: 100,
        render: RenderHelpers.renderBidAsk,
      },
      {
        title: "Last Seen (UTC)",
        dataIndex: "timestamp",
        width: 100,
        render: (text) => this.renderDate(text)
      },
      {
        title: "MT4",
        dataIndex: "mt4Name",
        width: 100,
      },
      {
        title: "Primary Provider",
        dataIndex: "primaryFeed",
        width: 100,
        render: (text, record) => RenderHelpers.renderProvider(text, record, true),
      },
      {
        title: "Primary Feed",
        dataIndex: "primaryName",
        width: 150,
        render: (text, record) => RenderHelpers.renderFeeds(text, record, true),
      },
      {
        title: "Secondary Provider",
        dataIndex: "secondaryFeed",
        width: 100,
        render: (text, record) => RenderHelpers.renderProvider(text, record, false),
      },
      {
        title: "Secondary Feed",
        dataIndex: "secondaryName",
        width: 150,
        render: (text, record) =>
          RenderHelpers.renderFeeds(text, record, false),
      },
      {
        width: 50,
        render: (text, record) => <InstrumentTableButtons record={record} />,
      },
    ];
  };

  renderDate(text) { 
    return (text ? (
      <Tooltip title={moment(text).format(fullDateFormat).toString()}>
        {moment(text).format(dateFormat).toString()}
      </Tooltip>
    ) : (
      ""
    ));
  }

  renderPriceStatus(status) {
    let text = "";
    let colour = "#cccccc"; // default color

    switch (status) {
        case 0: // Unknown
            text = "Unknown";
            colour = "#808080";
            break;
        case 1: // Normal
            text = "Normal";
            colour = "#32CD32";
            break;
        case 2: // TimedOut
            text = "TimedOut";
            colour = "#B22222";
            break;
        case 3: // Error
            text = "Error";
            colour = "#FF4500";
            break;
        case 4: // Indicative
            text = "Indicative";
            colour = "#FFA500";
            break;
        case 5: // DataNotAvailable
            text = "Data Not Available";
            colour = "#FF4500";
            break;
        case 6: // NotConnected
            text = "Not Connected";
            colour = "#A9A9A9";
            break;
        case 7: // ValidationError
            text = "Validation Error";
            colour = "#DA70D6";
            break;
        case 8: // Unsubscribed
            text = "Unsubscribed";
            colour = "#20B2AA";
            break;
        case 9: // TradingHalt
            text = "Trading Halt";
            colour = "#B22222";
            break;
        default:
            break;
    }

    return !text ? "" : <font color={colour}>{text}</font>;
}

  async componentDidMount() {
    this._isMounted = true;
  }

  render() {
    return (
      <div>
        <Table
          rowKey="id"
          size="middle"
          onChange={this.props.onChange}
          loading={this.props.loading}
          columns={this.getInstrumentColumns(this.context.hasWriteRole)}
          dataSource={this.props.instruments}
          expandedRowRender={(record, i) => (
            <PrimaryAndSecondaryFeed record={record} />
          )}
          pagination={{
            size: 10,
            current: this.props.currentPage,
            total: this.props.total,
            showSizeChanger: false,
          }}
        />
      </div>
    );
  }
}

export default InstrumentTable;
