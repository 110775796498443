import React from "react";
import { Table, Empty } from "antd";

class ErrorDetailsDropdown extends React.Component {
  constructor(props) {
    super();
    this.props = props;
    this.state = { record: props.record };
  }
  async componentDidMount() {
    var columns = [
      {
        title: "Level",
        dataIndex: "level",
        width: "5%",
        render: (text) => this.renderErrorSeverity(text),
      },
      {
        title: "Type",
        dataIndex: "type",
        width: "10%",
        render: (text) => this.getNameOf(text),
      },
      {
        title: "Description",
        width: "20%",
        dataIndex: "description",
      },
      {
        title: "Currency code",
        width: "10%",
        dataIndex: "currencyCode",
      },
      {
        title: "Market Quote ID",
        width: "10%",
        dataIndex: "marketQuoteId",
      },
    ];
    this.setState({ columns: columns });
  }

  getNameOf(index) {
    for (let error of this.props.errorTypes) {
      if (error.index === index) {
        return error.description;
      }
    }
    return "Unknown";
  }

  renderErrorSeverity(text) {
    let color;
    switch (text) {
      case 0:
        color = "yellow";
        text = "Warning";
        break;
      case 1:
        color = "orange";
        text = "Error";
        break;
      case 2:
        color = "red";
        text = "Critical";
        break;
      default: 
        color= "white";
        text = "Unknown";
    }
    return (
      <b>
        <font className="bold16" color={color}>
          {text}
        </font>
      </b>
    );
  }

  render() {
    return (
      <div>
        <div className="errorSummaryTable">
          <Table
            dataSource={this.state.record.errorOrWarnings}
            indentSize={1}
            pagination={false}
            columns={this.state.columns}
            rowClassName={(record) => "black"}
            locale={{
              emptyText: <Empty image={null} description="No Acccounts" />,
            }}
            rowKey={(record) =>
              this.state.record.clientId + record.marketQuoteId + record.type
            }
          />
        </div>
      </div>
    );
  }
}

export default ErrorDetailsDropdown;
