import React from "react";
import { Table } from "antd";
import PositionSummary from "./positionSummary.js";
import { RenderHelpers } from "./renderHelpers";
import Errors from "./errors.js";
import LegacyAccountSummary from "./legacyAccountSummary.js";

class CurrencySummary extends React.Component {
  constructor(props) {
    super();
    this.props = props;
    this.state = { record: props.record, legacyRecord: props.legacyRecord };
  }
  async componentDidMount() {
    var columns = [
      {
        title: "Currency",
        dataIndex: "currencyCode",
        width: "24%",
        defaultSortOrder: "ascend",
        sortOrder: "ascend",
        sorter: (a, b) => {
          return ("" + a.currencyCode).localeCompare(b.currencyCode);
        },
        render: (text) => RenderHelpers.renderCurrency(text),
      },
      {
        title: "Balance",
        width: "10%",
        render: (record) =>
          record.accountBalance
            ? RenderHelpers.renderAccountSummaryValueWithSymbol(
                record.currencyCodeSymbol,
                record.accountBalance.value,
                true
              )
            : "-",
      },
      {
        title: "Credit Allocation",
        width: "10%",
        render: (record) =>
          record.credit
            ? RenderHelpers.renderAccountSummaryValueWithSymbol(
                record.currencyCodeSymbol,
                record.credit.value,
                true
              )
            : "-",
      },
      {
        title: "PnL",
        width: "10%",
        render: (record) =>
          record.openPnL
            ? RenderHelpers.renderAccountSummaryValueWithSymbol(
                record.currencyCodeSymbol,
                record.openPnL.value,
                true
              )
            : "-",
      },
      {
        title: "Margin",
        width: "10%",
        render: (record) =>
          record.changeIMR
            ? RenderHelpers.renderAccountSummaryValueWithSymbol(
                record.currencyCodeSymbol,
                record.changeIMR.value,
                true
              )
            : "-",
      },
      {
        title: "Margin %",
        width: "10%",
        render: (record) =>
          record.marginPercent !== undefined && record.marginPercent !== null
            ? RenderHelpers.renderAccountSummaryValue(
                record.marginPercent,
                true
              )
            : "-",
      },
      {
        title: "Account Valuation",
        width: "10%",
        render: (record) =>
          record.accountValuation
            ? RenderHelpers.renderAccountSummaryValueWithSymbol(
                record.currencyCodeSymbol,
                record.accountValuation.value,
                true
              )
            : "-",
      },
      {
        title: "",
        width: "16%",
        render: () => RenderHelpers.renderType(""),
      },
    ];
    this.setState({
      columns: columns,
      defaultSortOrder: "ascend",
      loading: false,
    });
  }

  render() {
    return (
      <div>
        <Errors record={this.props.record} />
        {this.props.legacyRecord ? (
          <LegacyAccountSummary record={this.props.legacyRecord} />
        ) : (
          ""
        )}
        <Table
          dataSource={
            this.props.record ? this.props.record.clientCurrencies : null
          }
          pagination={false}
          rowExpandable={(record) =>
            record.positions && record.positions.length > 0
          }
          expandedRowRender={(record, i) => <PositionSummary record={record} />}
          columns={this.state.columns}
          rowClassName={(record) => "currencyBlue"}
        />
      </div>
    );
  }
}

export default CurrencySummary;
