import React, { Component } from "react";
import {
  Layout,
  Button,
  Space,
  Spin,
  Upload,
  List,
  Select,
  Row,
  Col,
  Tooltip,
} from "antd";
import { InboxOutlined, FileOutlined } from "@ant-design/icons";
import "antd/dist/antd.dark.css";
import "../main.css";
import { AppContext } from "../dataContext";
import { Helpers } from "../helpers/helpers";
import ConnectorsCoordinatorProxy from "../proxies/connectorsCoordinatorProxy";
import PricingHubProxy from "../proxies/pricinghubProxy";

const { Content } = Layout;

class ProviderInstrumentImport extends Component {
  static contextType = AppContext;
  formRef = React.createRef();

  constructor(props) {
    super();
    const coordinatorAvailable = Helpers.currentEnv().COORDINATOR_AVAILABLE === "true";
    this.props = props;
    this.pricingHubProxy = new PricingHubProxy(
      {}
    );
    this.connectorCoordinatorProxy = new ConnectorsCoordinatorProxy();

    this.state = {
      loading: true,
      coordinatorAvailable: coordinatorAvailable,
      fileList: [],
      uploadingProviderInstrumentDisabled: true,
      uploadingInstrumentDisabled: true,
      selectedProvider: "",
      disabledImport: true,
      providerDisabled: false,
      providerInstrumentPopulated : false,
      instrumentPopulated : false
    };
  }

  componentDidMount = async () => {
    this._isMounted = true;
    this.context.updateMenuSelection("importProviderInstruments");
    this.setState({ loading: false });
  };

  componentDidUpdate = async (props) => {
    this._isMounted = true;
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  uploadProviderInstrumentFile = async () => {
    this.setState({ loading: true });
    let file = this.state.providerInstrumentFileList[0];
    await this.connectorCoordinatorProxy.importProviderInstruments(
      this.state.selectedProvider,
      file
    );
    this.setState({
      loading: false,
      providerDisabled: false,
      uploadingProviderInstrumentDisabled: true,
    });
  };

  uploadInstrumentFile = async () => {
    this.setState({ loading: true });
    let file = this.state.instrumentFileList[0];
    await this.connectorCoordinatorProxy.importInstruments(file);
    this.setState({ loading: false });
  };

  onChange = (info) => {
    console.debug(info.fileList);
  };

  onProviderInstrumentFileRemove = (file) => {
    this.setState((state) => {
      const index = state.providerInstrumentFileList.indexOf(file);
      const newFileList = state.providerInstrumentFileList.slice();
      newFileList.splice(index, 1);
      return {
        providerInstrumentFileList: newFileList,
      };
    });
    this.setState({
      uploadingProviderInstrumentDisabled: true,
      providerDisabled: false,
      providerInstrumentPopulated : false
    });
  };

  onInstrumentFileRemove = (file) => {
    this.setState((state) => {
      const index = state.instrumentFileList.indexOf(file);
      const newFileList = state.instrumentFileList.slice();
      newFileList.splice(index, 1);
      return {
        instrumentFileList: newFileList,
      };
    });
    this.setState({
      uploadingInstrumentDisabled: true,
      instrumentPopulated : false
    });
  };

  beforeProviderInstrumentUpload = (file) => {
    this.setState({
      uploadingDisabled: true,
      providerDisabled: true,
      providerInstrumentPopulated : true,
    });
    let isCSV = file.type === "text/csv" && file.name.endsWith(".csv");
    if (!isCSV) {
      Helpers.showError(`${file.name.toUpperCase()} is not a CSV file`);
      this.setState((state) => ({
        providerInstrumentFileList: [],
      }));
    } else
      this.setState((state) => ({
        providerInstrumentFileList: [file],
      }));
    return isCSV ? true : Upload.LIST_IGNORE;
  };

  beforeInstrumentUpload = (file) => {
    this.setState({
      uploadingDisabled: true,
      instrumentPopulated : true,
    });
    let isCSV = file.type === "text/csv" && file.name.endsWith(".csv");
    if (!isCSV) {
      Helpers.showError(`${file.name.toUpperCase()} is not a CSV file`);
      this.setState((state) => ({
        instrumentFileList: [],
      }));
    } else
      this.setState((state) => ({
        instrumentFileList: [file],
      }));
    return isCSV ? true : Upload.LIST_IGNORE;
  };


  render() {
    return (
      <Content style={{ padding: "0 5px" }}>
        <Spin spinning={this.state.loading}>
          <Row
            gutter={[16, 16]}
            align="top"
   //         disabled={this.state.disabledImport}
          >
            <Col span={12}>
              <Space>
                Provider Instruments{" "}
                <Tooltip title="Import template">
                  <a href="providerInstrumentsImportTemplate.csv">
                    <FileOutlined />
                  </a>
                </Tooltip>
              </Space>
            </Col>
            <Col span={12}>
              <Space>
                Instruments{" "}
                <Tooltip title="Import template">
                  <a href="instrumentsImportTemplate.csv">
                    <FileOutlined />
                  </a>
                </Tooltip>
              </Space>
            </Col>
            <Col span={12}>
              <Upload.Dragger
//                disabled={this.state.disabledImport}
                maxCount={1}
                onChange={this.onChange}
                beforeUpload={this.beforeProviderInstrumentUpload}
                onRemove={this.onProviderInstrumentFileRemove}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag a CSV file to this area to upload Provider
                  Instruments
                </p>
                <p className="ant-upload-hint">
                  All the required columns are listed in the template files
                  above.
                </p>
              </Upload.Dragger>
            </Col>
            <Col span={12}>
              <Upload.Dragger
                maxCount={1}
                onChange={this.onChange}
                beforeUpload={this.beforeInstrumentUpload}
                onRemove={this.onInstrumentFileRemove}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag a CSV file to this area to upload Instruments
                </p>
                <p className="ant-upload-hint">
                  All the required columns are listed in the template files
                  above.
                </p>
              </Upload.Dragger>
            </Col>
            <Col span={12}>
              <Tooltip
                title={
                  !this.state.coordinatorAvailable
                    ? `Action prevented in ${Helpers.currentEnvName().toLowerCase()} environment`
                    : ""
                }
                color="red"
              >
                <Button
                  type="primary"
                  onClick={this.uploadProviderInstrumentFile}
                  disabled={this.state.providerInstrumentPopulated === false || !this.state.coordinatorAvailable }
                  loading={this.uploading}
                  style={{ marginTop: 16 }}
                >
                  {this.uploading ? "Uploading" : "Start Upload"}
                </Button>
              </Tooltip>
            </Col>
            <Col span={12}>
              <Tooltip
                title={
                  !this.state.coordinatorAvailable
                    ? `Action prevented in ${Helpers.currentEnvName().toLowerCase()} environment`
                    : ""
                }
                color="red"
              >
                <Button
                  type="primary"
                  onClick={this.uploadInstrumentFile}
                  disabled={this.state.instrumentPopulated === false || !this.state.coordinatorAvailable}
                  loading={this.uploading}
                  style={{ marginTop: 16 }}
                >
                  {this.uploading ? "Uploading" : "Start Upload"}
                </Button>
              </Tooltip>
            </Col>
          </Row>
        </Spin>
      </Content>
    );
  }
}

ProviderInstrumentImport.contextType = AppContext;
export default Helpers.withParams(ProviderInstrumentImport);
