import {
  HubConnectionBuilder,
  LogLevel,
  HubConnectionState,
  HttpTransportType,
} from "@microsoft/signalr";
import { Helpers } from "../../helpers/helpers";

class OrderQueuesDataAccessHub {
  constructor(
    onOrdersChanged,
    onMarketsChanged,
    onMarketQuotesChanged,
    onError,
    onReconnect
  ) {
    this.ORDER_QUEUES_REPOSITORY_URL =Helpers.currentEnv().DYNAMIC_DATA_REPOSITORY_API_URL.replace("/api","/api-ws")+ "proxy/orderDataAccess/";
    console.debug(
      "Order repository hub: ",
      Helpers.currentEnvName(),
      this.ORDER_QUEUES_REPOSITORY_URL
    );
    this.onOrdersChanged = onOrdersChanged;
    this.onMarketsChanged = onMarketsChanged;
    this.onMarketQuotesChanged = onMarketQuotesChanged;

    this.onError = onError;
    this.onReconnect = onReconnect;
    this.hubConnection = new HubConnectionBuilder()
      .withUrl(this.ORDER_QUEUES_REPOSITORY_URL + "orders", {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
      })
      .configureLogging(LogLevel.Debug)
      .withAutomaticReconnect()
      .build();

    this.hubConnection.keepAliveIntervalInMilliseconds = 30000;
    this.hubConnection.serverTimeoutInMilliseconds = 60000;

    this.hubConnection.onreconnected((connectionId) => {
      console.debug(
        "OrderDataAccessHub Reconnected connectionId: ",
        connectionId
      );
      if (onReconnect) {
        onReconnect();
      }
    });

    this.hubConnection.onclose((connectionId) => {
      console.debug("OrderDataAccessHub Closed connectionId: ", connectionId);
    });

    this.hubConnection.on("allOrdersUpdated", (message) => {
      if (this.onOrdersChanged) this.onOrdersChanged(message[0]);
    });

    this.hubConnection.on("marketsUpdated", (message) => {
      if (this.onMarketsChanged) this.onMarketsChanged(message[0]);
    });

    this.hubConnection.on("marketQuotesUpdated", (message) => {
      if (this.onMarketQuotesChanged) this.onMarketQuotesChanged(message[0]);
    });

    this.hubConnection.on("error", (message) => {
      if (this.onError) this.onError(message[0]);
    });
  }

  connect() {
    this.connectPromise = this.hubConnection
      .start()
      .then(() => {
        console.debug("OrderDataAccessHub Connected");
      })
      .catch((error) => {
        console.assert(
          this.hubConnection.state === HubConnectionState.Disconnected
        );
        console.error(error);
        setTimeout(() => this.connect(), 2000);
      });
    return this.connectPromise;
  }

  subscribe(marketQuoteId, marketId, tradeMode, search, page, pageLength) {
    if (
      this.hubConnection &&
      this.hubConnection.state === HubConnectionState.Connected
    ) {
      this.hubConnection
        .invoke("subscribe", {
          marketQuoteId: marketQuoteId,
          marketId: marketId,
          tradeMode: tradeMode,
          search: search,
          page: page,
          pageLength: pageLength,
        })
        .catch((err) => console.error(err));
    }
  }

  unsubscribe(marketQuoteId, marketId, tradeMode, search, page, pageLength) {
    if (
      this.hubConnection &&
      this.hubConnection.state === HubConnectionState.Connected
    ) {
      this.hubConnection
        .invoke("unsubscribe", {
          marketQuoteId: marketQuoteId,
          marketId: marketId,
          tradeMode: tradeMode,
          search: search,
          page: page,
          pageLength: pageLength,
        })
        .catch((err) => console.error(err));
    }
  }

  stop() {
    if (this.hubConnection !== HubConnectionState.Disconnected) {
      return this.connectPromise.then(() =>
        this.hubConnection.stop().catch((err) => console.error(err))
      );
    }
    return Promise.resolve(true);
  }
}

export default OrderQueuesDataAccessHub;
