import React, { Component } from "react";
import { Layout, Table, Button, Space, Tooltip } from "antd";
import {
  RedoOutlined,
  PauseOutlined,
  CaretRightOutlined,
} from "@ant-design/icons";
import "antd/dist/antd.dark.css";
import "../main.css";
import { RenderHelpers } from "../helpers/renderHelpers";
import { AppContext } from "../dataContext";

const { Content } = Layout;

class SqlTableDependencyDetails extends Component {
  static contextType = AppContext;
  constructor(props) {
    super();
    this.props = props;
    var columns = [
      {
        title: "Table name",
        dataIndex: "name",
      },
      {
        title: "Status",
        dataIndex: "statusDescription",
        render: RenderHelpers.renderSqlTableDependencyStatus,
      },
      {
        title: "Raw Status",
        dataIndex: "rawStatusDescription",
        render: RenderHelpers.renderSqlTableDependencyRawStatus,
      },
      {
        title: "Id",
        dataIndex: "id",
        key: "id",
      },
      {
        width: 50,
        render: (text, record) => (
          <Space>
            <Tooltip title="Start">
              <Button
                type="primary"
                shape="circle"
                onClick={() =>
                  this.props.start(this.props.record.name, record.name)
                }
                icon={<CaretRightOutlined />}
              />
            </Tooltip>
            <Tooltip title="Stop">
              <Button
                type="primary"
                shape="circle"
                onClick={() =>
                  this.props.stop(this.props.record.name, record.name)
                }
                icon={<PauseOutlined />}
              />
            </Tooltip>
            <Tooltip title="Reset">
              <Button
                type="primary"
                shape="circle"
                onClick={() =>
                  this.props.reset(this.props.record.name, record.name)
                }
                icon={<RedoOutlined />}
              />
            </Tooltip>
          </Space>
        ),
      },
    ];

    this.state = {
      columns: columns,
      loading: false,
      tables: this.props.record.tables,
    };
  }

  async componentDidMount() {
    this._isMounted = true;
    this.setState({ tables: [...this.props.record.tables] });
  }

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  componentDidUpdate = (props) => {
    if (props.record !== this.props.record) {
      this.setState({ tables: [...this.props.record.tables] });
    }
  };
  render() {
    return (
      <Layout className="layout">
        <Content style={{ padding: "0 5px" }}>
          <Table
            rowKey="id"
            size="middle"
            loading={this.state.loading}
            columns={this.state.columns}
            dataSource={this.state.tables}
            pagination={false}
          />
        </Content>
      </Layout>
    );
  }
}
SqlTableDependencyDetails.contextType = AppContext;
export default SqlTableDependencyDetails;
