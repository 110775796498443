import {
  HubConnectionBuilder,
  LogLevel,
  HubConnectionState,
  HttpTransportType,
} from "@microsoft/signalr";
import { Helpers } from "../helpers/helpers";

class DynamicDataRepositoryRedisDbProxy {
  constructor(
    { onProvider = null,
      onInstrumentWithFeeds = null,
      onProviderInstrument = null,
      onInstrument = null,
      onMarket = null,
      onMarketTradeSettings = null,
      onReconnect = null }
  ) {
    this.onProvider = onProvider;
    this.onInstrumentWithFeeds = onInstrumentWithFeeds;
    this.onProviderInstrument = onProviderInstrument;
    this.onInstrument = onInstrument;
    this.onMarket = onMarket;
    this.onMarketTradeSettings = onMarketTradeSettings;
    this.onReconnect = onReconnect;
    this.DYNAMIC_DATA_REPOSITORY_API_URL =
      Helpers.currentEnv().DYNAMIC_DATA_REPOSITORY_API_URL;
    console.debug(
      "Dynamic data repository: ",
      Helpers.currentEnvName(),
      this.DYNAMIC_DATA_REPOSITORY_API_URL
    );

    this.hubConnection = new HubConnectionBuilder()
      .withUrl(this.DYNAMIC_DATA_REPOSITORY_API_URL + "wsredisdb", {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
      })
      .configureLogging(LogLevel.Debug)
      .withAutomaticReconnect()
      .build();

    this.hubConnection.keepAliveIntervalInMilliseconds = 60000;
    this.hubConnection.serverTimeoutInMilliseconds = 90000;

    this.hubConnection.onreconnected((connectionId) => {
      console.debug("RedisDB Reconnected...");
      this.hubConnection
        .invoke("refresh", 15)
        .catch((err) => console.error("RedisDB onreconnected: ", err));
    });

    this.hubConnection.onclose((connectionId) => {
      console.debug("RedisDB connection closed...");
      this.oldConnectionId = connectionId;
    });

    this.hubConnection.on("providerDatabaseEntity", (message) => {
      if (this.onProvider) this.onProvider(message[0].data);
    });

    this.hubConnection.on("instrumentWithFeeds", (message) => {
      if (this.onInstrumentWithFeeds) this.onInstrumentWithFeeds(message[0]);
    });

    this.hubConnection.on("providerInstrumentDatabaseEntity", (message) => {
      if (this.onProviderInstrument) this.onProviderInstrument(message[0]);
    });

    this.hubConnection.on("market", (message) => {
      if (this.onMarket) this.onMarket(message[0].data);
    });

    this.hubConnection.on("marketTradeSettings", (message) => {
      if (this.onMarketTradeSettings)
        this.onMarketTradeSettings(message[0].data);
    });
  }

  async connect() {
    if (this.hubConnection.state === HubConnectionState.Disconnected) {
      await this.hubConnection
        .start()
        .then(() => {
        })
        .catch((error) => {
          console.assert(
            this.hubConnection.state === HubConnectionState.Disconnected
          );
          console.error("RedisDB connect: ", error);
          setTimeout(() => this.connect(), 2000);
        });
    }
  }

  async subscribeAndOverride() {
    if (this.hubConnection.state === HubConnectionState.Connected)
      await this.hubConnection
        .invoke("subscribeAndOverride", 31)
        .catch((err) => console.error("RedisDB subscribeAndOverride: ", err));
  }

  async unsubscribe() {
    await this.hubConnection
      .invoke("unsubscribe")
      .catch((err) => console.error("RedisDB unsubscribe: ", err));
  }

  async stop() {
    await this.hubConnection.stop().catch((err) => console.error("RedisDB stop: ", err));
  }
}

export default DynamicDataRepositoryRedisDbProxy;
