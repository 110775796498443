import React from "react";
import { Table, Empty } from "antd";
import { RenderHelpers } from "./renderHelpers";
import Errors from "./errors.js";

class OpenOrderSummary extends React.Component {
  constructor(props) {
    super();
    this.props = props;
    this.state = { record: props.record };
  }
  async componentDidMount() {
    var columns = [
      {
        title: "Opening Order ID",
        dataIndex: "orderId",
        width: "20%",
        render: (text) => RenderHelpers.renderOrder(text),
      },
      {
        title: "Buy/Sell",
        width: "10%",
        render: (record) => {
          let tradeModeType;
          if (record.orderProperties.orderTradeMode === 0) {
            tradeModeType = 'Buy';
          } else if (record.orderProperties.orderTradeMode === 1) {
            tradeModeType = 'Sell'
          }
            return (
              <b>
                <font className="bold16" color="#A9C1D6">
                  {tradeModeType}
                </font>
              </b>
            );
        }
      },
      {
        title: "Stake",
        width: "10%",
        render: (record) =>
          RenderHelpers.renderAccountSummaryValueWithSymbol(
            record.currencyCodeSymbol,
            record.orderProperties.stakeBalance,
            true
          ),
      },
      {
        title: "Limit Price",
        width: "10%",
        render: (record) =>
          RenderHelpers.renderAccountSummaryValueWithSymbol(
            record.currencyCodeSymbol,
            record.orderProperties.limitOrderPrice,
            true
          ),
      },
      {
        title: "Stop Price",
        width: "10%",
        render: (record) =>
          RenderHelpers.renderAccountSummaryValueWithSymbol(
            record.currencyCodeSymbol,
            record.orderProperties.stopOrderPrice,
            true
          ),
      },
      {
        title: "Current Price",
        width: "10%",
        render: (record) =>
          this.props.record.tradeModeType === "Sell"
            ? this.props.record.currentPrice &&
              this.props.record.currentPrice.ask
              ? RenderHelpers.renderAccountSummaryValueWithSymbol(
                  this.props.record.currencyCodeSymbol,
                  this.props.record.currentPrice.ask,
                  true
                )
              : "-"
            : this.props.record.currentPrice &&
              this.props.record.currentPrice.bid
            ? RenderHelpers.renderAccountSummaryValueWithSymbol(
                this.props.record.currencyCodeSymbol,
                this.props.record.currentPrice.bid,
                true
              )
            : "-",
      },
    ];
    this.setState({ columns: columns });
  }
  render() {
    return (
      <div>
        <Errors record={this.props.record} />
        <div className="tradeSummaryTable">
          {this.state.record.openingOrders.length > 0 && (
            <Table
              dataSource={this.state.record.openingOrders}
              indentSize={1}
              pagination={false}
              columns={this.state.columns}
              rowClassName={(record) => "tradeBlue"}
              locale={{
                emptyText: (
                  <Empty image={null} description="No Opening Orders" />
                ),
              }}
            />
          )}
        </div>
      </div>
    );
  }
}

export default OpenOrderSummary;
