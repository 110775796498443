import {
  HubConnectionBuilder,
  LogLevel,
  HubConnectionState,
  HttpTransportType,
} from "@microsoft/signalr";
import { Helpers } from "../helpers/helpers";
import axios from "axios";
import axiosRetry from "axios-retry";
import * as _ from "underscore";

class DynamicDataRepositoryRedisProxy {
  constructor(onPrice, onReconnect) {
    this.onPrice = onPrice;
    this.onReconnect = onReconnect;
    this.DYNAMIC_DATA_REPOSITORY_API_URL =
      Helpers.currentEnv().DYNAMIC_DATA_REPOSITORY_API_URL;
    console.debug(
      "Dynamic data repository: ",
      Helpers.currentEnvName(),
      this.DYNAMIC_DATA_REPOSITORY_API_URL
    );

    axiosRetry(axios, {
      retries: 3,
      shouldResetTimeout: true,
      retryCondition: (error) => true,
      retryDelay: (retryCount) => retryCount * 1000,
    });

    this.hubConnection = new HubConnectionBuilder()
      .withUrl(this.DYNAMIC_DATA_REPOSITORY_API_URL + "wsRedis", {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
      })
      .configureLogging(LogLevel.Debug)
      .withAutomaticReconnect()
      .build();

    this.hubConnection.keepAliveIntervalInMilliseconds = 60000;
    this.hubConnection.serverTimeoutInMilliseconds = 90000;

    this.hubConnection.onreconnected((connectionId) => {
      var marketQuoteIds = [];
      if (this.onReconnect) marketQuoteIds = this.onReconnect();
      if (marketQuoteIds && marketQuoteIds.length > 0) {
        console.debug("Redis Reconnected...", marketQuoteIds);
        this.hubConnection
          .invoke("refresh", marketQuoteIds)
          .catch((err) => console.error("Redis onreconnected: ", err));
      }
    });

    this.hubConnection.onclose((connectionId) => {
      console.debug("Redis connection closed...");
    });

    this.hubConnection.on("price", (message) => {
      if (this.onPrice) this.onPrice(message[0]);
    });
  }

  async connect() {
    if (this.hubConnection.state === HubConnectionState.Disconnected) {
      await this.hubConnection
        .start()
        .then(() => {
        })
        .catch((error) => {
          console.assert(
            this.hubConnection.state === HubConnectionState.Disconnected
          );
          console.error("Redis connect: ", error);
          setTimeout(() => this.connect(), 2000);
        });
    }
  }

  async subscribe(marketQuotes) {
    var marketQuoteIds = marketQuotes.map((x) => x.id);
    marketQuoteIds = _.uniq(marketQuoteIds, (x) => x);
    if (this.hubConnection.state === HubConnectionState.Connected)
      await this.hubConnection
        .invoke("subscribe", marketQuoteIds)
        .catch((err) => console.error("Redis subscribe: ", err));
  }

  async subscribeAndOverride(marketQuotes) {
    var marketQuoteIds = marketQuotes?.map((x) => x.id);
    marketQuoteIds = _.uniq(marketQuoteIds, (x) => x);
    if (
      marketQuoteIds &&
      this.hubConnection.state === HubConnectionState.Connected
    )
      await this.hubConnection
        .invoke("subscribeAndOverride", marketQuoteIds)
        .catch((err) => console.error("Redis subscribeAndOverride: ", err));
  }

  async unsubscribe() {
    await this.hubConnection
      .invoke("unsubscribe")
      .catch((err) => console.error("Redis unsubscribe: ", err));
  }

  async stop() {
    await this.hubConnection.stop().catch((err) => console.error("Redis stop: ", err));
  }

  async connectorPrices(names) {
    names = _.uniq(names, (x) => x);
    var response;
    try {
      response = await axios({
        method: "post",
        timeout: 30000,
        url: this.DYNAMIC_DATA_REPOSITORY_API_URL + "redis/connector/prices",
        data: names,
      });
    } catch (error) {
      console.error(error);
    }
    var results =
      response && response.status === 200 ? response?.data.results : [];
    console.debug("Dynamic data repository ConnectorPrices:", names, results);
    return results;
  }

  async connectorPrice(name) {
    var response;
    try {
      response = await axios({
        method: "get",
        url:
          this.DYNAMIC_DATA_REPOSITORY_API_URL +
          "redis/connector/prices/" +
          name,
      });
    } catch (error) {
      console.error(error);
    }
    var results =
      response && response.status === 200 ? response?.data.results : [];
    console.debug("Dynamic data repository ConnectorPrices:", name, results);
    return results;
  }

  async connectorStatusFeed(name) {
    var response;
    try {
      response = await axios({
        method: "get",
        timeout: 30000,
        url: this.DYNAMIC_DATA_REPOSITORY_API_URL + "redis/connector/status/feeds/" + name,
      });
    } catch (error) {
      console.error(error);
    }
    var results =
      response && response.status === 200 ? response?.data.results : [];
    console.debug(
      "Dynamic data repository ConnectorFeedStatuses:",
      name,
      results
    );
    return results;
  }

  async connectorStatusFeeds(names) {
    names = _.uniq(names, (x) => x);
    var response;
    try {
      response = await axios({
        method: "post",
        timeout: 30000,
        url: this.DYNAMIC_DATA_REPOSITORY_API_URL + "redis/connector/status/feeds/",
        data: names,
      });
    } catch (error) {
      console.error(error);
    }
    var results =
      response && response.status === 200 ? response?.data.results : [];
    console.debug(
      "Dynamic data repository ConnectorFeedStatuses:",
      names,
      results
    );
    return results;
  }

  async tradableMarket(marketIds) {
    var response;
    try {
      response = await axios({
        method: "post",
        timeout: 30000,
        url: this.DYNAMIC_DATA_REPOSITORY_API_URL + "redis/tradeable/markets",
        data: marketIds,
      });
    } catch (error) {
      console.error(error);
    }
    var results =
      response && response.status === 200 ? response?.data.results : [];
    console.debug(
      "Dynamic data repository TradeableMarkets:",
      marketIds,
      results
    );
    return results;
  }

  async priceLive(marketQuoteIds) {
    var response;
    try {
      response = await axios({
        method: "post",
        timeout: 30000,
        url: this.DYNAMIC_DATA_REPOSITORY_API_URL + "redis/price/live",
        data: marketQuoteIds,
      });
    } catch (error) {
      console.error(error);
    }
    var results =
      response && response.status === 200 ? response?.data.results : [];
    console.debug("Dynamic data repository PriceLive:", marketQuoteIds, results);
    return results;
  }
}

export default DynamicDataRepositoryRedisProxy;
