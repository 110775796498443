import axios from "axios";
import axiosRetry from "axios-retry";
import { Helpers } from "../helpers/helpers";


class ConnectorsCoordinatorProxy {
  constructor() {
    this.CONNECTORS_COORDINATOR_API_URL = //"http://localhost:5030/api/"
       Helpers.currentEnv().DYNAMIC_DATA_REPOSITORY_API_URL +
      "proxy/PricingHubCoordinator/";
    this.APPLICATION = "DASHBOARD";
    console.debug(
      "Connector Coordinator API: ",
      Helpers.currentEnvName(),
      this.CONNECTORS_COORDINATOR_API_URL
    );

    axiosRetry(axios, {
      retries: 3,
      shouldResetTimeout: true,
      retryCondition: (error) => {
        console.debug(error);
        return true;
      },
      retryDelay: (retryCount) => retryCount * 1000,
    });
  }

  async swapConnectorInstance(providerName) {
    var response;
    try {
      response = await axios({
        method: "post",
        url: this.CONNECTORS_COORDINATOR_API_URL + "provider/connector-swap/" + providerName,
        headers: {
          application: this.APPLICATION,
        },
      }
        
      );
    } catch (error) {
      console.error(error);
    }
    var results =
      response && response.status === 200 ? response?.data : undefined;
    console.debug("Pricing Hub Coordinator Swap:", providerName, results);
    return results;
  }

  async validateInstrumentsImport(file) {
    var response;
    try {
      var formData = new FormData();
      formData.append(file.name, file);
      response = await axios.post(
        this.CONNECTORS_COORDINATOR_API_URL + "instrument/validate",
        formData,
        {
          headers: [
            { application: this.APPLICATION },
            { "content-type": "multipart/form-data" },
          ],
          "axios-retry": {
            retries: 0,
          },
        }
      );
    } catch (error) {
      console.error(error);
      response = error.response;
    }
    var results = response?.data;
    console.debug("Data repository Validation of Instruments Import:", results);
    return results;
  }

  async validateProviderInstrumentsImport(providerName, file) {
    var response;
    try {
      var formData = new FormData();
      formData.append(providerName, file, file.name);
      response = await axios.post(
        this.CONNECTORS_COORDINATOR_API_URL + "providerInstrument/validate",
        formData,
        {
          headers: [
            { application: this.APPLICATION },
            { "content-type": "multipart/form-data" },
          ],
          "axios-retry": {
            retries: 0,
          },
        }
      );
    } catch (error) {
      console.error(error);
      response = error.response;
    }
    var results = response?.data;
    console.debug(
      "Data repository Validation of Provider Instruments Import:",
      results
    );
    return results;
  }

  async importProviderInstruments(providerName, file) {
    var response = { data: { errors: [] } };
    try {
      var formData = new FormData();
      formData.append(providerName, file, file.name);
      response = await axios.post(
        this.CONNECTORS_COORDINATOR_API_URL + "providerInstrument/csv",
        formData,
        {
          headers: [
            { application: this.APPLICATION },
            { "content-type": "multipart/form-data" },
          ],
          "axios-retry": {
            retries: 0,
          },
        }
      );
    } catch (error) {
      console.error(error);
      if (!error.response)
        response.data.errors = [
          "A file cannot be modified after validation. Please try again.",
        ];
      else response = error.response;
    }
    var results = response?.data;
    console.debug("Data repository Provider Instruments Import:", results);
    return results;
  }

  async importInstruments(file) {
    var response = { data: { errors: [] } };
    try {
      var formData = new FormData();
      formData.append(file.name, file);
      response = await axios.post(
        this.CONNECTORS_COORDINATOR_API_URL + "instrument/csv",
        formData,
        {
          headers: [
            { application: this.APPLICATION },
            { "content-type": "multipart/form-data" },
          ],
          "axios-retry": {
            retries: 0,
          },
        }
      );
    } catch (error) {
      console.error(error);
      if (!error.response)
        response.data.errors = [
          "A file cannot be modified after validation. Please try again.",
        ];
      else response = error.response;
    }
    var results = response?.data;
    console.debug("Data repository Instruments Import:", results);
    return results;
  }
  async addInstrument(instrument) {
    var response;
    try {
      response = await axios({
        method: "post",
        url: this.CONNECTORS_COORDINATOR_API_URL + "instrument",
        data: instrument,
        headers: { application: this.APPLICATION },
      });
    } catch (error) {
      console.error(error);
    }
    var results =
      response && response.status === 200 ? response?.data.results : [];
    console.debug("Data repository Update Instrument:", results);
    return results[0];
  }

  async updateInstrument(id, instrument) {
    var response;
    try {
      response = await axios({
        method: "put",
        url: this.CONNECTORS_COORDINATOR_API_URL + `instrument/${id}`,
        data: instrument,
        headers: { application: this.APPLICATION },
      });
    } catch (error) {
      console.error(error);
    }
    console.debug("Data repository Update Instrument:", response);
    return response;
  }

  async addProviderInstrument(providerInstrument) { 
    var response;
    try {
      response = await axios({
        method: "post",
        url: this.CONNECTORS_COORDINATOR_API_URL + "providerInstrument/",
        data: providerInstrument,
        headers: { application: this.APPLICATION },
      });
    } catch (error) {
      console.error(error);
    }
    console.debug("Data repository Update ProviderInstrument:", response);
    return response && response.status === 200;
  }

  async updateProviderInstrument(providerInstrument) {
    var response;
    try {
      response = await axios({
        method: "put",
        url: this.CONNECTORS_COORDINATOR_API_URL + "providerInstrument/" + providerInstrument.id,
        data: providerInstrument,
        headers: { application: this.APPLICATION },
      });
    } catch (error) {
      console.error(error);
    }
    console.debug("Data repository Update ProviderInstrument:", response);
    return response && response.status === 200;
  }

  async swapPrimaryAndSecondary(instrumentName) {
    var response;
    try {
      response = await axios({
        method: "put",
        url:
          this.CONNECTORS_COORDINATOR_API_URL +
          "connectorMaps/swap/" +
          encodeURIComponent(instrumentName),
        headers: { application: this.APPLICATION },
      });
    } catch (error) {
      console.error(error);
    }
    console.debug("Data repository Swap ConnectorMaps:", response);
    return response && response.status === 200;
  }

  async deleteInstrument(instrumentId) {
    var response;
    try {
      response = await axios({
        method: "delete",
        url: `${this.CONNECTORS_COORDINATOR_API_URL}instrument/${instrumentId}`,
        headers: { application: this.APPLICATION },
      });
    } catch (error) {
      console.error(error);
    }
    return response && response.status === 200;
  }

  async deleteProviderInstrument(providerInstrumentId) {
    var response;
    try {
      response = await axios({
        method: "delete",
        url: `${this.CONNECTORS_COORDINATOR_API_URL}providerInstrument/${providerInstrumentId}`,
        headers: { application: this.APPLICATION },
      });
    } catch (error) {
      console.error(error);
    }
    return response && response.status === 200;
  }
}

export default ConnectorsCoordinatorProxy;
