import React from "react";
import AccountSummaryDataAccess from "../proxies/accountSummaryDataAccess.js";
import { Input, Button, Table, Radio, Space } from "antd";
import CurrencySummary from "./currencySummary.js";
import AccountSummaryDataAccessHub from "../proxies/accountSummaryDataAccessHub.js";
import { RenderHelpers } from "./renderHelpers";
import { SearchOutlined } from "@ant-design/icons";
import { AppContext } from "../dataContext";
import * as _ from "underscore";

class ClientSummaries extends React.Component {
  static contextType = AppContext;
  constructor(props) {
    super();
    this.refreshTimer = null;
    this.props = props;
    this.accountSummaryProxy = new AccountSummaryDataAccess();
    this.accountSummaryProxyHub = new AccountSummaryDataAccessHub(
      message => this.onAccountSummaryChanged(message),
      () => this.onReconnect()
    );
    this._isMounted = false;

    this.idField = [];
    this.nameField = [];
    this.userNameField = [];
    this.defaultAccountTypeFilterOptions = [
      { key: "CFD", description: "CFD" },
      { key: "Spread", description: "Spread" },
    ];

    this.filterColumns = {};

    this.state = {
      clientSummaries: null,
      page: 0,
      pageSize: 10,
      sortCol: "marginPercent",
      sortOrder: "ascend",
      loading: false,
      accountTypes: this.defaultAccountTypeFilterOptions,
    };
    this.throttledGetData = _.throttle(this.getData, 500, {
      leading: false,
    });
  }

  getColumns = () => {
    return [
      {
        title: "Account (Client Id)",
        sorter: true,
        dataIndex: ["calculation", "alertLevel"],
        columnKey: "alphaCode",
        filteredValue: this.filterColumns.clientIdOrAlphaCode ? [this.filterColumns.clientIdOrAlphaCode] : [],
        width: "14%",
        ...this.getColumnSearchProps(
          "clientIdOrAlphaCode",
          "Search AccountId or ClientId",
          this.throttledGetData.bind(this),
          this.resetFilter,
          this.setFilterField
        ),
        render: (text, record) =>
          record.calculation && record.calculation.alertLevel
            ? RenderHelpers.renderAccountSummaryWithAlertLevel(
                record.client.alphaCode + " (" + record.clientId + ")",
                record.calculation.alertLevel
              )
            : RenderHelpers.renderAccountSummaryWithAlertLevel(record.client.alphaCode + " (" + record.clientId + ")", 0),
      },
      {
        title: "Type",
        dataIndex: "type",
        sorter: true,
        columnKey: "type",
        filteredValue: this.filterColumns.accountType ? [this.filterColumns.accountType] : [],
        width: "6%",
        ...this.getRadioFilterProps(
          "accountType",
          this.state.accountTypes,
          this.throttledGetData.bind(this),
          this.resetFilter,
          this.setFilterField
        ),
      },
      {
        title: "Currency",
        sorter: true,
        columnKey: "currency",
        dataIndex: ["calculation", "defaultRateCode"],
        filterMultiple: false,
        width: "5%",
        render: (text, record) => (record.calculation && record.calculation.defaultRateCode ? record.calculation.defaultRateCode : "?"),
      },
      {
        title: "Balance",
        sorter: true,
        columnKey: "accountBalance",
        dataIndex: ["calculation", "accountBalance"],
        width: "10%",
        render: (text, record) =>
          record.calculation && record.calculation.accountBalance
            ? RenderHelpers.renderAccountSummaryValueWithSymbol(
                record.calculation.defaultRateSymbol,
                record.calculation.accountBalance.defaultCcy,
                true
              )
            : RenderHelpers.renderAccountSummaryValue("-"),
      },
      {
        title: "Credit Allocation",
        sorter: true,
        columnKey: "credit",
        dataIndex: ["calculation", "credit"],
        width: "10%",
        render: (text, record) =>
          record.calculation && record.calculation.credit
            ? RenderHelpers.renderAccountSummaryValueWithSymbol(
                record.calculation.defaultRateSymbol,
                record.calculation.credit.defaultCcy,
                true
              )
            : RenderHelpers.renderAccountSummaryValue("-"),
      },
      {
        title: "PnL",
        sorter: true,
        columnKey: "openPnL",
        dataIndex: ["calculation", "openPnL"],
        width: "10%",
        render: (text, record) =>
          record.calculation && record.calculation.openPnL
            ? RenderHelpers.renderAccountSummaryValueWithSymbol(
                record.calculation.defaultRateSymbol,
                record.calculation.openPnL.defaultCcy,
                true
              )
            : RenderHelpers.renderAccountSummaryValue("-"),
      },
      {
        title: "Margin",
        sorter: true,
        columnKey: "changeIMR",
        dataIndex: ["calculation", "changeIMR"],
        width: "10%",
        render: (text, record) =>
          record.calculation && record.calculation.changeIMR
            ? RenderHelpers.renderAccountSummaryValueWithSymbol(
                record.calculation.defaultRateSymbol,
                record.calculation.changeIMR.defaultCcy,
                true
              )
            : RenderHelpers.renderAccountSummaryValue("-"),
      },
      {
        title: "Margin %",
        dataIndex: ["calculation", "marginPercent"],
        defaultSortOrder: "ascend",
        sorter: true,
        columnKey: "marginPercent",
        width: "8%",
        render: (text, record) =>
          record.calculation && record.calculation.marginPercent !== undefined && record.calculation.marginPercent !== null
            ? RenderHelpers.renderAccountSummaryWithAlertLevel(record.calculation.marginPercent.toFixed(2), record.calculation.alertLevel)
            : RenderHelpers.renderAccountSummaryValue("-"),
      },
      {
        title: "Account Valuation",
        sorter: true,
        columnKey: "accountValuation",
        dataIndex: ["calculation", "accountValuation"],
        width: "10%",
        render: (text, record) =>
          record.calculation && record.calculation.accountValuation
            ? RenderHelpers.renderAccountSummaryValueWithSymbol(
                record.calculation.defaultRateSymbol,
                record.calculation.accountValuation.defaultCcy,
                true
              )
            : RenderHelpers.renderAccountSummaryValue("-"),
      },
      {
        title: "Name",
        sorter: true,
        columnKey: "name",
        dataIndex: ["client", "name"],
        filteredValue: this.filterColumns.name ? [this.filterColumns.name] : [],
        width: "10%",
        render: (text, record) => (record.client ? record.client.name : ""),
        ...this.getColumnSearchProps("name", "Search Name", this.throttledGetData.bind(this), this.resetFilter, this.setFilterField),
      },
      {
        title: "User Name",
        sorter: true,
        columnKey: "userName",
        dataIndex: ["client", "userName"],
        filteredValue: this.filterColumns.userName ? [this.filterColumns.userName] : [],
        width: "10%",
        render: (text, record) => (record.client ? record.client.userName : ""),
        ...this.getColumnSearchProps(
          "userName",
          "Search User Name",
          this.throttledGetData.bind(this),
          this.resetFilter,
          this.setFilterField
        ),
      },
      {
        title: "Status",
        width: "5%",
        dataIndex: ["calculation", "hasErrors"],
        render: (text, record) => RenderHelpers.renderAccountSummaryErrors(record.calculation),
      },
    ];
  };

  getColumnSearchProps = (dataIndex, placeHolder, searchFunction, resetFunction, updateFilterFunction) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={placeHolder}
          value={selectedKeys[0]}
          onChange={e => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            updateFilterFunction(dataIndex, e.target.value);
          }}
          onPressEnter={() => {
            searchFunction();
            confirm();
          }}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type='primary'
          onClick={() => {
            searchFunction();
            confirm();
          }}
          size='small'
          style={{ width: 90, marginRight: 8 }}>
          Search
        </Button>
        <Button onClick={() => resetFunction(clearFilters, dataIndex)} size='small' style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
  });

  getRadioFilterProps = (dataIndex, options, searchFunction, resetFunction, updateFilterFunction) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Radio.Group
          ref={node => {
            this.searchInput = node;
          }}
          onChange={e => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            updateFilterFunction(dataIndex, e.target.value);
            confirm();
          }}
          value={selectedKeys ? selectedKeys[0] : null}
          style={{ width: 188, marginBottom: 8, display: "block" }}>
          <Space direction='vertical'>
            {options.map(x => (
              <Radio value={x.key} key={x.key}>
                {x.description}
              </Radio>
            ))}
          </Space>
        </Radio.Group>
        <Button
          type='primary'
          onClick={() => {
            searchFunction();
            confirm();
          }}
          size='small'
          style={{ width: 90, marginRight: 8 }}>
          Search
        </Button>
        <Button onClick={() => resetFunction(clearFilters, dataIndex)} size='small' style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
  });

  async onReconnect() {
    await this.throttledGetData();
  }
  async onAccountSummaryChanged(message) {
    var updated = false;
    if (this.state && this.state.clientSummaries) {
      const newList = this.state.clientSummaries.map(item => {
        if (item.key === message.key) {
          updated = true;
          return message;
        }

        return item;
      });
      if (updated) {
        this.setState({ clientSummaries: newList });
      }
    }
  }

  async componentWillUnmount() {
    this._isMounted = false;
    if (this.state.clientSummaries) {
      await this.accountSummaryProxyHub.unsubscribe(
        this.state.clientSummaries.map(x => (x.clientCalculationKey ? x.clientCalculationKey : x.clientSummaryKey))
      );
    }
  }

  async componentDidMount() {
    this._isMounted = true;
    this.setState({ loading: false });
    await this.getOptions();
    await this.accountSummaryProxyHub.connect();

    this.setFiltersFromProps();

    if (this.props.loadAllWhenNoSearchString) {
      this.getData();
    }
  }

  async getOptions() {
    try {
      var response = await this.accountSummaryProxy.getOptions();
      if (response.accountTypes && response.accountTypes.length > 0) {
        this.setState({ accountTypes: response.accountTypes });
      } else {
        this.setState({ accountTypes: this.defaultAccountTypeFilterOptions });
      }
    } catch (error) {
      console.error(error);
      this.setState({ accountTypes: this.defaultAccountTypeFilterOptions });
    }
  }

  async componentDidUpdate(prevProps) {
    var shouldRefresh = this.setFiltersFromProps(prevProps);
    if (prevProps.searchString !== this.props.searchString) {
      if (this.props.searchString && this.props.searchString.length > 2) {
        shouldRefresh = true;
      } else if ((!this.props.searchString || this.props.searchString.length === 0) && this.props.loadAllWhenNoSearchString) {
        shouldRefresh = true;
      }
    }
    if (this.areEqual(prevProps.legacyAccountSummaries, this.props.legacyAccountSummaries) === false) {
      shouldRefresh = true;
    }

    if (shouldRefresh) {
      this.getData();
    }
    if (prevProps.refreshInterval !== this.props.refreshInterval) {
      this.startRefresh(this.props.refreshInterval);
    }
  }

  arrayEquals(first, second) {
    if (first === second) {
      return true;
    }
    if ((first === undefined || first === null) && (second === undefined || second === null || second.length === 0)) {
      return true;
    }
    if ((second === undefined || second === null) && (first === undefined || first === null || first.length === 0)) {
      return true;
    }
    if ((first === undefined || first === null) && second !== undefined && second !== null && second.length > 0) {
      return false;
    }
    if ((second === undefined || second === null) && first !== undefined && first !== null && first.length > 0) {
      return false;
    }
    if (first.length !== second.length) {
      return false;
    }
    if (first.sort().join(",") === second.sort().join(",")) {
      return true;
    }
    return false;
  }

  areFiltersEqual(filter1, filter2){
    if (filter1 === filter2) {
      return true;
    }
    if ((filter1 === undefined || filter1 === null) && (filter2 === undefined || filter2 === null || filter2.length === 0)) {
      return true;
    }
    if ((filter2 === undefined || filter2 === null) && (filter1 === undefined || filter1 === null || filter1.length === 0)) {
      return true;
    }
    if ((filter1 === undefined || filter1 === null) && filter2 !== undefined && filter2 !== null && filter2.length > 0) {
      return false;
    }
    if ((filter2 === undefined || filter2 === null) && filter1 !== undefined && filter1 !== null && filter1.length > 0) {
      return false;
    }
    return this.areEqual(filter1.lastLoggedInAgoMonths, filter2.lastLoggedInAgoMonths) &&
    this.arrayEquals(filter1.errorLevel, filter2.errorLevel) &&
    this.arrayEquals(filter1.alertLevel, filter2.alertLevel) &&
    this.arrayEquals(filter1.errorType, filter2.errorType) &&
    this.areEqual(filter1.hasPositions, filter2.hasPositions) &&
    this.areEqual(filter1.hasErrors, filter2.hasErrors) &&
    this.arrayEquals(filter1.whiteLabelId, filter2.whiteLabelId) &&
    this.arrayEquals(filter1.clientType, filter2.clientType) &&
    this.areEqual(filter1.accountType, filter2.accountType) &&
    this.areEqual(filter1.name, filter2.name) &&
    this.areEqual(filter1.userName, filter2.userName) &&
    this.areEqual(filter1.clientIdOrAlphaCode, filter2.clientIdOrAlphaCode);
  }

  setFiltersFromProps = prevProps => {
    var shouldRefresh = false;
    if (
      prevProps === null ||
      prevProps === undefined ||
      this.areEqual(prevProps.lastLoggedInAgoMonthsFilter, this.props.lastLoggedInAgoMonthsFilter) === false ||
      this.arrayEquals(prevProps.errorLevelFilter, this.props.errorLevelFilter) === false ||
      this.arrayEquals(prevProps.alertLevelsFilter, this.props.alertLevelsFilter) === false ||
      this.arrayEquals(prevProps.errorTypeFilter, this.props.errorTypeFilter) === false ||
      this.areEqual(prevProps.hasPositionsFilter, this.props.hasPositionsFilter) === false ||
      this.areEqual(prevProps.hasErrorsFilter, this.props.hasErrorsFilter) === false ||
      this.arrayEquals(prevProps.whiteLabelsFilter, this.props.whiteLabelsFilter) === false ||
      this.arrayEquals(prevProps.clientTypesFilter, this.props.clientTypesFilter) === false ||
      this.areEqual(prevProps.accountTypeFilter, this.props.accountTypeFilter) === false ||
      this.areEqual(prevProps.nameFilter, this.props.nameFilter) === false ||
      this.areEqual(prevProps.userNameFilter, this.props.userNameFilter) === false ||
      this.areEqual(prevProps.clientIdFilter, this.props.clientIdFilter) === false
    ) {
      var newList = Object.assign({}, this.filterColumns);

      if (this.props.accountTypeFilter) {
        var options = this.state.accountTypes.filter(x => x.key.toLowerCase() === this.props.accountTypeFilter.toLowerCase());
        if (options.length > 0) {
          newList.accountType = options[0].key;
          shouldRefresh = true;
        }
      } else {
        delete newList.accountType;
      }

      if (this.props.userNameFilter) {
        newList.userName = this.props.userNameFilter;
      } else {
        delete newList.userName;
      }

      if (this.props.nameFilter) {
        newList.name = this.props.nameFilter;
      } else {
        delete newList.name;
      }

      if (this.props.clientIdFilter) {
        newList.clientIdOrAlphaCode = this.props.clientIdFilter;
      } else {
        delete newList.clientIdOrAlphaCode;
      }

      if (this.props.lastLoggedInAgoMonthsFilter) {
        newList.lastLoggedInAgoMonths = this.props.lastLoggedInAgoMonthsFilter;
      } else {
        delete newList.lastLoggedInAgoMonths;
      }
      if (this.props.errorLevelFilter && this.props.errorLevelFilter.length > 0) {
        newList.errorLevel = this.props.errorLevelFilter;
      } else {
        delete newList.errorLevel;
      }
      if (this.props.alertLevelsFilter && this.props.alertLevelsFilter.length > 0) {
        newList.alertLevel = this.props.alertLevelsFilter;
      } else {
        delete newList.alertLevel;
      }
      if (this.props.errorTypeFilter && this.props.errorTypeFilter.length > 0) {
        newList.errorType = this.props.errorTypeFilter;
      } else {
        delete newList.errorType;
      }
      if (this.props.hasPositionsFilter !== null && this.props.hasPositionsFilter !== undefined) {
        newList.hasPositions = this.props.hasPositionsFilter;
      } else {
        delete newList.hasPositions;
      }
      if (this.props.hasErrorsFilter !== null && this.props.hasErrorsFilter !== undefined) {
        newList.hasErrors = this.props.hasErrorsFilter;
      } else {
        delete newList.hasErrors;
      }
      if (this.props.whiteLabelsFilter && this.props.whiteLabelsFilter.length > 0) {
        newList.whiteLabelId = this.props.whiteLabelsFilter;
      } else {
        delete newList.whiteLabelId;
      }
      if (this.props.clientTypesFilter && this.props.clientTypesFilter.length > 0) {
        newList.clientType = this.props.clientTypesFilter;
      } else {
        delete newList.clientType;
      }
      shouldRefresh = this.areFiltersEqual(newList, this.filterColumns)===false;
      this.filterColumns = newList;
      return shouldRefresh;
    }
    return shouldRefresh;
  };

  areEqual(val1, val2) {
    if (!val1 && !val2) {
      return true;
    }
    return val1 === val2;
  }

  startRefresh = intervalSeconds => {
    var that = this;
    if (this.refreshTimer) {
      console.debug("Clearing refresh");
      clearInterval(this.refreshTimer);
      this.refreshTimer = null;
    }
    if (intervalSeconds > 0) {
      console.debug("Starting auto refresh. Interval:" + intervalSeconds + "secs");
      this.refreshTimer = setInterval(function () {
        that.getData();
      }, intervalSeconds * 1000);
    }
  };

  filter = (selectedKeys, confirm, dataIndex) => {
    confirm();
    var filtered = selectedKeys.find(x => x !== undefined && x !== null && (typeof x !== "string" || (x.length > 0 && x.trim())));
    this.setFilterField(dataIndex, filtered);
  };

  setFilterField = (dataIndex, value) => {
    var newList = Object.assign({}, this.filterColumns);
    if (value) {
      newList[dataIndex] = value;
    } else {
      for (var element in newList) {
        if (~element.indexOf(dataIndex)) {
          delete newList[element];
        }
      }
    }

    this.filterColumns = newList;
  };

  resetFilter = (clearFilters, dataIndex) => {
    clearFilters();
    var newList = Object.assign({}, this.state.filterColumns);
    for (var element in newList) {
      if (~element.indexOf(dataIndex)) {
        delete newList[element];
      }
    }
    this.filterColumns = newList;
  };

  async getData() {
    if (this._isMounted && this.state.loading === false) {
      if (!this.props.searchString && !this.props.loadAllWhenNoSearchString) {
        this.clearData();
        return;
      }
      try {
        this.setState({ loading: true });
        if (this.state.clientSummaries) {
          this.accountSummaryProxyHub.unsubscribe(
            this.state.clientSummaries.map(x => (x.clientCalculationKey ? x.clientCalculationKey : x.clientSummaryKey))
          );
        }

        var filters = { ...this.filterColumns };

        if (this.props.onFilterApplied) {
          this.props.onFilterApplied({
            lastLoggedInAgoMonthsFilter: filters.lastLoggedInAgoMonths,
            errorLevelFilter: filters.errorLevel,
            errorTypeFilter: filters.errorType,
            hasPositionsFilter: filters.hasPositions,
            hasErrorsFilter: filters.hasErrors,
            whiteLabelsFilter: filters.whiteLabelId,
            clientTypesFilter: filters.clientType,
            accountTypeFilter: filters.accountType,
            searchString: this.props.searchString,
            nameFilter: filters.name,
            userNameFilter: filters.userName,
            clientIdFilter: filters.clientIdOrAlphaCode,
            alertLevelsFilter: filters.alertLevel,
          });
        }

        var response = await this.accountSummaryProxy.clientSummaries(
          this.props.searchString,
          this.state.page,
          this.state.pageSize,
          this.state.sortCol,
          this.state.sortOrder,
          this.props.legacyAccountSummaries,
          filters
        );

        if (response.filteredSummariesCount < this.state.page + 1 * this.state.pageSize) {
          this.setState({ page: 0 });
          response = await this.accountSummaryProxy.clientSummaries(
            this.props.searchString,
            0,
            this.state.pageSize,
            this.state.sortCol,
            this.state.sortOrder,
            this.props.legacyAccountSummaries,
            filters
          );
        }
        if (response.details) {
          this.accountSummaryProxyHub.subscribe(
            response.details.map(x => (x.clientCalculationKey ? x.clientCalculationKey : x.clientSummaryKey)),
            this.props.legacyAccountSummaries
          );
          this.setState({
            clientSummaries: response.details,
            filteredSummariesCount: response.filteredSummariesCount,
          });
        } else {
          this.setState({ clientSummaries: [], filteredSummariesCount: 0 });
        }
        if (this.props.onDataRetrieved) {
          this.props.onDataRetrieved({
            filteredRecordCount: response.filteredSummariesCount,
            filteredErrorCount: response.filteredErrorCount,
            errorCount: response.totalErrorCount,
            recordCount: response.totalSummaries,
          });
        }
      } catch (error) {
        console.error(error);
        this.setState({ clientSummaries: [], filteredSummariesCount: 0 });
      } finally {
        this.setState({ loading: false });
      }
    }
  }

  async clearData() {
    if (this._isMounted && this.state.loading === false) {
      try {
        this.setState({ loading: true });
        if (this.state.clientSummaries) {
          await this.accountSummaryProxyHub.unsubscribe(
            this.state.clientSummaries.map(x => (x.clientCalculationKey ? x.clientCalculationKey : x.clientSummaryKey))
          );
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.setState({
          clientSummaries: [],
          filteredSummariesCount: 0,
          loading: false,
        });
      }
    }
  }

  async handleTableChange(pagination, filters, sorter) {
    var sortCol = undefined;
    var sortOrder = undefined;
    if (sorter.column) {
      sortCol = sorter.column.columnKey;
      sortOrder = sorter.order;
    }

    this.setState({
      page: pagination.current - 1,
      pageSize: pagination.pageSize,
      sortCol: sortCol,
      sortOrder: sortOrder,
    });
    await this.getData();
  }

  render() {
    return (
      <div>
        <Table
          loading={this.state.loading}
          dataSource={this.state.clientSummaries}
          onChange={(pagination, filters, sorter) => this.handleTableChange(pagination, filters, sorter)}
          expandedRowRender={(record, i) =>
            record.calculation && record.calculation.clientCurrencies ? (
              <CurrencySummary record={record.calculation ? record.calculation : null} legacyRecord={record.legacyAccountSummary} />
            ) : null
          }
          pagination={{
            size: this.state.pageSize,
            total: this.state.filteredSummariesCount,
            showSizeChanger: false,
            showTotal: total => "" + total + " Account Summaries",
          }}
          columns={this.getColumns()}
        />
      </div>
    );
  }
}

export default ClientSummaries;
