import React from "react";
import { Table } from "antd";
import { RenderHelpers } from "./renderHelpers";

class LegacyAccountSummary extends React.Component {
    constructor(props) {
        super();
        this.props = props;
        this.state = { record: props.record };
    }
    async componentDidMount() {
        var columns = [
            {
                title: "Type",
                render: (record) => RenderHelpers.renderType('Legacy',JSON.stringify(record.underlyingResult)),
            },
            {
                title: "Balance",
                render: (record) =>
                        RenderHelpers.renderAccountSummaryValueWithSymbol(
                            record.currencySymbol,
                            record.accountBalance,
                            true
                        )
            },
            {
                title: "Credit Allocation",
                render: (record) =>
                    RenderHelpers.renderAccountSummaryValueWithSymbol(
                            record.currencySymbol,
                            record.creditAllocation,
                            true
                        )
            },
            {
                title: "OpenPL",
                render: (record) =>
                        RenderHelpers.renderAccountSummaryValueWithSymbol(
                            record.currencySymbol,
                            record.openPL,
                            true
                        )
            },
            {
                title: "Initial Margin",
                render: (record) =>
                    RenderHelpers.renderAccountSummaryValueWithSymbol(
                            record.currencySymbol,
                            record.initialMargin,
                            true
                        )
            },
            {
                title: "Percentage",
                dataIndex: "marginPercent",
            },
            {
                title: "Account Valuation",
                render: (record) =>
                    RenderHelpers.renderAccountSummaryValueWithSymbol(
                            record.currencySymbol,
                            record.accountValuation,
                            true
                        )
            },
            {
                title: "Trading Resources",
                render: (record) =>
                    RenderHelpers.renderAccountSummaryValueWithSymbol(
                            record.currencySymbol,
                            record.tradingResources,
                            true
                        )
            },
            {
                title: "Variation Margin Required",
                render: (record) =>
                    RenderHelpers.renderAccountSummaryValueWithSymbol(
                            record.currencySymbol,
                            record.variationMarginRequired,
                            true
                        )
            },
            {
                title: "Waived IM Limit",
                render: (record) =>
                    RenderHelpers.renderAccountSummaryValueWithSymbol(
                            record.currencySymbol,
                            record.waivedInitialMarginLimit,
                            true
                        )
            },
        ];
        this.setState({ columns: columns });
    }

    render() {
        return (

            <Table
                dataSource={
                    this.props.record ? [this.props.record] : []
                }
                pagination={false}
                rowExpandable={false}
                columns={this.state.columns}
            />

        );
    }
}

export default LegacyAccountSummary;
