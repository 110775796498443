import React from "react";
import { Table } from "antd";
import { RenderHelpers } from "../account-summary/renderHelpers";
import ErrorDetailsDropdown from "./errorsErrorDetailsDropdown";

class AccountDetailsDropdown extends React.Component {
  constructor(props) {
    super();
    this.props = props;
    this.state = { record: props.record, errorTypes: props.errorTypes };
  }
  async componentDidMount() {
    var columns = [
      {
        title: "AccountId",
        dataIndex: "accountId",
        width: "15%",
        render: (text) => RenderHelpers.renderOrder(text),
        align: "left",
        key: "accountId",
      },
      {
        title: "ClientId",
        dataIndex: "clientId",
        width: "15%",
        key: "clientId",
        render: (text) => RenderHelpers.renderOrder(text),
      },
      {
        title: "Type",
        width: "15%",
        key: "type",
        dataIndex: "tradingAccountType",
      },
      {
        title: "Whitelabel",
        width: "15%",
        key: "whitelabelId",
        dataIndex: "whiteLabelId",
        render: (text, record, index) => this.renderWhiteLabels(text, record)
      },
      {
        title: "Errors of this type",
        width: "15%",
        key: "errorsOfType",
        dataIndex: "errorsOfType",
      },
      {
        title: "Total errors on this account",
        width: "15%",
        key: "totalErrors",
        dataIndex: "totalErrors",
        render: (text, record, index) => this.renderTotalErrors(text, record)
      },
      {
        title: "Total Positions",
        width: "10%",
        key: "totalPositions",
        dataIndex: "totalPositions",
      },
    ];
    this.setState({ columns: columns });
  }

  renderWhiteLabels(text, record) {
    for(let whiteLabel of this.props.whiteLabels)
    {
      if(text === whiteLabel.id)
      {
        return whiteLabel.name
      }
    }
  }

  renderTotalErrors(text, record) {
    let color= "white";
    if (record.errorsOfType < text) {
      color="orange"
    }
    return (
        <font color={color}>
          {text}
        </font>
    );
  }

  render() {
    return (
      <div>
        <div className="accountSummaryTable">
          <Table
            dataSource={this.props.record}
            pagination={{
              hideOnSinglePage: true,
              size: 30,
              total: this.state.filteredErrorsCount,
              showSizeChanger: true,
              showTotal: total => "" + total + " Errors",
            }}
            columns={this.state.columns}
            rowClassName={(record) => "tradeBlue"}
            expandable={{
              expandedRowRender: (record) => (
                <ErrorDetailsDropdown
                  record={record}
                  errorTypes={this.props.errorTypes}
                />
              ),
            }}
            rowKey={(record) => record.accountId + record.errorType}
          />
        </div>
      </div>
    );
  }
}

export default AccountDetailsDropdown;
