import React from "react";
import AccountSummaryDataAccess from "../proxies/accountSummaryDataAccess.js";
import { Input, Button, Table } from "antd";
import { RenderHelpers } from "../account-summary/renderHelpers";
import { SearchOutlined } from "@ant-design/icons";
import { AppContext } from "../dataContext";
import * as _ from "underscore";
import SubtypeDetailsDropdown from "./errorsSubtypeDropdown.js";

class ErrorsTable extends React.Component {
  static contextType = AppContext;
  constructor(props) {
    super();
    this.refreshTimer = null;
    this.props = props;
    this.accountSummaryProxy = new AccountSummaryDataAccess();
    this._isMounted = false;

    this.idField = [];
    this.nameField = [];
    this.defaultAccountTypeFilterOptions = [
      { key: "CFD", description: "CFD" },
      { key: "Spread", description: "Spread" },
    ];

    this.filterColumns = {};

    this.state = {
      errorSummaries: null,
      sortCol: "marginPercent",
      sortOrder: "ascend",
      loading: false,
      accountTypes: this.defaultAccountTypeFilterOptions,
    };
    this.throttledGetData = _.throttle(this.getData, 500, {
      leading: false,
    });
  }

  getColumns = () => {
    return [
      {
        title: "Error Type",
        width: "90%",
        dataIndex: ["errorType"],
        render: (text, record) => this.getNameOf(record.errorType),
      },
      {
        title: "Count",
        width: "10%",
        dataIndex: "errorCount",
        align: "right",
        render: (text, record) => ( RenderHelpers.renderTrade(text))
      },
    ];
  };

  getColumnSearchProps = (
    dataIndex,
    placeHolder,
    searchFunction,
    resetFunction,
    updateFilterFunction
  ) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={placeHolder}
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            updateFilterFunction(dataIndex, e.target.value);
          }}
          onPressEnter={() => {
            searchFunction();
            confirm();
          }}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => {
            searchFunction();
            confirm();
          }}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => resetFunction(clearFilters, dataIndex)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
  });

  async onReconnect() {
    await this.throttledGetData();
  }
  async onAccountSummaryChanged(message) {
    var updated = false;
    if (this.state && this.state.errorSummaries) {
      const newList = this.state.errorSummaries.map((item) => {
        if (item.key === message.key) {
          updated = true;
          return message;
        }

        return item;
      });
      if (updated) {
        this.setState({ errorSummaries: newList });
      }
    }
  }

  async componentDidMount() {
    this._isMounted = true;
    this.setState({ loading: false });
    await this.getOptions();

    this.setFiltersFromProps();

    if (this.props.loadAllWhenNoSearchString) {
      this.getData();
    }
  }

  async getOptions() {
    try {
      var response = await this.accountSummaryProxy.getOptions();
      if (response.accountTypes && response.accountTypes.length > 0) {
        this.setState({ accountTypes: response.accountTypes });
      } else {
        this.setState({ accountTypes: this.defaultAccountTypeFilterOptions });
      }
    } catch (error) {
      console.error(error);
      this.setState({ accountTypes: this.defaultAccountTypeFilterOptions });
    }
  }

  async componentDidUpdate(prevProps) {
    var shouldRefresh = this.setFiltersFromProps(prevProps);
    if (prevProps.searchString !== this.props.searchString) {
      if (this.props.searchString && this.props.searchString.length > 2) {
        shouldRefresh = true;
      } else if (
        (!this.props.searchString || this.props.searchString.length === 0) &&
        this.props.loadAllWhenNoSearchString
      ) {
        shouldRefresh = true;
      }
    }
    if (
      this.areEqual(
        prevProps.legacyAccountSummaries,
        this.props.legacyAccountSummaries
      ) === false
    ) {
      shouldRefresh = true;
    }

    if (shouldRefresh) {
      this.getData();
    }
    if (prevProps.refreshInterval !== this.props.refreshInterval) {
      this.startRefresh(this.props.refreshInterval);
    }
  }

  arrayEquals(first, second) {
    if (first === second) {
      return true;
    }
    if (
      (first === undefined || first === null) &&
      (second === undefined || second === null || second.length === 0)
    ) {
      return true;
    }
    if (
      (second === undefined || second === null) &&
      (first === undefined || first === null || first.length === 0)
    ) {
      return true;
    }
    if (
      (first === undefined || first === null) &&
      second !== undefined &&
      second !== null &&
      second.length > 0
    ) {
      return false;
    }
    if (
      (second === undefined || second === null) &&
      first !== undefined &&
      first !== null &&
      first.length > 0
    ) {
      return false;
    }
    if (first.length !== second.length) {
      return false;
    }
    if (first.sort().join(",") === second.sort().join(",")) {
      return true;
    }
    return false;
  }

  areFiltersEqual(filter1, filter2) {
    if (filter1 === filter2) {
      return true;
    }
    if (
      (filter1 === undefined || filter1 === null) &&
      (filter2 === undefined || filter2 === null || filter2.length === 0)
    ) {
      return true;
    }
    if (
      (filter2 === undefined || filter2 === null) &&
      (filter1 === undefined || filter1 === null || filter1.length === 0)
    ) {
      return true;
    }
    if (
      (filter1 === undefined || filter1 === null) &&
      filter2 !== undefined &&
      filter2 !== null &&
      filter2.length > 0
    ) {
      return false;
    }
    if (
      (filter2 === undefined || filter2 === null) &&
      filter1 !== undefined &&
      filter1 !== null &&
      filter1.length > 0
    ) {
      return false;
    }
    return (
      this.areEqual(
        filter1.lastLoggedInAgoMonths,
        filter2.lastLoggedInAgoMonths
      ) &&
      this.arrayEquals(filter1.errorLevel, filter2.errorLevel) &&
      this.arrayEquals(filter1.errorType, filter2.errorType) &&
      this.arrayEquals(filter1.whiteLabelId, filter2.whiteLabelId) &&
      this.arrayEquals(filter1.clientType, filter2.clientType) &&
      this.areEqual(filter1.accountType, filter2.accountType) &&
      this.areEqual(filter1.name, filter2.name) &&
      this.areEqual(filter1.clientIdOrAlphaCode, filter2.clientIdOrAlphaCode)
    );
  }

  setFiltersFromProps = (prevProps) => {
    var shouldRefresh = false;
    if (
      prevProps === null ||
      prevProps === undefined ||
      this.areEqual(
        prevProps.lastLoggedInAgoMonthsFilter,
        this.props.lastLoggedInAgoMonthsFilter
      ) === false ||
      this.arrayEquals(
        prevProps.errorLevelFilter,
        this.props.errorLevelFilter
      ) === false ||
      this.arrayEquals(
        prevProps.alertLevelsFilter,
        this.props.alertLevelsFilter
      ) === false ||
      this.arrayEquals(
        prevProps.errorTypeFilter,
        this.props.errorTypeFilter
      ) === false ||
      this.areEqual(
        prevProps.hasPositionsFilter,
        this.props.hasPositionsFilter
      ) === false ||
      this.arrayEquals(
        prevProps.whiteLabelsFilter,
        this.props.whiteLabelsFilter
      ) === false ||
      this.arrayEquals(
        prevProps.clientTypesFilter,
        this.props.clientTypesFilter
      ) === false ||
      this.areEqual(
        prevProps.accountTypeFilter,
        this.props.accountTypeFilter
      ) === false ||
      this.areEqual(prevProps.nameFilter, this.props.nameFilter) === false ||
      this.areEqual(prevProps.clientIdFilter, this.props.clientIdFilter) ===
        false
    ) {
      var newList = Object.assign({}, this.filterColumns);

      if (this.props.accountTypeFilter) {
        var options = this.state.accountTypes.filter(
          (x) =>
            x.key.toLowerCase() === this.props.accountTypeFilter.toLowerCase()
        );
        if (options.length > 0) {
          newList.accountType = options[0].key;
          shouldRefresh = true;
        }
      } else {
        delete newList.accountType;
      }
      if (this.props.nameFilter) {
        newList.name = this.props.nameFilter;
      } else {
        delete newList.name;
      }

      if (this.props.clientIdFilter) {
        newList.clientIdOrAlphaCode = this.props.clientIdFilter;
      } else {
        delete newList.clientIdOrAlphaCode;
      }
      if (
        this.props.errorLevelFilter &&
        this.props.errorLevelFilter.length > 0
      ) {
        newList.errorLevel = this.props.errorLevelFilter;
      } else {
        delete newList.errorLevel;
      }
      if (this.props.errorTypeFilter && this.props.errorTypeFilter.length > 0) {
        newList.errorType = this.props.errorTypeFilter;
      } else {
        delete newList.errorType;
      }
      if (
        this.props.whiteLabelsFilter &&
        this.props.whiteLabelsFilter.length > 0
      ) {
        newList.whiteLabelId = this.props.whiteLabelsFilter;
      } else {
        delete newList.whiteLabelId;
      }
      if (
        this.props.clientTypesFilter &&
        this.props.clientTypesFilter.length > 0
      ) {
        newList.clientType = this.props.clientTypesFilter;
      } else {
        delete newList.clientType;
      }
      shouldRefresh =
        this.areFiltersEqual(newList, this.filterColumns) === false;
      this.filterColumns = newList;
      return shouldRefresh;
    }
    return shouldRefresh;
  };

  areEqual(val1, val2) {
    if (!val1 && !val2) {
      return true;
    }
    return val1 === val2;
  }

  startRefresh = (intervalSeconds) => {
    var that = this;
    if (this.refreshTimer) {
      console.debug("Clearing refresh");
      clearInterval(this.refreshTimer);
      this.refreshTimer = null;
    }
    if (intervalSeconds > 0) {
      console.debug(
        "Starting auto refresh. Interval:" + intervalSeconds + "secs"
      );
      this.refreshTimer = setInterval(function () {
        that.getData();
      }, intervalSeconds * 1000);
    }
  };

  filter = (selectedKeys, confirm, dataIndex) => {
    confirm();
    var filtered = selectedKeys.find(
      (x) =>
        x !== undefined &&
        x !== null &&
        (typeof x !== "string" || (x.length > 0 && x.trim()))
    );
    this.setFilterField(dataIndex, filtered);
  };

  setFilterField = (dataIndex, value) => {
    var newList = Object.assign({}, this.filterColumns);
    if (value) {
      newList[dataIndex] = value;
    } else {
      for (var element in newList) {
        if (~element.indexOf(dataIndex)) {
          delete newList[element];
        }
      }
    }

    this.filterColumns = newList;
  };

  resetFilter = (clearFilters, dataIndex) => {
    clearFilters();
    var newList = Object.assign({}, this.state.filterColumns);
    for (var element in newList) {
      if (~element.indexOf(dataIndex)) {
        delete newList[element];
      }
    }
    this.filterColumns = newList;
  };

  async getData() {
    if (this._isMounted && this.state.loading === false) {
      try {
        this.setState({ loading: true });

        var filters = { ...this.filterColumns };

        if (this.props.onFilterApplied) {
          this.props.onFilterApplied({
            errorLevelFilter: filters.errorLevel,
            errorTypeFilter: filters.errorType,
            whiteLabelsFilter: filters.whiteLabelId,
            clientTypesFilter: filters.clientType,
            searchString: this.props.searchString,
            nameFilter: filters.name,
            clientIdFilter: filters.clientIdOrAlphaCode,
          });
        }

        var response = await this.accountSummaryProxy.errorSummaries(
          this.props.searchString,
          filters
        );
        if (response.details) {
          this.setState({
            errorSummaries: response.details,
            filteredErrorCount: response.filteredErrorCount,
          });
        } else {
          this.setState({ errorSummaries: [], filteredSummariesCount: 0 });
        }
        if (this.props.onDataRetrieved) {
          this.props.onDataRetrieved({
            filteredRecordCount: response.filteredSummariesCount,
            filteredErrorCount: response.filteredErrorCount,
            errorCount: response.totalErrorCount,
            recordCount: response.totalSummaries,
          });
        }
      } catch (error) {
        console.error(error);
        this.setState({ errorSummaries: [], filteredSummariesCount: 0 });
      } finally {
        this.setState({ loading: false });
      }
    }
  }

  async clearData() {
    if (this._isMounted && this.state.loading === false) {
      try {
        this.setState({ loading: true });
      } catch (error) {
        console.error(error);
      } finally {
        this.setState({
          errorSummaries: [],
          filteredSummariesCount: 0,
          loading: false,
        });
      }
    }
  }

  async handleTableChange(pagination, filters, sorter) {
    var sortCol = undefined;
    var sortOrder = undefined;
    if (sorter.column) {
      sortCol = sorter.column.columnKey;
      sortOrder = sorter.order;
    }

    this.setState({
      sortCol: sortCol,
      sortOrder: sortOrder,
    });
    await this.getData();
  }

  getNameOf(index) {
    for (let error of this.props.errorTypes) {
      if (error.index === index) {
        return error.description;
      }
    }
    return "Unknown";
  }

  render() {
    return (
      <div>
        <Table
          loading={this.state.loading}
          dataSource={this.state.errorSummaries}
          expandable={{
            expandedRowRender: (record) => (
              <SubtypeDetailsDropdown
                record={record.errorSubtypeAndErrorDetailsDtos}
                errorTypes={this.props.errorTypes}
                whiteLabels={this.props.whiteLabels}
              />
            ),
            columnWidth: 3,
            defaultExpandAllRows: true,
          }}
          pagination = {false}
          columns={this.getColumns()}
          rowKey={(record) => record.errorType}
        />
      </div>
    );
  }
}

export default ErrorsTable;
