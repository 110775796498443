import React from "react";
import * as _ from "underscore";
import OrderDataAccess from "./proxies/orderDataAccess.js";
import { Layout, Typography, Select, Row, Col, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { AppContext } from "../dataContext";
import OrderQueue from "./orderQueue.js";
import { Helpers } from "../helpers/helpers";
const { Content } = Layout;
const { Title } = Typography;
const { Option } = Select;

class OrderQueues extends React.Component {
  static contextType = AppContext;
  constructor(props) {
    super();
    this.props = props;

    this.throttledSearch = _.throttle(this.search, 500, {
      leading: false,
    });

    this.ordersProxy = new OrderDataAccess();
    this.state = {
      tradeModes: [],
      triggerTypes: [],
      marketQuotes: [],
      markets: [],
      searchString: null
    };
  }

  async componentDidMount() {
    this._isMounted = true;
    this.context.updateMenuSelection("orderQueues");
    await this.getOptions();
  }

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  async getOptions() {
    try {
      var response = await this.ordersProxy.getOptions();
      this.setState({
        tradeModes: response.tradeModes,
        triggerTypes: response.triggerTypes,
        markets: response.allMarkets,
        marketQuotes: response.allMarketQuotes,
      });
    } catch (error) {
      console.error(error);
      this.setState({
        tradeModes: [],
        triggerTypes: [],
        markets: [],
        marketQuotes: [],
      });
    }
  }

  filterDropDown = (input, option) => {
    if (option.props && option.props.value) {
      if (
        option.props.value === input ||
        (Object.prototype.toString.call(option.props.value) ===
          "[object String]" &&
          option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0)
      ) {
        return true;
      } else if (
        option.props.key &&
        option.props.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
      ) {
        return true;
      } else if (option.props.children) {
        if (Array.isArray(option.props.children)) {
          if (
            option.props.children.some((element) => {
              if (element === input) {
                return true;
              } else if (
                Object.prototype.toString.call(element) === "[object String]" &&
                element.toLowerCase().indexOf(input.toLowerCase()) >= 0
              ) {
                return true;
              }
              return false;
            })
          ) {
            return true;
          }
        } else if (
          Object.prototype.toString.call(option.props.children) ===
            "[object String]" &&
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        ) {
          return true;
        }
      }
    }
    return false;
  };

  onTradeModesChanged = (e, control) => {
    this.tradeMode = e;
    this.throttledSearch();
  };

  onMarketsChanged = (e, control) => {
    this.markets = e && e.length > 0 ? e : undefined;
    this.throttledSearch();
  };

  onMarketQuotesChanged = (e, control) => {
    this.marketQuotes = e && e.length > 0 ? e : undefined;
    this.throttledSearch();
  };

  onSearchChange = (event) => {
    this.name = event.target.value.toLowerCase();
    this.throttledSearch();
  };

  search = async () => {
    this.setState({ marketQuotesFilter: this.marketQuotes });
    this.setState({ marketsFilter: this.markets });
    this.setState({ tradeModesFilter: this.tradeMode });
    this.setState({ searchString: this.name });
  };

  render() {
    var that = this;

    const tradeModesMenu = (
      <Select
        mode="single"
        allowClear
        style={{ width: "100%" }}
        placeholder="trade mode"
        defaultValue={[]}
        onChange={(e) => that.onTradeModesChanged(e, that.tradeModesMenu)}
        showArrow={true}
        ref={(select) => (that.tradeModesMenu = select)}
        filterOption={(input, option) => that.filterDropDown(input, option)}
      >
        {this.state.tradeModes.map((element) => (
          <Option value={element.key} key={element.key}>
            {element.description}
          </Option>
        ))}
      </Select>
    );

    const marketsMenu = (
      <Select
        mode="multiple"
        allowClear
        placeholder="market"
        style={{ width: "100%" }}
        defaultValue={[]}
        onChange={(e) => that.onMarketsChanged(e, that.marketsMenu)}
        showArrow={true}
        showSearch={true}
        ref={(select) => (that.marketsMenu = select)}
        filterOption={(input, option) => that.filterDropDown(input, option)}
      >
        {this.state.markets.map((element) => (
          <Option value={element.id} key={element.id}>
            {element.name} | {element.id}
          </Option>
        ))}
      </Select>
    );

    const marketQuotesMenu = (
      <Select
        mode="multiple"
        allowClear
        placeholder="market quote"
        style={{ width: "100%" }}
        defaultValue={[]}
        onChange={(e) => that.onMarketQuotesChanged(e, that.marketQuotesMenu)}
        showArrow={true}
        showSearch={true}
        ref={(select) => (that.marketQuotesMenu = select)}
        filterOption={(input, option) => that.filterDropDown(input, option)}
      >
        {this.state.marketQuotes.map((element) => (
          <Option value={element.id} key={element.id}>
            {element.id} | {element.name}
          </Option>
        ))}
      </Select>
    );

    return (
      <Layout className="layout">
        <Content style={{ padding: "0 5px " }}>
          <Row gutter={[16, 16]}>
            <Col flex="70px">
              <Title level={4}>Search</Title>
            </Col>
            <Col flex="3">
              <Input
                allowClear
                prefix={<SearchOutlined />}
                placeholder="market name, client"
                onChange={this.onSearchChange}
              />
            </Col>
            <Col flex="1">{tradeModesMenu}</Col>
            <Col flex="2">{marketsMenu}</Col>
            <Col flex="2">{marketQuotesMenu}</Col>
          </Row>
          <OrderQueue
            marketQuoteId={this.state.marketQuotesFilter}
            marketId={this.state.marketsFilter}
            tradeMode={this.state.tradeModesFilter}
            searchString={this.state.searchString}
          />
        </Content>
      </Layout>
    );
  }
}

export default Helpers.withParams(OrderQueues);
