import React from "react";
import { ExclamationCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";

class Errors extends React.Component {
    constructor(props) {
        super();
        this.props = props;
        this.state = { record: props.record, errors: [], className:this.props.className };
    }
    async componentDidMount() {
        var errors = [];
        if (this.state.record && this.state.record.errorsAndWarnings) {
            if (this.state.record.errorsAndWarnings) {
                this.state.record.errorsAndWarnings.forEach(error => {
                    errors.push(this.getMessage(error));
                    
                });
            }
        }
        this.setState({ errors: errors });
    }

    getMessage=(error) => {
        var prepend = '';
        if (error.tradeId){
            prepend = "TradeId: "+error.tradeId;
        }
        if (error.marketQuoteId){
            prepend = "MarketQuoteId: "+error.marketQuoteId + ' '+prepend;
        }
        else if (error.marketId) {
            prepend = "MarketId: "+error.marketId + ' '+prepend;
        }

        if (error.currencyCode) {
            prepend = "Currency: "+error.currencyCode + ' '+prepend;
        }

        if (prepend){
            prepend = prepend+ ' ';
        }

        if (error.type === 1){
            var missingPriceError = prepend+'No price for market quote id ' + error.marketQuoteId + ' (' + error.description + ')';
            return {description:missingPriceError, level:error.level};
        }
        else{
            return {description:prepend+error.description, level:error.level};
        }
    }

    render() {
        return (
            <div>{this.state.errors ? this.state.errors.map((err,index) => {
                var icon = (<CloseCircleOutlined/>)
                var className = 'redForeground';
                if (err.level === 0){
                    className='yellowForeground';
                    icon = (<ExclamationCircleOutlined/>)
                }
                return (<div className={className} key={this.state.record.clientId+index+err}>{icon}{err.description}</div>)}
            ):''}</div>
        );
    }
}

export default Errors;
