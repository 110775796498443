import React, { Component } from "react";
import { Layout, Table, Button, Space, Tooltip } from "antd";
import {
  RedoOutlined,
} from "@ant-design/icons";
import "antd/dist/antd.dark.css";
import "../main.css";
import DbListenerProxy from "../proxies/dbListenerProxy";
import SqlTableDependencyDetails from "./sqlTableDependencyDetails";
import { RenderHelpers } from "../helpers/renderHelpers";
import { Helpers } from "../helpers/helpers";
import { AppContext } from "../dataContext";

const { Content } = Layout; 

class SqlTableDependency extends Component {
  static contextType = AppContext;
  constructor(props) {
    super();
    this.dbListenerProxy = new DbListenerProxy();

    var columns = [
      {
        width: 50,
        render: RenderHelpers.renderSqlTableDependencyWarning,
      },
      {
        title: "DB Name",
        dataIndex: "name",
        key: "name",
        width: 150,
      },
      {
        title: "Status",
        dataIndex: "statusDescription",
        render: RenderHelpers.renderSqlTableDependencyStatus,
      },
      {
        width: 50,
        render: (text, record) => (
          <Space>
            <Tooltip title="Refresh">
              <Button
                type="primary"
                shape="circle"
                onClick={() => this.refreshDb(record.name)}
                icon={<RedoOutlined />}
              />
            </Tooltip>
          </Space>
        ),
      },
    ];

    this.state = {
      search: undefined,
      columns: columns,
      dbs: [],
      loading: true,
    };
  }

  refreshDb = async (name) => {
    this.setState({ loading: true });
    var dbs = this.state.dbs;
    var index = dbs.findIndex((x) => x.name === name);
    var db = await this.dbListenerProxy.db(name);
    dbs.splice(index, 1, db);
    this.setState({ dbs: [...dbs] });
    this.setState({ loading: false });
  };

  start = async (db, table) => {
    this.setState({ loading: true });
    await this.dbListenerProxy.start(db, table);
    this.setState({ loading: false });
  };

  stop = async (db, table) => {
    this.setState({ loading: true });
    await this.dbListenerProxy.stop(db, table);
    this.setState({ loading: false });
  };

  reset = async (db, table) => {
    this.setState({ loading: true });
    await this.dbListenerProxy.reset(db, table);
    this.setState({ loading: false });
  };

  async componentDidMount() {
    this._isMounted = true;
    var dbs = await this.dbListenerProxy.dbs();
    this.setState({ dbs: dbs });
    this.setState({ loading: false });
  }

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  render() {
    return (
      <Layout className="layout">
        <Content style={{ padding: "0 5px" }}>
          <Table
            rowKey="name"
            size="middle"
            loading={this.state.loading}
            columns={this.state.columns}
            dataSource={this.state.dbs}
            onChange={this.handleChange}
            pagination={false}
            expandable={{
              expandedRowRender: (record, i) => (
                <SqlTableDependencyDetails
                  record={record}
                  start={this.start}
                  stop={this.stop}
                  reset={this.reset}
                />
              ),
            }}
          />
        </Content>
      </Layout>
    );
  }
}
SqlTableDependency.contextType = AppContext;
export default Helpers.withParams(SqlTableDependency);
