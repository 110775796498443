import React from 'react';
import TelemetryProxy from '../proxies/telemetryProxy.js'
import { Brush, BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import moment from 'moment'
import {
    Layout,
    Table,
} from 'antd';

const { Content } = Layout;
const timeWindow = 15;

class LatencyChart extends React.Component {
    constructor(props) {
        super();

        this.telemetryProxy = new TelemetryProxy();

        this.props = props;
        this._isMounted = false;
        var columns = [
            {
                title: "Connection Id",
                dataIndex: "connectionId",
            },
            {
                title: "DateTime UTC",
                dataIndex: "dateTimeUtc",
            },
            {
                title: "Round Trip",
                dataIndex: "roundTrip",
                sorter: (a, b) => a.roundTrip > b.roundTrip ? 1 : a.roundTrip < b.roundTrip ? -1 : 0,
            },
            {
                title: "Sent By Server",
                dataIndex: "sentByServer",
            },
            {
                title: "Received By Client",
                dataIndex: "receivedByClient",
            },
            {
                title: "Sent By Client",
                dataIndex: "sentByClient",
            },
            {
                title: "Received By Server",
                dataIndex: "receivedByServer",
            },
            {
                title: "Send Message Count",
                dataIndex: "sendMessageCount",
                width: 50
            },
            {
                title: "Receive Message Count",
                dataIndex: "receiveMessageCount",
                width: 50
            },
        ];
        this.state = { data: [], columns: columns, record: props.record, events: [] };
    };

    async componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidMount = async () => {
        this._isMounted = true;
        var i = 0;
        var events = await this.telemetryProxy.GetDistributionUserWebsocketHeartbeats(this.props.record.clientId);
        var converted = events.map((x) => {
            x.id = i++;
            x.roundTrip = Math.abs(new Date(x.receivedByServer) - new Date(x.sentByServer));
            return x;
        })
        var groups = {};
        converted.forEach(x => {
            var split = x.sentByServer.split(':');
            var minutes = Math.floor(split[1] / timeWindow);
            var date = split[0] + ":" + ('0' + (minutes * timeWindow)).slice(-2);
            if (date in groups) {
                groups[date].push(x);
            } else {
                groups[date] = new Array(x);
            }
        });
        console.debug(groups);
        var data = [];
        for (var date in groups) {
            data.push({ date: date, avg: Math.round(groups[date].map(x => x.roundTrip).reduce((a, b) => a + b, 0) / groups[date].length) });
        }
        console.debug(data);
        data.sort(function (a, b) { return new Date(a.date) - new Date(b.date) });
        this.setState({
            data: data,
            allEvents: converted
        });
    }

    async componentDidUpdate(prevProps) {
    }

    onShowMatchedWindowsChanged(e) {
        this.setState({ showMatchedWindows: e.target.checked })
    }

    onClick = (e) => {
        if (e && this.state.allEvents) {
            var start = moment(e.activeLabel + ":00");
            var end = moment(e.activeLabel + ":00").add(timeWindow, 'minutes');
            var events = this.state.allEvents.filter(x => moment(x.sentByServer).isAfter(start) && moment(x.sentByServer).isBefore(end));
            console.debug("Bar details: ", e, events);
            this.setState((state) => ({
                events: events
            }));
        }
    }

    render() {

        const { data } = this.state;

        return (
            <Layout className="layout">
                <Content style={{ padding: "0 5px" }}>
                    <ResponsiveContainer width="100%" height={400} onClick={this.onClick}>
                        <BarChart data={data} onClick={this.onClick}>
                            <XAxis dataKey="date" name='Day' tickFormatter={(date) => moment(date).format('DD-MM HH:mm')}
                            />
                            <YAxis dataKey="avg" />
                            <Tooltip wrapperStyle={{ backgroundColor: '#4A4A4A' }} contentStyle={{ backgroundColor: '#4A4A4A' }} animationDuration={0} />
                            <Legend verticalAlign="top" height={36} />
                            <Bar type="monotone" name={timeWindow + 'min average'} dataKey="avg" stroke="#FC4F00" fill="#FC4F00" maxBarSize={100}/>
                            <Brush dataKey="date" tickFormatter={(date) => moment(date).format('DD-MM HH:mm')} startIndex={Math.round(this.state.data.length > 30 ? this.state.data.length * 0.5 : 0)} endIndex={this.state.data.length-1} />
                        </BarChart>
                    </ResponsiveContainer>
                    <Table
                        rowKey="id"
                        size="middle"
                        onChange={this.pageChanged}
                        loading={this.state.loading}
                        columns={this.state.columns}
                        dataSource={this.state.events}
                        pagination={{
                            size: 10,
                            showSizeChanger: false,
                        }}
                    />
                </Content>
            </Layout>
        )
    }
}

export default LatencyChart;