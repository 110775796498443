import React, { Component } from "react";
import { Route, HashRouter, Routes } from "react-router-dom";
import { Layout, Typography, Space, Menu, Modal } from "antd";

import "./App.css";
import Dash from "./providers-page/dash";
import InstrumentAddOrEdit from "./instrument/instrument-actions/instrument-add-or-edit/instrumentAddOrEdit";
import InstrumentPage from "./instrument/instrumentPage";
import ProviderInstrumentSearch from "./provider-instrument/providerInstrumentSearch";
import ProviderInstrumentAddOrEdit from "./provider-instrument/provider-instrument-add-or-edit/providerInstrumentAddOrEdit";
import ProviderInstrumentImport from "./provider-instrument/providerInstrumentImport";
import DisabledProviderInstruments from "./disabled-provider-instrument/disabledProviderInstruments";
import InstrumentExport from "./instrument/instrument-actions/instrumentExport";
import MarketMonitor from "./market/marketMonitor";
import MarketWatcher from "./market/marketWatcher";
import { AppContext } from "./dataContext";
import ConnectorsCoordinatorProxy from "./proxies/connectorsCoordinatorProxy";
import { Helpers } from "./helpers/helpers";
import ClientAccountSummaries from "./account-summary/clientAccountSummaries";
import ErrorsPage from "./account-errors/errorsPage";
import OrderQueues from "./order-queues/orderQueues";
import Latencies from "./latencies/latencies";
import SqlTableDependency from "./sql-table-dependency/sqlTableDependency";
import { SignOutButton } from "./components/SignOutButton";
import { MsalContext } from "@azure/msal-react";
import { appRoles } from "./authConfig";
import {
  DesktopOutlined,
  SearchOutlined,
  PercentageOutlined,
  MonitorOutlined,
  ImportOutlined,
  DashboardOutlined,
  FieldTimeOutlined,
  CloseCircleOutlined,
  UserOutlined,
  EyeInvisibleOutlined,
} from "@ant-design/icons";
import PricingHubProxy from "./proxies/pricinghubProxy";

const { Content, Sider, Header } = Layout;
const { Title } = Typography;

class App extends Component {
  static contextType = MsalContext;
  constructor(props) {
    super();
    this.props = props;
    this.connectorCoordinatorProxy = new ConnectorsCoordinatorProxy();
    this.pricingHubProxy = new PricingHubProxy();

    this.state = {
      isUnderConstruction: Helpers.isUnderConstruction(),
      collapsed: false,
      providers: [],
      instruments: [],
      loadedAndReady: undefined,
      isLoadedAndReady: false,
      onDelete: undefined,
      onAdded: undefined,
    };
  }

  showProviderInstrumentEdit = (record) => {
    console.log("PROVIDER INSTRUMENT", record);
    this.setState({
      providerInstrumentEditTitle:
        "Edit: " + record.name + " provider instrument",
      providerInstrument: record,
      isProviderInstrumentAddOrEditVisible: true,
      isProviderInstrumentAdd: false,
    });
  };
  showProviderInstrumentAdd = () => {
    this.setState({
      providerInstrumentAddTitle: "Add a new TEST provider instrument",
      isProviderInstrumentAddOrEditVisible: true,
      isProviderInstrumentAdd: true,
    });
  };

  search = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  reset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "", searchedColumn: undefined });
  };

  async componentDidMount() {
    this.setState({ loading: true });
    this.setState({ mt4Loading: true });
    var providers = await this.pricingHubProxy.providers();
    if (providers.length > 0) {
      Helpers.sort(providers, (x) => x.name);
      this.setState({ platformsLoading: false });
      this.setState({ statusesLoading: false });
      this.setState({ riskGroupsLoading: false });
      this.setState({ regionsLoading: false });
      this.setState({ loading: false });
      this.setState({ mt4Loading: false });
      this.loadedAndReady();
    }
  }

  onCollapse = (collapsed) => {
    console.debug(collapsed);
    this.setState({ collapsed });
  };

  loadedAndReady = () => {
    console.debug("LoadedAndReady: ", this.state.loadedAndReady);
    if (this.state.loadedAndReady) this.state.loadedAndReady();
    this.setState({ isLoadedAndReady: true });
  };

  updateMenuSelection = (name) => {
    this.setState({ selectedKeys: [name] });
  };

  updateContext = (name, value) => {
    this.setState({ [name]: value });
  };

  showInstrumentEdit = (record) => {
    this.setState({
      instrumentEditTitle: "Edit: " + record.publishedName + " instrument",
      instrumentToEdit: record,
      isInstrumentAddOrEditVisible: true,
      isInstrumentAdd: false,
    });
  };

  showInstrumentAdd = (provider, record) => {
    this.setState({
      instrumentAddTitle:
        "Add a new " + (provider ? provider.name : "") + " instrument",
      provider: provider,
      instrumentToClone: record,
      isInstrumentAddOrEditVisible: true,
      isInstrumentAdd: true,
    });
  };

  showInstrumentExport = (currentInstrumentSearchAndFilter, instruments) => {
    this.setState({
      instrumentExportTitle:
        "Are you sure you want to export " +
        instruments.length +
        " instrument(s)?",
      currentInstrumentSearchAndFilter: currentInstrumentSearchAndFilter,
      isInstrumentExportVisible: true,
    });
  };

  onCancel = () => {
    this.setState({
      isInstrumentAddOrEditVisible: false,
    });
  };

  onProviderInstrumentCancel = () => {
    this.setState({
      isProviderInstrumentAddOrEditVisible: false,
    });
  };

  onInstrumentExportCancel = () => {
    this.setState({ isInstrumentExportVisible: false });
  };

  render() {
    var hasWriteRole = false;
    var hasAdminRole = false;
    var hasReadRole = false;
    if (
      this.context.accounts &&
      this.context.accounts[0].idTokenClaims["roles"]
    ) {
      hasReadRole = this.context.accounts[0].idTokenClaims["roles"].includes(
        appRoles.Read
      );
      hasWriteRole = this.context.accounts[0].idTokenClaims["roles"].includes(
        appRoles.Write
      );
      hasAdminRole = this.context.accounts[0].idTokenClaims["roles"].includes(
        appRoles.Admin
      );
      if (hasAdminRole === true) {
        hasReadRole = true;
        hasWriteRole = true;
      } else if (hasWriteRole === false) {
        hasReadRole = true;
      }
    }

    if (hasReadRole === false) {
      return (
        <div style={{ width: "100%", top: "30%", position: "absolute" }}>
          <h1 style={{ textAlign: "center" }}>Trading Platform Dashboard</h1>
          <h2 style={{ textAlign: "center" }}>{Helpers.currentEnvName()}</h2>
          <h2 style={{ textAlign: "center", color: "red" }}>Access Denied</h2>

          <div
            style={{
              left: "50%",
              transform: "translate(-50%)",
              position: "absolute",
            }}
          >
            Please contact{" "}
            <a
              href={
                "mailto:techsupport@tradenation.com?subject=Request access to Trading Platform Dashboard - " +
                Helpers.currentEnvName()
              }
            >
              tech support
            </a>{" "}
            for access.
          </div>
        </div>
      );
    }
    return (
      <AppContext.Provider
        value={{
          state: this.state,
          updateMenuSelection: this.updateMenuSelection,
          updateContext: this.updateContext,
          showInstrumentAdd: this.showInstrumentAdd,
          showInstrumentEdit: this.showInstrumentEdit,
          showProviderInstrumentAdd: this.showProviderInstrumentAdd,
          showProviderInstrumentEdit: this.showProviderInstrumentEdit,
          showInstrumentExport: this.showInstrumentExport,
          hasWriteRole: hasWriteRole ?? false,
        }}
      >
        <HashRouter>
          <Layout>
            <Header className="header">
              <Space>
                <div className="logo">
                  <img
                    src="newTN.png"
                    alt="TradeNation"
                    width="32"
                    height="32"
                  />
                </div>
                <Title level={3}>Dashboard</Title>
                <div style={{ color: "#FC4F00", float: "left" }}>
                  v{window._env_.VER} [{window._env_.ENV.toUpperCase()}]
                </div>
              </Space>
              <Space style={{ float: "right" }}>
                <SignOutButton />{" "}
              </Space>
            </Header>

            <Layout style={{ minHeight: "150vh" }}>
              <Sider
                collapsible
                collapsed={this.state.collapsed}
                onCollapse={this.onCollapse}
              >
                <Menu
                  mode="inline"
                  selectedKeys={this.state.selectedKeys}
                  defaultOpenKeys={["providers"]}
                >
                  <Menu.Item key="dash" icon={<DashboardOutlined />}>
                    <a href="#/dash">Providers</a>
                  </Menu.Item>

                  <Menu.Item key="allInstruments" icon={<SearchOutlined />}>
                    <a href="#/instruments">Instruments</a>
                  </Menu.Item>
                  <Menu.Item
                    key="allProviderInstruments"
                    icon={<SearchOutlined />}
                  >
                    <a href="#/providerInstruments">Provider instruments</a>
                  </Menu.Item>
                  <Menu.Item
                    key="disabledProviderInstruments"
                    icon={<EyeInvisibleOutlined />}
                  >
                    <a href="#/disabledProviderInstruments">
                      Disabled provider instruments
                    </a>
                  </Menu.Item>
                  {hasWriteRole && (
                    <Menu.Item
                      key="importProviderInstruments"
                      icon={<ImportOutlined />}
                    >
                      <a href="#/import">Import</a>
                    </Menu.Item>
                  )}
                  <Menu.Item key="marketMonitor" icon={<DesktopOutlined />}>
                    <a href="#/marketMonitor">Market Monitor</a>
                  </Menu.Item>
                  <Menu.Item key="marketWatcher" icon={<MonitorOutlined />}>
                    <a href="#/marketWatcher">Market Watcher</a>
                  </Menu.Item>
                  <Menu.Item key="accountSummaries" icon={<UserOutlined />}>
                    <a href="#/accountSummaries">Account Summaries</a>
                  </Menu.Item>
                  <Menu.Item key="accountErrors" icon={<CloseCircleOutlined />}>
                    <a href="#/accountErrors">Account Errors</a>
                  </Menu.Item>
                  <Menu.Item key="orderQueues" icon={<PercentageOutlined />}>
                    <a href="#/orderQueues">Order Queues</a>
                  </Menu.Item>
                  <Menu.Item key="latencies" icon={<FieldTimeOutlined />}>
                    <a href="#/latencies">Latencies</a>
                  </Menu.Item>
                </Menu>
              </Sider>
              <Layout className="site-layout">
                <Content style={{ padding: "0 50px" }}>
                  <Routes>
                    <Route exact path="/" element={<Dash />} />
                    <Route exact path="/dash/" element={<Dash />} />
                    <Route
                      exact
                      path="/instruments"
                      element={<InstrumentPage />}
                    />
                    <Route
                      exact
                      path="/instruments/:id"
                      element={<InstrumentPage />}
                    />
                    <Route
                      exact
                      path="/instruments/:id/providers/:name"
                      element={<InstrumentPage />}
                    />
                    <Route
                      exact
                      path="/import"
                      element={<ProviderInstrumentImport />}
                    />
                    <Route
                      exact
                      path="/providerInstruments"
                      element={<ProviderInstrumentSearch />}
                    />
                    <Route
                      exact
                      path="/providerInstruments/:id"
                      element={<ProviderInstrumentSearch />}
                    />
                    <Route
                      exact
                      path="/providerInstruments/providers/:name"
                      element={<ProviderInstrumentSearch />}
                    />
                    <Route
                      exact
                      path="/providerInstruments/:id/providers/:name"
                      element={<ProviderInstrumentSearch />}
                    />
                    <Route
                      exact
                      path="/disabledProviderInstruments"
                      element={<DisabledProviderInstruments />}
                    />
                    <Route
                      exact
                      path="/marketMonitor"
                      element={<MarketMonitor />}
                    />
                    <Route
                      exact
                      path="/marketWatcher"
                      element={<MarketWatcher />}
                    />{" "}
                    <Route
                      exact
                      path="/marketMonitor/:nameOrId"
                      element={<MarketMonitor />}
                    />
                    <Route exact path="/sql" element={<SqlTableDependency />} />{" "}
                    <Route
                      exact
                      path="/accountSummaries"
                      element={<ClientAccountSummaries />}
                    />
                    <Route
                      exact
                      path="/accountErrors"
                      element={<ErrorsPage />}
                    />
                    <Route
                      exact
                      path="/orderQueues"
                      element={<OrderQueues />}
                    />
                    <Route path="/latencies" element={<Latencies />} />
                  </Routes>
                </Content>
                {this.state.isInstrumentAddOrEditVisible && (
                  <Modal
                    maskClosable={false}
                    closable={true}
                    title={
                      this.state.isInstrumentAdd
                        ? this.state.instrumentAddTitle
                        : this.state.instrumentEditTitle
                    }
                    open={true}
                    width={1000}
                    footer={null}
                    onCancel={this.onCancel}
                  >
                    <InstrumentAddOrEdit
                      instrumentToEdit={this.state.instrumentToEdit}
                      isInstrumentAdd={this.state.isInstrumentAdd}
                      provider={this.state.provider}
                      instrumentToClone={this.state.instrumentToClone}
                    ></InstrumentAddOrEdit>
                  </Modal>
                )}
                {this.state.isProviderInstrumentAddOrEditVisible ? (
                  <Modal
                    maskClosable={false}
                    closable={true}
                    title={
                      this.state.isProviderInstrumentAdd
                        ? this.state.providerInstrumentAddTitle
                        : this.state.providerInstrumentEditTitle
                    }
                    open={true}
                    width={1000}
                    footer={null}
                    onCancel={this.onProviderInstrumentCancel}
                  >
                    <ProviderInstrumentAddOrEdit
                      providerInstrument={
                        this.state.isProviderInstrumentAddOrEditVisible
                          ? this.state.providerInstrument
                          : undefined
                      }
                      isProviderInstrumentAdd={
                        this.state.isProviderInstrumentAdd
                      }
                    ></ProviderInstrumentAddOrEdit>
                  </Modal>
                ) : (
                  ""
                )}
                {this.state.isInstrumentExportVisible ? (
                  <Modal
                    maskClosable={false}
                    closable={true}
                    title={this.state.instrumentExportTitle}
                    open={true}
                    width={1000}
                    footer={null}
                    onCancel={this.onInstrumentExportCancel}
                  >
                    <InstrumentExport
                      currentInstrumentSearchAndFilter={
                        this.state.currentInstrumentSearchAndFilter
                      }
                    ></InstrumentExport>
                  </Modal>
                ) : (
                  ""
                )}
              </Layout>
            </Layout>
          </Layout>
        </HashRouter>
      </AppContext.Provider>
    );
  }
}

export default App;
